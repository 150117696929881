<template>
  <div>
      <div class="d-flex justify-content-center align-items-center mb-3">
        <div class="text-center" style="position: relative;">
          <div class="d-flex justify-content-center">
            <h3 class="m-0" style="display: inline-block;margin: 0;">
              {{ calendarTitle() }}
            </h3>
            <div class="ml-2" v-if="elementType === 'Ehpad'">
              <b-button :variant="resourcesIsFiltered ? 'primary' : 'secondary'" size="sm" @click.prevent="openFilterModal"
                ><i class="fas fa-filter" v-b-tooltip.hover.v-primary title="Filtrer les événements" />
              </b-button>
            </div>
            <div class="ml-2" v-if="elementType === 'Ehpad'">
              <b-button variant="primary" size="sm" @click.prevent="openSwapResourceModal"
                ><i class="fas fa-exchange-alt" v-b-tooltip.hover.v-primary title="Echanger une ressource" />
              </b-button>
            </div>
          </div>
          <div style="color: #469AE0; position: absolute; left: 0; right: 0; bottom: -22px;" v-if="loading">
            Chargement...
            <i class="fa fa-spin fa-circle-notch" />
          </div>
        </div>
      </div>
    <FullCalendar
      ref="fullCalendar"
      :key='calendarKey'
      :options="calendarOptions"
    >
    </FullCalendar>

    <app-event-modal
      :id="`event-modal-${elementType}`"
      :selectedEvent="selectedEvent"
      :elementType="elementType"
      :elementId="elementId"
      :clickInfo="clickInfo"
      :loadingSelectedEvent="loadingSelectedEvent"
      hide-footer
      v-on:events-refetch="asyncFetchEvents(datesInfo)"
    />

    <app-full-calendar-filter-modal
      :id="`full-calendar-filter-modal-${elementType}`"
      :elementType="elementType"
      :filteredResources="filteredResources"
      hide-footer
      @filtered-resources-changed="(data) => filteredResourcesChanged(data)"
    />

    <app-swap-resource-modal
      :id="`full-calendar-swap-resource-modal-${this.elementType}`"
      :elementType="elementType"
      hide-footer
      v-on:events-refetch="asyncFetchEvents(datesInfo)"
    />
  </div>
</template>

<script>
import '@fullcalendar/core/vdom';
import { nanoid } from 'nanoid';
import moment from 'moment';
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import FullCalendar from '@fullcalendar/vue';
import rrulePlugin from '@fullcalendar/rrule';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import scrollGridPlugin from '@fullcalendar/scrollgrid';
import listPlugin from '@fullcalendar/list';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import EventModal from './EventModal.vue';
import FullCalendarFilterModal from './FullCalendarFilterModal.vue';
import SwapResourceModal from './SwapResourceModal.vue';

export default {
  props: ['elementType', 'elementId'],
  components: {
    FullCalendar,
    appEventModal: EventModal,
    appFullCalendarFilterModal: FullCalendarFilterModal,
    appSwapResourceModal: SwapResourceModal,
  },
  data() {
    return {
      calendarKey: nanoid(20),
      filteredResources: null,
      resourcesIsFiltered: false,
      loading: false,
      loadingSelectedEvent: false,
      datesInfoStart: '',
      datesInfoEnd: '',
      datesInfo: '',
      selectedEvent: null,
      clickInfo: null,
      calendarOptions: {
        dayMaxEventRows: true,
        plugins: [rrulePlugin, dayGridPlugin, interactionPlugin, timeGridPlugin, scrollGridPlugin, bootstrapPlugin, listPlugin],
        initialView: 'timeGridWeek',
        weekends: true,
        selectable: true,
        themeSystem: 'bootstrap',
        locale: 'fr',
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        stickyHeaderDates: true,
        buttonText: {
          today: "Aujourd'hui",
          month: 'M',
          week: 'S',
          day: 'J',
          list: 'Liste',
        },
        firstDay: 1,
        scrollTime: '08:00:00',
        allDayText: 'Journée',
        titleFormat: { year: 'numeric', month: 'long', day: 'numeric' },
        headerToolbar: {
          start: 'today prev,next',
          center: 'title',
          end: 'timeGridDay timeGridWeek dayGridMonth listDay',
        },
        events: [],
        // For Main Sequence Event in bold :
        // https://fullcalendar.io/docs/event-render-hooks
        // https://fullcalendar.io/docs/content-injection
        // eventContent: function(arg, createElement) {
        //   if (!arg.event.extendedProps.vuexModified && arg.event.extendedProps.sequence) {
        //     return createElement('b', {}, arg.event.title);
        //   }
        // },
        datesSet: this.handleDatesSet,
      },
    };
  },
  watch: {
    resources: {
      handler() {
        this.filteredResources = [...this.resources];
      },
      immediate: true,
    },
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if (modalId === `event-modal-${this.elementType}`) {
        this.selectedEvent = null;
        this.clickInfo = null;
      }
    });
  },
  methods: {
    ...mapActions([
      'fetchEventsWithDates', 'findEvent',
    ]),
    openFilterModal() {
      this.$bvModal.show(`full-calendar-filter-modal-${this.elementType}`);
    },
    openSwapResourceModal() {
      this.$bvModal.show(`full-calendar-swap-resource-modal-${this.elementType}`);
    },
    filteredResourcesChanged(data) {
      this.filteredResources = [...data];
      this.resourcesIsFiltered = (this.filteredResources.length !== this.resources.length);
      this.asyncFetchEvents(this.datesInfo);
    },
    calendarTitle() {
      switch (this.elementType) {
        case 'Resident':
          return `Programme de ${this.resident.firstName} ${this.resident.name}`;
        case 'Ehpad':
          return `Programme de ${this.currentEhpad.shortname}`;
        case 'Resource':
          return `Programme de ${this.resource.name}`;
        default:
          return this.elementType;
      }
    },
    handleDateSelect(clickEvent) {
      this.selectedEvent = null;
      this.clickInfo = clickEvent;
      this.$bvModal.show(`event-modal-${this.elementType}`);
    },
    handleDatesSet(datesInfo) {
      // !!! Changing calendarOptions.events call datesSet/handleDatesSet (why ?) : infinite loop
      if ((this.datesInfoStart !== datesInfo.startStr) && (this.datesInfoEnd !== datesInfo.endStr)) {
        this.asyncFetchEvents(datesInfo);
      }
      this.datesInfoStart = datesInfo.startStr;
      this.datesInfoEnd = datesInfo.endStr;
      this.datesInfo = datesInfo;
    },
    handleEventClick(clickEvent) {
      this.findEvent(clickEvent.event);
      this.clickInfo = clickEvent;
      // let calendarApi = clickInfo.view.calendar;
      // let test = calendarApi.getEventById(2);
      // this.$refs.fullCalendar.getApi().render();
      // this.$refs.fullCalendar.getApi().view.activeEnd
      this.$bvModal.show(`event-modal-${this.elementType}`);
    },
    async asyncFetchEvents(datesInfo) {
      this.loading = true;
      const start = moment(datesInfo.start).format('YYYY-MM-DD');
      const end = moment(datesInfo.end).format('YYYY-MM-DD');
      let includeEventResident = false;
      let includeSequenceEventResidents = false;
      const { elementId } = this;
      const { elementType } = this;
      const resourcesIds = this.resourcesIsFiltered ? _.map(this.filteredResources, 'id') : null;
      // If in Query, parse in Vuex Store
      if (elementType === 'Resident') includeEventResident = true;
      if (elementType === 'Resource') includeSequenceEventResidents = true;
      await this.$store.dispatch('fetchEventsWithDates', {
        elementId,
        elementType,
        resourcesIds,
        includeEventResident,
        includeSequenceEventResidents,
        start,
        end,
      }).then((result) => {
        this.calendarOptions.events = result;
        this.loading = false;
      }).catch((error) => {
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
    },
    async findEvent(event) {
      this.loadingSelectedEvent = true;
      let includeEventResident = false;
      let { elementId } = this;
      let { elementType } = this;
      if (elementType === 'Resident') includeEventResident = true;
      // For Resource Calendar Sequence child events (already modified in Vuex module with fetchevents) :
      if (elementType === 'Resource' && event.extendedProps.sequence === true && event.extendedProps.vuexModified) {
        includeEventResident = true;
        elementId = event.extendedProps.residentId;
        elementType = 'Resident';
      }
      await this.$store.dispatch('findEvent', {
        id: event.id,
        elementId,
        elementType,
        includeEventResident,
      }).then((result) => {
        // SPREAD for COPY ELSE its a LINK
        this.selectedEvent = { ...result };
        this.loadingSelectedEvent = false;
      }).catch((error) => {
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
      resident: 'resident',
      resources: 'resources',
      resource: 'resource',
      events: 'events',
    }),
  },
};
</script>
<style lang="scss">
  .fc-header-toolbar {
    zoom: 0.7;
  }

  .fc-license-message {
    z-index: -1 !important;
  }

  .fc-timegrid-slots > table > tbody > tr {
    height: 40px;
  }

  .fc-timegrid-axis-frame-liquid {
    font-size: smaller;
  }
</style>
