<template>
  <b-modal v-bind="$attrs" size="lg" class="col-12 text-center">
    <template #modal-title v-if="resident !== null">
      {{ editableEvent.title }} du {{ editableEvent.start | dateFormatterShort }} pour {{ residentName | capitalizeString }}
    </template>

    <p>Les données de participation aux exercices sont directement sauvegardées au clic</p>
    <app-event-mobista-card
      :resident="resident"
      :computedEventResidentTasks="computedEventResidentTasks"
      @update-event-resident-task="$emit('update-event-resident-task')"
    />
  </b-modal>
</template>

<script>

import EventMobistaCard from '../mobista/EventMobistaCard.vue';

export default {
  props: ['resident', 'editableEvent', 'computedEventResidentTasks'],
  components: {
    AppEventMobistaCard: EventMobistaCard,
  },
  computed: {
    residentName() {
      let { firstName } = this.resident;
      if (firstName === null) {
        firstName = '';
      }
      return `${firstName} ${this.resident.name.toUpperCase()}`;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
