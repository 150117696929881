<template>
  <div>
    <li>
      <span class="m-0">
        <span style="font-size: x-large;">
          {{ eventResidentTask.task.title | capitalizeString }}
        </span>

        <b-button
          v-if="eventResidentTask.id"
          variant="link"
          v-b-modal="`add-mobista-comment-modal${resident.id}-${eventResidentTask.task.id}-${eventResidentTask.eventId}-${eventResidentTask.eventDate}`"
          :class="commentClass"
        />

        <app-add-mobista-comment-modal
          :id="`add-mobista-comment-modal${resident.id}-${eventResidentTask.task.id}-${eventResidentTask.eventId}-${eventResidentTask.eventDate}`"
          hide-footer
          :eventResidentTask="eventResidentTask"
          :resident="resident"
          @update-event-resident-task="$emit('update-event-resident-task')"
        />

      </span>
    </li>
    <b-row class="mt-2 d-print-none d-flex justify-content-around mr-4">
      <b-col
        cols="1"
        v-for="mobistaObject in mobistaObjects"
        :key="mobistaObject.value"
      >
        <div
          :class="mobistaClass(mobistaObject)"
          @click="saveMobista(mobistaObject)"
          :style="isTaskForbidden ? 'cursor: not-allowed; opacity: 0.5;' : ''"
          :disabled="isTaskForbidden"
        >
          <span style="color: white;">{{ mobistaObject.value * 100 }}%</span>
        </div>
      </b-col>
    </b-row>

    <hr class="w-50 d-print-none" />

  </div>
</template>

<script>
import _ from 'lodash';
import AddMobistaCommentModal from './AddMobistaCommentModal.vue';
import { CREATE_EVENT_RESIDENT_TASKS, UPDATE_EVENT_RESIDENT_TASKS } from '../../graphql/event_resident_tasks/mutations';

export default {
  props: ['eventResidentTask', 'resident'],
  components: {
    AppAddMobistaCommentModal: AddMobistaCommentModal,
  },
  data() {
    return {
      mobistaObjects: [
        { value: 0, class: 'redCircle', isSelected: false },
        { value: 0.25, class: 'redCircle', isSelected: false },
        { value: 0.5, class: 'orangeCircle', isSelected: false },
        { value: 0.75, class: 'orangeCircle', isSelected: false },
        { value: 1.0, class: 'greenCircle', isSelected: false },
        { value: 1.25, class: 'greenCircle', isSelected: false },
        { value: 1.5, class: 'greenCircle', isSelected: false },
        { value: 1.75, class: 'greenCircle', isSelected: false },
        { value: 2, class: 'greenCircle', isSelected: false },
      ],
    };
  },
  watch: {
    eventResidentTask: {
      handler() {
        this.unsetMobistaValue();
        this.setMobistaValue();
      },
      immediate: true,
    },
  },
  methods: {
    setMobistaValue() {
      if (this.eventResidentTask.mobista !== null) {
        const obj = _.find(this.mobistaObjects, { value: this.eventResidentTask.mobista });
        obj.isSelected = true;
      }
    },
    unsetMobistaValue() {
      this.mobistaObjects.forEach((el) => {
        el.isSelected = false;
      });
    },
    saveMobista(mobistaObject) {
      if (this.isTaskForbidden) {
        return;
      }
      this.unsetMobistaValue();
      mobistaObject.isSelected = true;
      if (this.eventResidentTask.id === null) { this.createEventResidentTask(mobistaObject); }
      if (this.eventResidentTask.id !== null) { this.updateEventResidentTask(mobistaObject); }
    },
    async createEventResidentTask(mobistaObject) {
      await this.$apollo.mutate({
        mutation: CREATE_EVENT_RESIDENT_TASKS,
        variables: {
          eventId: this.eventResidentTask.eventId,
          residentId: this.eventResidentTask.residentId,
          mobista: mobistaObject.value,
          eventResidentTaskOrder: this.eventResidentTask.eventResidentTaskOrder,
          eventDate: this.eventResidentTask.eventDate,
          comment: this.eventResidentTask.comment,
          task: this.eventResidentTask.task,
        },
      }).then((result) => {
        this.$emit('update-event-resident-task');
      }).catch((error) => {
        mobistaObject.isSelected = false;
        this.setMobistaValue();
        if (error.message === 'Network error: Failed to fetch') {
          alert('Erreur réseau, veuillez rééssayer');
        } else {
          this.flashMessage.show({
            status: 'error',
            title: error.graphQLErrors[0].extensions.code,
            message: error.graphQLErrors[0].message,
          });
        }
      });
    },
    async updateEventResidentTask(mobistaObject) {
      await this.$apollo.mutate({
        mutation: UPDATE_EVENT_RESIDENT_TASKS,
        variables: {
          id: this.eventResidentTask.id,
          mobista: mobistaObject.value,
          comment: this.eventResidentTask.comment,
        },
      }).then((result) => {
        this.$emit('update-event-resident-task');
      }).catch((error) => {
        mobistaObject.isSelected = false;
        this.setMobistaValue();
        if (error.message === 'Network error: Failed to fetch') {
          alert('Erreur réseau, veuillez rééssayer');
        } else {
          this.flashMessage.show({
            status: 'error',
            title: error.graphQLErrors[0].extensions.code,
            message: error.graphQLErrors[0].message,
          });
        }
      });
    },
    mobistaClass(item) {
      if (item.isSelected) {
        return `selected ${item.class}`;
      }
      return item.class;
    },
  },
  computed: {
    commentClass() {
      if (this.eventResidentTask.comment) {
        return 'far fa-comment-dots red';
      }
      return 'far fa-comment-dots';
    },
    isTaskForbidden() {
      return this.resident.forbiddenTasks.some(
        (task) => task.id === this.eventResidentTask.taskId
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.red {
  color: red;
}

.selected {
  box-shadow: 0px 0px 5px 5px #469ae0;
}

.redCircle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 50px;
  text-align: center;
  background: rgb(184, 31, 31);
  cursor: pointer;
}
.orangeCircle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 50px;
  text-align: center;
  background: orange;
  cursor: pointer;
}
.greenCircle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 50px;
  text-align: center;
  background: rgb(138, 210, 149);
  cursor: pointer;
}
</style>
