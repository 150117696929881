<template>
  <b-container fluid>
    <app-col-content>
      <div v-for="ehpad in ehpads" :key="ehpad.id">
        <div class="ehpad-link text-center">
          <router-link
            tag="a"
            :to="{ path: `/ehpads/${ehpad.id}/main` }"
            class="btn btn-outline-primary ephad_name-btn mb-4"
            @click.native="selectEhpad(ehpad)"
            >{{ ehpad.name }}</router-link
          >
        </div>
      </div>
    </app-col-content>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ColContent from '../../components/layout/ColContent.vue';

export default {
  name: 'EhpadsIndex',
  components: {
    appColContent: ColContent,
  },
  methods: {
    ...mapActions(['selectEhpad', 'setEhpads', 'setGlobalSettings']),
  },
  created() {
    this.setEhpads();
    this.setGlobalSettings();
  },
  watch: {
    user: {
      handler() {
        if (this.user && this.user.ehpad) {
          this.$router.push({
            path: `/ehpads/${this.user.ehpad.id}/main`,
          });
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      ehpads: 'ehpads',
      user: 'user',
    }),
  },
};
</script>

<style lang="scss" scoped>
.ephad_name-btn {
  width: 350px;
}
</style>
