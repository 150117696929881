import apiClient from '../../utilities/api_client';
import { FIND_EHPAD, FETCH_EHPADS } from '../../graphql/ehpad/queries';
import { UPDATE_EHPAD, UPDATE_EHPAD_SETTING } from '../../graphql/ehpad/mutations';

// For dispatching from outside the module
const namespaced = true;

const ehpadState = {
  ehpad: null,
  ehpads: [],
};

const mutations = {
  SELECT_EHPAD(state, ehpad) {
    state.ehpad = ehpad;
  },
  SET_EHPADS(state, ehpads) {
    state.ehpads = ehpads;
  },
  UPDATE_EHPAD(state, ehpad) {
    const index = state.ehpads.findIndex((e) => e.id === ehpad.id);
    state.ehpads.splice(index, 1, ehpad);
    state.ehpad = ehpad;
  },
};

const actions = {
  selectEhpad: ({ commit }, ehpad) => {
    commit('SELECT_EHPAD', ehpad);
  },
  findEhpad: async ({ commit }, id) => {
    const { data } = await apiClient.query({
      query: FIND_EHPAD,
      variables: { id },
      fetchPolicy: 'network-only',
    });
    commit('SELECT_EHPAD', data.findEhpad);
  },
  setEhpads: async ({ commit }) => {
    const { data } = await apiClient.query({
      query: FETCH_EHPADS,
      fetchPolicy: 'network-only',
    });
    commit('SET_EHPADS', data.ehpads);
  },
  updateEhpad: async ({ commit }, formData) => {
    const { data } = await apiClient.mutate({
      mutation: UPDATE_EHPAD,
      variables: { ...formData },
    });
    const { ehpad } = data.updateEhpad;
    commit('UPDATE_EHPAD', ehpad);
    return ehpad;
  },
  updateEhpadSetting: async ({ commit }, formData) => {
    const { data } = await apiClient.mutate({
      mutation: UPDATE_EHPAD_SETTING,
      variables: { ...formData },
    });
    const { ehpad } = data.updateEhpadSetting;
    commit('UPDATE_EHPAD', ehpad);
    return ehpad;
  },
};

const getters = {
  ehpad: (state) => state.ehpad,
  ehpads: (state) => state.ehpads,
};

export default {
  state: ehpadState,
  mutations,
  actions,
  getters,
};
