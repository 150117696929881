<template>
  <div>
    <b-row class="mb-2">
      <b-col cols="3">
        <label for="inline-form-input-mobilizationCategories" class="pr-4 text-nowrap"
          >Catégories concernées :
        </label>
      </b-col>
      <b-col cols="8">
        <multiselect
          v-model="editableEvent.mobilizationCategories"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="false"
          :options="mobilizationCategoriesOptions"
          placeholder="Selectionner une/plusieurs catégories"
          label="name"
          track-by="name"
          selectLabel=""
          deselectLabel=""
          selectedLabel="Sélectionné"
          :disabled='disableEdit'
          @select='onSelect'
          @remove='onRemove'
        >
          <span slot="noResult">Pas de résultat correspondant</span>

          <template slot="tag" slot-scope="{ option, remove }">
            <span class="multiselect__tag" :style="{ backgroundColor: option.color }">
              <span>{{ option.name }}</span>
              <i
                aria-hidden="true"
                tabindex="1"
                class="multiselect__tag-icon"
                :style="{ backgroundColor: option.color }"
                @click="remove(option)"
              ></i>
            </span>
          </template>
        </multiselect>
      </b-col>
      <b-col cols="1" class="my-auto">
        <p :style="numberOfResidentsStyle" class="my-auto">
          <i
            class="fas fa-users"
            v-b-tooltip.hover.v-primary
            title="Total de résidents de cette/ces catégorie(s)"
          />
          {{ currentNumberOfResidentsforCategories }}
        </p>
      </b-col>

    </b-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import Multiselect from 'vue-multiselect';

export default {
  props: ['editableEvent', 'disableEdit', 'elementType'],
  data() {
    return {
      currentNumberOfResidentsforCategories: 0,
    };
  },
  components: {
    Multiselect,
  },
  watch: {
    editableEvent: {
      handler() {
        // Replace mobilizationCategories objects with formatedMobilizationCategoryObject (for more features here)
        this.editableEvent.mobilizationCategories = this.formatedMobilizationCategories;
        // Use resident last mobilization category to set mobilization categories in form
        if (this.elementType === 'Resident' && this.resident.lastMobilizationDiagnostic !== null && this.editableEvent.ehpad === null) {
          this.editableEvent.mobilizationCategories = [_.find(this.mobilizationCategoriesOptions, { id: this.resident.lastMobilizationDiagnostic.mobilizationCategory.id })];
        }
      },
      immediate: true,
    },
    'editableEvent.mobilizationCategories': {
      handler() {
        if (this.editableEvent.mobilizationCategories.length === 0) {
          this.currentNumberOfResidentsforCategories = 0;
        }
        // Counter for currentNumberOfResidentsforCategories
        if (this.editableEvent.mobilizationCategories.length !== 0) {
          let count = 0;
          this.editableEvent.mobilizationCategories.forEach((value) => {
            count += value.numberOfResidents;
          });
          this.currentNumberOfResidentsforCategories = count;
        }
      },
      immediate: true,
    },
  },
  methods: {
    onSelect(value) {
      // Push residents of the category (which are not already in the list) to editableEvent.residents
      const editableEventResidentIds = _.map(this.editableEvent.residents, 'id');
      const categoryFilteredResidents = _.filter(value.residents, (obj) => !editableEventResidentIds.includes(obj.id));
      this.editableEvent.residents.push(...categoryFilteredResidents);
    },
    onRemove(value) {
      // Remove residents of the category from editableEvent.residents
      const categoryResidentsIds = _.map(value.residents, 'id');
      this.editableEvent.residents = _.filter(this.editableEvent.residents, (obj) => !categoryResidentsIds.includes(obj.id));
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
      resident: 'resident',
    }),
    formatedMobilizationCategories() {
      const formatedMobilizationCategories = [];
      // Use index and object if necessary
      // this.editableEvent.mobilizationCategories.forEach((mobilizationCategory, index, object) => {
      this.editableEvent.mobilizationCategories.forEach((mobilizationCategory) => {
        const formatedMobilizationCategory = _.find(this.mobilizationCategoriesOptions, { id: mobilizationCategory.id });
        formatedMobilizationCategories.push(formatedMobilizationCategory);
      }, this);
      return formatedMobilizationCategories;
    },
    mobilizationCategoriesOptions() {
      return this.currentEhpad.mobilizationCategories.map((el) => ({
        id: el.id,
        name: el.name,
        number: el.number,
        color: el.color,
        description: el.description,
        numberOfResidents: el.numberOfResidents,
        residents: el.residents,
      }));
    },
    numberOfResidentsStyle() {
      return this.editableEvent.capacity < this.currentNumberOfResidentsforCategories
        ? 'color: red;font-weight: bold;'
        : 'color: black;';
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
