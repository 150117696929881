import apiClient from '../../utilities/api_client';
import {
  FIND_EVENT,
  FETCH_EVENTS_WITH_DATES,
} from '../../graphql/events/queries';
import {
  CREATE_EVENT,
  UPDATE_EVENT,
  DESTROY_EVENT,
} from '../../graphql/events/mutations';
import { FIND_EVENT_RESIDENT_TASKS } from '../../graphql/event_resident_tasks/queries';

function changeEventForSequence(event, eventResident) {
  event.title = `${event.title} : ${eventResident.resident.name}`;
  event.end = event.end.split(" ")[0];
  event.end += ' ';
  event.end += eventResident.endHour;
  event.start = event.start.split(" ")[0];
  event.start += ' ';
  event.start += eventResident.startHour;
  event.duration = eventResident.duration;
  event.residentId = eventResident.residentId;
  event.vuexModified = true;
  if (event.rrule !== null) {
    event.rrule = { ...event.rrule };
    event.rrule.dtstart = event.start.split(" ")[0];
    event.rrule.dtstart += ' ';
    event.rrule.dtstart += eventResident.startHour;
    event.exdate = event.exdate.map((exdate) => `${exdate.split(' ')[0]} ${eventResident.startHour}`);
  }
}

const eventState = {
  event: {
    title: '',
    start: null,
    end: null,
    mobilizationCategories: [],
    eventDefaultTasks: [],
    resources: [],
    capacity: null,
    residents: [],
    ehpad: null,
    rrule: null,
    sequence: false,
    sequenceDefaultDuration: null,
  },
};

const mutations = {
  SET_EVENT(state, event) {
    state.event = event;
  },
};

const actions = {
  findEvent: async ({ commit, state }, formData) => {
    state.event = null;
    const { data } = await apiClient.query({
      query: FIND_EVENT,
      variables: { ...formData },
      fetchPolicy: 'no-cache',
    });
    const { elementType } = formData;
    const { events } = data;
    if (elementType === 'Resident') {
      if (events[0].sequence === true) {
        changeEventForSequence(events[0], events[0].eventResident);
      }
    }
    commit('SET_EVENT', events[0]);
    // Sort Residents by ORDER TODO if sequence
    // if (events[0].sequence) {
    //   const sortedResidents = events[0].residents.sort((a, b) => a.sequenceEvent.startHour.localeCompare(b.sequenceEvent.startHour));
    //   events[0].residents = sortedResidents;
    // }
    return events[0];
  },
  fetchEventsWithDates: async ({ commit }, formData) => {
    const { data } = await apiClient.query({
      query: FETCH_EVENTS_WITH_DATES,
      variables: { ...formData },
      fetchPolicy: 'network-only',
    });
    const { elementType } = formData;
    const { events } = data;
    if (elementType === 'Resident') {
      events.forEach((event) => {
        if (event.sequence === false) return;
        changeEventForSequence(event, event.eventResident);
      });
    }
    let newEvents = [];
    if (elementType === 'Resource') {
      events.forEach((event) => {
        if (event.sequence === false) return;
        event.sequenceEventResidents.forEach((eventResident) => {
          const newEvent = { ...event };
          changeEventForSequence(newEvent, eventResident);
          newEvents.push(newEvent);
        });
      });
    }
    return [...events, ...newEvents];
  },
  findEventResidentTasks: async ({ commit, state }, formData) => {
    const { data } = await apiClient.query({
      query: FIND_EVENT_RESIDENT_TASKS,
      variables: { ...formData },
      fetchPolicy: 'no-cache',
    });
    const { eventResidentTasks } = data;
    return eventResidentTasks;
  },
  createEvent: async ({ commit, dispatch, state }, formData) => {
    const { data } = await apiClient.mutate({
      mutation: CREATE_EVENT,
      variables: { ...formData },
    });
  },
  updateEvent: async ({ commit, dispatch, state }, formData) => {
    const { data } = await apiClient.mutate({
      mutation: UPDATE_EVENT,
      variables: { ...formData },
    });
  },
  destroyEvent: async ({ commit, dispatch, state }, formData) => {
    const { data } = await apiClient.mutate({
      mutation: DESTROY_EVENT,
      variables: { ...formData },
    });
  },
};

const getters = {
  event: (state) => state.event,
};

export default {
  state: eventState,
  mutations,
  actions,
  getters,
};
