import _ from 'lodash';

import apiClient from '../../utilities/api_client';
import { FIND_TASK, FETCH_TASKS } from '../../graphql/tasks/queries';
import {
  CREATE_TASK,
  UPDATE_TASK,
  DESTROY_TASK,
} from '../../graphql/tasks/mutations';

const taskState = {
  task: null,
  tasks: [],
};

const mutations = {
  SET_TASKS(state, tasks) {
    state.tasks = tasks;
  },
  SELECT_TASK(state, task) {
    state.task = task;
  },
  ADD_TASK(state, task) {
    state.tasks.push(task);
  },
  UPDATE_TASK(state, task) {
    const index = _.findIndex(state.tasks, { id: task.id });
    state.tasks.splice(index, 1, task);
  },
  DELETE_TASK(state, id) {
    const index = _.findIndex(state.tasks, { id });
    state.tasks.splice(index, 1);
  },
};

const actions = {
  setTasks: async ({ commit, state }, ehpadId) => {
    state.tasks = [];
    const { data } = await apiClient.query({
      query: FETCH_TASKS,
      variables: { ehpadId },
      fetchPolicy: 'network-only',
    });
    commit('SET_TASKS', data.tasks);
  },
  findTask: async ({ commit, state }, id) => {
    state.task = null;
    const { data } = await apiClient.query({
      query: FIND_TASK,
      variables: { id },
      fetchPolicy: 'network-only',
    });
    commit('SELECT_TASK', data.findTask);
  },
  createTask: async ({ commit }, formData) => {
    const { data } = await apiClient.mutate({
      mutation: CREATE_TASK,
      variables: { ...formData },
    });
    const { task } = data.createTask;
    commit('ADD_TASK', task);
  },
  updateTask: async ({ commit }, formData) => {
    const { data } = await apiClient.mutate({
      mutation: UPDATE_TASK,
      variables: { ...formData },
    });
    const { task } = data.updateTask;
    commit('UPDATE_TASK', task);
  },
  destroyTask: async ({ commit }, formData) => {
    const { data } = await apiClient.mutate({
      mutation: DESTROY_TASK,
      variables: { ...formData },
    });
    const { task } = data.destroyTask;
    commit('DELETE_TASK', task.id);
  },
};

const getters = {
  tasks: (state) => state.tasks,
  task: (state) => state.task,
};

export default {
  state: taskState,
  mutations,
  actions,
  getters,
};
