import gql from 'graphql-tag';

export const FIND_EVENT_RESIDENT_TASKS = gql`
  query FIND_EVENT_RESIDENT_TASKS(
    $eventId: ID!,
    $residentId: ID!,
    $eventDate: String!,
  ) {
    eventResidentTasks(
      eventId: $eventId,
      residentId: $residentId,
      eventDate: $eventDate,
    )  {
      id
      residentId
      eventId
      taskId
      task {
        id
        title
      }
      mobista
      eventDate
      eventResidentTaskOrder
      comment
    }
  }
`;
