<template>
  <div>
    <div class="d-flex justify-content-center align-items-center mb-3">
      <span class="text-center">
        <h5 class="font-weight-bold">Objectifs des activités</h5>
      </span>
    </div>

    <b-form-group
      label-size="sm"
      label-for="filterInput"
      class="mb-3"
      style="width: 40%;"
    >
      <b-input-group size="sm">
        <b-form-input
          v-model="filter"
          type="search"
          id="filterInput"
          placeholder="Filtre de recherche"
          aria-label="Filtre de recherche pour la liste des activités"
        ></b-form-input>
        <b-input-group-append>
          <b-button :disabled="!filter" @click="filter = ''">Effacer</b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <b-table
      :filter="filter"
      bordered
      striped
      small
      hover
      :items="tasks"
      :fields="fields"
      :sort-by.sync="sortBy"
      class="m-0"
      ref="tasksTable"
      id="tasksTable"
      :key="tableKey"
    >
      <template v-slot:head(actions)>{{ 'Valeur' }}</template>

      <template v-slot:cell(actions)="data">
        <b-form-input
          :value="getGoalValue(data.item.id)"
          type="number"
          min="0"
          step="1"
          :class="getGoalValue(data.item.id) === 0 ? 'alert-danger' : ''"
          @input="updateGoalValue(data.item.id, $event)"
        ></b-form-input>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  props: ['resident'],
  data() {
    return {
      filter: null,
      sortBy: 'title',
      tableKey: 0,
      fields: [
        {
          key: 'title',
          label: 'Titre',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
          class: 'large-size',
        },
        {
          key: 'description',
          label: 'Description',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'humanTaskType',
          label: "Type d'activité",
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
          class: 'min-size',
        },
        {
          key: 'actions',
          label: 'actions',
          tdClass: 'align-middle',
          thClass: 'align-middle',
          class: 'min-size',
        },
      ],
    };
  },
  created() {
    this.setTasks(this.currentEhpad.id);
  },
  methods: {
    ...mapActions(['setTasks']),
    async upsertResidentTaskGoal(taskId, value) {
      try {
        await this.$store.dispatch('upsertResidentTaskGoal', {
          residentId: this.resident.id,
          taskId,
          value,
        });
      } catch (error) {
        this.error = error;
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      }
    },
    getGoalValue(taskId) {
      const matchingGoals = this.resident.residentTaskGoals.filter((goal) => goal.taskId === taskId);

      if (matchingGoals.length === 0) {
        return 0;
      }

      const latestGoal = matchingGoals.reduce((latest, current) => {
        return new Date(current.date) > new Date(latest.date) ? current : latest;
      });

      return latestGoal.value;
    },
    updateGoalValue(taskId, value) {
      const parsedValue = parseInt(value, 10);
      const finalValue = isNaN(parsedValue) ? 0 : Math.max(0, parsedValue);
      const goalIndex = this.resident.residentTaskGoals.findIndex((goal) => goal.taskId === taskId);
      if (goalIndex !== -1) {
        this.$set(this.resident.residentTaskGoals, goalIndex, { ...this.resident.residentTaskGoals[goalIndex], value: finalValue });
      } else {
        this.resident.residentTaskGoals.push({ taskId, value: finalValue });
      }
      this.upsertResidentTaskGoal(taskId, finalValue);
    },
  },
  computed: {
    ...mapGetters({
      tasks: 'tasks',
      currentEhpad: 'ehpad',
    }),
  },
};
</script>

<style lang="scss" scoped>
::v-deep .large-size {
  width: 300px;
}

::v-deep .min-size {
  width: 150px;
}
</style>
