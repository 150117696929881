import gql from 'graphql-tag';

export const FETCH_MOBILIZATION_TESTS = gql`
  query FETCH_MOBILIZATION_TESTS($ehpadId: ID!) {
    mobilizationTests(ehpadId: $ehpadId) {
      id
      name
      url
      description
      mobilizationTestCategories {
        id
        alertTreshold
        mobilizationCategory {
          id
          name
          number
          color
        }
      }
      mobilizationCategories {
        id
        name
        number
        color
      }
      residentTests {
        id
      }
    }
  }
`;

export const FIND_MOBILIZATION_TEST = gql`
  query FIND_MOBILIZATION_TEST($id: ID!) {
    findMobilizationTest(id: $id) {
      id
      name
      url
      description
      mobilizationTestCategories {
        id
        alertTreshold
        mobilizationCategory {
          id
          name
          number
          color
        }
      }
      mobilizationCategories {
        id
        name
        number
        color
      }
    }
  }
`;
