<template>
  <div>
    <div class="d-flex justify-content-center align-items-center mb-3">
      <span class="text-center">
        <h3 class="m-0 mb-3" style="display: inline-block;margin: 0;">
          Modifier un Test
        </h3>
      </span>
    </div>
    <b-row align-h="center">
      <b-col cols="12" xl="10">
        <app-edit-mobilization-test-form />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import EditMobilizationTestForm from '../../components/mobilization_tests/EditMobilizationTestForm.vue';

export default {
  components: {
    appEditMobilizationTestForm: EditMobilizationTestForm,
  },
  props: ['mobilizationTestId'],
  methods: {
    ...mapActions(['findMobilizationTest']),
  },
  computed: {
    ...mapGetters({
      mobilizationTest: 'mobilizationTest',
      currentEhpad: 'ehpad',
    }),
  },
  created() {
    this.findMobilizationTest(this.mobilizationTestId);
  },
};

</script>

<style>

</style>
