<template>
  <div>
    <div class="d-flex justify-content-center align-items-center mb-1">
      <span class="text-center">
        <h5 class="m-0" style="display: inline-block;margin: 0;">
          Liste des Secteurs
        </h5>
        <b-button class="mb-2 ml-4" variant="primary" size="sm" v-b-modal.new-sector-modal>
          <i class="fas fa-plus" />
          Créer un secteur
        </b-button>
      </span>
    </div>

    <b-table
      sticky-header="600px"
      bordered
      striped
      small
      hover
      :items="sectors"
      :fields="fields"
      :sort-by.sync="sortBy"
      class="m-0"
      ref="sectorTable"
      id="sectorTable"
    >
      <template v-slot:cell(active)="data">{{ humanActive(data.item) }}</template>

      <template v-slot:head(actions)>{{ '' }}</template>

      <template v-slot:cell(actions)="data">
        <b-button
          variant="link"
          size="sm"
          v-b-modal.info-sector-modal
          @click="selectSector(data.item)"
        >
          <i class="fas fa-edit" />
          Modifier
        </b-button>
      </template>
    </b-table>

    <app-new-sector-modal id="new-sector-modal" hide-footer />

    <app-info-sector-modal
      v-if="selectedSector"
      id="info-sector-modal"
      hide-footer
      :sector="selectedSector"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import InfoSectorModal from './InfoSectorModal.vue';
import NewSectorModal from './NewSectorModal.vue';

export default {
  name: 'SectorTable',
  components: {
    appInfoSectorModal: InfoSectorModal,
    appNewSectorModal: NewSectorModal,
  },
  data() {
    return {
      updating: false,
      selectedSector: null,
      sortBy: 'name',
      fields: [
        {
          key: 'name',
          label: 'Nom du secteur',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'actions',
          label: 'actions',
          class: 'text-center',
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
      ],
    };
  },
  created() {
    this.setSectors(this.currentEhpad.id);
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', () => {
      this.selectedSector = null;
    });
  },
  methods: {
    ...mapActions(['setSectors']),
    selectSector(sector) {
      this.selectedSector = sector;
    },
  },
  computed: {
    ...mapGetters({
      sectors: 'sectors',
      currentEhpad: 'ehpad',
    }),
  },
};
</script>

<style lang="scss" scoped></style>
