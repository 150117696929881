<template>
  <div id="app">
    <app-navbar v-if="currentUser"/>
    <div v-if="$route.name !== 'SignIn' && $route.name !== 'EhpadCalendar' && $route.name !== 'ResidentCalendar'" class="header__navbar-fix d-print-none"></div>

    <div :id="content">
      <router-view></router-view>
    </div>
    <FlashMessage :position="'right bottom'"/>
    <app-terms-of-service-modal/>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Navbar from './components/layout/Navbar.vue';
import TermsOfServiceModal from './components/users/TermsOfServiceModal.vue';

export default {
  name: 'App',
  components: {
    appNavbar: Navbar,
    appTermsOfServiceModal: TermsOfServiceModal,
  },
  computed: {
    ...mapGetters({
      currentUser: 'user',
    }),
    content() {
      if (this.currentUser) { return 'content'; }
      return 'signin';
    },
  },
  watch: {
    currentUser: {
      handler() {
        if (this.currentUser && !this.currentUser.termsOfService) {
          this.$bvModal.show('terms-of-service-modal');
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions([
      'autoLogin',
    ]),
  },
  created() {
    this.autoLogin();
  },
};
</script>

<style lang="scss">

#signin:before {
  content: "";
  position: fixed;
  left: 0;
  right: 0;
  z-index: -1;

  display: block;
  background-size:cover;
  background: url(/assets/images/HomeScreenMedium.png) no-repeat center center fixed;
  width: 100%;
  height: 100%;

  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -o-filter: blur(4px);
  -ms-filter: blur(4px);
  filter: blur(4px);
}

@media only screen and (min-width: 992px) and (max-width: 1300px) {
  #signin:before {
    background: url(/assets/images/HomeScreenSmall.png) no-repeat center center fixed;
  }
}

@media only screen and (min-width: 1600px) {
  #signin:before {
    background: url(/assets/images/HomeScreenLarge.png) no-repeat center center fixed;
  }
}

body {
  background-color: #f4f4f4 !important;
}

#content {
  min-height: calc(100vh - 153px);
  margin-top: 20px;
}

#signin {
  min-height: 100vh;
  background-color: white !important;
  z-index: 1;
  overflow: auto;
  position: relative;
}

.d-print-only {
  display: none;
}

@media print {
    canvas {
        min-height: 100%;
        max-width: 100%;
        max-height: 100%;
        height: auto!important;
        width: auto!important;
    }

    #container_ingesta-day * {
      font-size: small !important;
    }

    .d-print-only {
      display: unset !important;
    }
}

.header__navbar-fix {
  margin-top: 11.5rem;
}

._vue-flash-msg-body {
  z-index: 9999;
}

</style>
