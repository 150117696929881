import apiClient from '../../utilities/api_client';
import { UPDATE_MOBILIZATION_CATEGORY } from '../../graphql/mobilization_categories/mutations';

const mutations = {
  // THIS.state can access other state
  UPDATE_MOBILIZATION_CATEGORY(state, mobilizationCategory) {
    const ehpadMobCatIndex = this.state.ehpads.ehpad.mobilizationCategories.findIndex((el) => el.id === mobilizationCategory.id);
    this.state.ehpads.ehpad.mobilizationCategories.splice(ehpadMobCatIndex, 1, mobilizationCategory);
  },
};

const actions = {
  updateMobilizationCategory: async ({ commit, dispatch }, formData) => {
    const { data } = await apiClient.mutate({
      mutation: UPDATE_MOBILIZATION_CATEGORY,
      variables: { ...formData },
    });
    const { mobilizationCategory } = data.updateMobilizationCategory;
    commit('UPDATE_MOBILIZATION_CATEGORY', mobilizationCategory);
  },
};

export default {
  mutations,
  actions,
};
