import gql from 'graphql-tag';
import { resourceFragment } from './fragments';

export const FETCH_RESOURCES = gql`
  query FETCH_RESOURCES($ehpadId: ID!) {
    resources(ehpadId: $ehpadId) {
      ...ResourceFragment
    }
  }
  ${resourceFragment}
`;

export const FIND_RESOURCE = gql`
  query FIND_RESOURCE($id: ID!) {
    findResource(id: $id) {
      ...ResourceFragment
    }
  }
  ${resourceFragment}
`;
