<template>
  <b-modal title="Ajouter un commentaire" v-bind="$attrs" class="col-12 text-center">
    <b-container fluid>
      <div>RESIDENT : {{ residentName | capitalizeString }}</div>
      <div>
        TACHE/ACTIVITE : {{ eventResidentTask.task.title | capitalizeString }}
      </div>
      <b-form>
        <div class="pt-2">
          <b-form-textarea
            id="comment"
            v-model="comment"
            type="text"
            size="sm"
            rows="4"
            placeholder="Saisissez un commentaire"
          />
        </div>

        <div v-if="error" style="color: red;" class="text-center mt-2">
          <p v-for="(error, i) of error.graphQLErrors" :key="i">
            {{ error.message }}
          </p>
        </div>

        <b-button
          class="mt-3 d-flex mx-auto"
          variant="primary"
          size="sm"
          @click.prevent="addComment"
          >Valider</b-button
        >
      </b-form>
      <hr />
    </b-container>
  </b-modal>
</template>

<script>
import { UPDATE_EVENT_RESIDENT_TASKS } from '../../graphql/event_resident_tasks/mutations';

export default {
  props: ['eventResidentTask', 'resident'],
  data() {
    return {
      comment: null,
      error: null,
    };
  },
  created() {
    this.comment = this.eventResidentTask.comment;
  },
  watch: {
    'eventResidentTask.comment': {
      handler() {
        this.comment = this.eventResidentTask.comment;
      },
    },
  },
  methods: {
    async addComment() {
      await this.$apollo.mutate({
        mutation: UPDATE_EVENT_RESIDENT_TASKS,
        variables: {
          id: this.eventResidentTask.id,
          mobista: this.eventResidentTask.mobista,
          comment: this.comment,
        },
      }).then((result) => {
        this.error = null;
        this.$emit('update-event-resident-task');
        this.$bvModal.hide(`add-mobista-comment-modal${this.resident.id}-${this.eventResidentTask.task.id}-${this.eventResidentTask.eventId}-${this.eventResidentTask.eventDate}`);
      }).catch((error) => {
        this.error = error;
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
    },
  },
  computed: {
    residentName() {
      let { firstName } = this.resident;
      if (firstName === null) {
        firstName = '';
      }
      return `${firstName} ${this.resident.name.toUpperCase()}`;
    },
  },
};
</script>

<style lang="scss" scoped></style>
