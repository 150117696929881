<template>
    <div>
        <app-resources-table />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ResourcesTable from '../../components/resources/Table.vue';

export default {
  components: {
    appResourcesTable: ResourcesTable,
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
    }),
  },
};
</script>

<style lang="scss" scoped>
</style>
