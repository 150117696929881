<template>
  <b-container fluid>
    <app-col-content>
      <b-row align-h="center">
        <b-col cols="12" md="8">
            <div class="d-flex justify-content-center align-items-center mb-3">
                <span class="text-center">
                    <h5 class="m-0" style="display: inline-block;margin: 0;">
                    Liste des utilisateurs
                    </h5>
                    <b-button class="mb-2 ml-4" variant="primary" size="sm" v-b-modal.new-user-modal>
                    <i class="fas fa-plus" />
                        Créer un utilisateur
                    </b-button>
                </span>
            </div>

            <app-new-user-modal
            id="new-user-modal"
            hide-footer
            />

            <b-table
              bordered
              striped
              small
              hover
              :items="users"
              :fields="fields"
              :sort-by.sync="sortBy"
              class="m-0"
              ref="userTable"
              id="userTable"
            >

            <template v-slot:cell(ehpad)="data">{{
              (data.item.ehpad && `${data.item.ehpad.name}`) || 'Accès à tous les EHPAD'
            }}</template>

            <template v-slot:head(actions)>{{ '' }}</template>

            <template v-slot:cell(actions)="data">
                <b-button variant="link" size="sm" v-b-modal.info-user-modal @click="selectUser(data.item)">
                <i class="fas fa-edit" />
                Modifier
                </b-button>
            </template>
            </b-table>

            <app-info-user-modal
            v-if="selectedUser"
            id="info-user-modal"
            hide-footer
            :user="selectedUser"
            />

        </b-col>
    </b-row>
    </app-col-content>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import ColContent from '../../components/layout/ColContent.vue';
import InfoUserModal from '../../components/users/InfoUserModal.vue';
import NewUserModal from '../../components/users/NewUserModal.vue';

export default {
  components: {
    appColContent: ColContent,
    appInfoUserModal: InfoUserModal,
    appNewUserModal: NewUserModal,
  },
  data() {
    return {
      selectedUser: null,
      sortBy: 'email',
    };
  },
  created() {
    this.setUsers();
    this.setEhpads();
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', () => {
      this.selectedUser = null;
    });
  },
  methods: {
    ...mapActions(['setUsers', 'setEhpads']),
    selectUser(user) {
      this.selectedUser = user;
    },
    ehpadElName(el) {
      return _.get(el, 'name', null);
    },
  },
  computed: {
    fields() {
      return [
        {
          key: 'email',
          label: 'Courriel',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'role',
          label: 'Role',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'ehpad',
          label: 'Ehpad',
          sortable: true,
          sortByFormatted: true,
          formatter: 'ehpadElName',
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'actions',
          label: 'actions',
          class: 'text-center',
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
      ];
    },
    ...mapGetters({
      users: 'users',
    }),
  },
};
</script>

<style lang="scss" scoped></style>
