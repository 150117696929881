import Vue from 'vue';
import Vuex from 'vuex';

import users from './modules/users';
import ehpads from './modules/ehpads';
import residents from './modules/residents';
import mobilizationTests from './modules/mobilizationTests';
import tasks from './modules/tasks';
import events from './modules/events';
import resources from './modules/resources';
import mobilizationCategories from './modules/mobilizationCategories';
import rooms from './modules/rooms';
import sectors from './modules/sectors';
import globalSettings from './modules/globalSettings';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    users,
    ehpads,
    residents,
    mobilizationTests,
    tasks,
    mobilizationCategories,
    rooms,
    sectors,
    events,
    resources,
    globalSettings,
  },
});
