<template>
    <div>
        <app-tasks-table />
    </div>
</template>

<script>
import TasksTable from '../../components/tasks/Table.vue';

export default {
  components: {
    appTasksTable: TasksTable,
  },
};
</script>

<style lang="scss" scoped>
</style>
