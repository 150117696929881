<template>
  <div>
    <b-row class="mb-2">
      <b-col cols="3">
        <label for="inline-form-input-title" class="pr-4 text-nowrap"
          >Titre de l'événement :
        </label>
      </b-col>
      <b-col cols="9">
        <b-form-input
          id="inline-form-input-title"
          size="sm"
          type="text"
          v-model="editableEvent.title"
          :disabled='disableEdit'
          :class="{ 'is-invalid': $v.editableEvent.title.$anyError }"
        />
        <b-form-invalid-feedback>Valeur requise (50 caractères max)</b-form-invalid-feedback>
      </b-col>
    </b-row>

                <!-- <multiselect
              v-model="editableEvent.title"
              :options="filteredEvents"
              :loading="isLoading"
              @search-change="asyncFind"
              :disabled='disableEdit'
              :searchable="true"
              label="title"
              track-by="title"
              :show-no-results="false"
              :internal-search="false"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="false"
              :options-limit="30"
              :limit="5"
              open-direction="bottom"
              placeholder="Taper un titre d'événement"
              selectLabel=""
              deselectLabel=""
              selectedLabel="Sélectionné"
              :class="{ 'is-invalid': $v.editableEvent.title.$anyError }"
            >
              <span slot="noResult">Pas de résultat correspondant</span>
            </multiselect> -->

    <b-row class="mb-2">
      <b-col cols="3">
        <label for="inline-form-input-title" class="pr-4 text-nowrap"
          >Début de l'événement :
        </label>
      </b-col>
      <b-col cols="6">
        <b-form-datepicker
          id="date-datepicker-start"
          :date-format-options="{
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            weekday: 'short',
          }"
          :disabled='disableEdit'
          label-help
          label-no-date-selected="Aucune date sélectionnée"
          size="sm"
          v-model="destructuredStart.day"
          locale="fr"
          :class="{ 'is-invalid': $v.destructuredStart.day.$anyError }"
        />
        <b-form-invalid-feedback v-if="!$v.destructuredStart.day.required">Valeur requise</b-form-invalid-feedback>
        <b-form-invalid-feedback v-else>La date est invalide</b-form-invalid-feedback>
      </b-col>

      <b-col cols="3">
        <b-input-group>
          <b-form-input
            v-model="destructuredStart.hour"
            type="text"
            placeholder="HH:mm:ss"
            :disabled='disableEdit'
            size="sm"
            :class="{ 'is-invalid': $v.destructuredStart.hour.$anyError }"
          ></b-form-input>
          <b-input-group-append>
            <b-form-timepicker
              v-model="destructuredStart.hour"
              locale="fr"
              show-seconds
              button-only
              right
              size="sm"
              :disabled='disableEdit'
              :class="{ 'is-invalid': $v.destructuredStart.hour.$anyError }"
            />
          </b-input-group-append>
          <b-form-invalid-feedback v-if="!$v.destructuredStart.hour.required">Valeur requise (HH:mm:ss)</b-form-invalid-feedback>
          <b-form-invalid-feedback v-else>La date est invalide</b-form-invalid-feedback>
        </b-input-group>
      </b-col>
    </b-row>

    <b-row class="mb-2">
      <b-col cols="3">
        <label for="inline-form-input-title" class="pr-4 text-nowrap"
          >Fin de l'événement :
        </label>
      </b-col>
      <b-col cols="6">
        <b-form-datepicker
          id="date-datepicker-end"
          :date-format-options="{
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            weekday: 'short',
          }"
          :disabled='disableEdit'
          label-help
          label-no-date-selected="Aucune date sélectionnée"
          size="sm"
          v-model="destructuredEnd.day"
          locale="fr"
          :class="{ 'is-invalid': $v.destructuredEnd.day.$anyError }"
        />
        <b-form-invalid-feedback v-if="!$v.destructuredEnd.day.required">Valeur requise</b-form-invalid-feedback>
        <b-form-invalid-feedback v-else>La date est invalide</b-form-invalid-feedback>
      </b-col>
      <b-col cols="3">
        <b-input-group>
          <b-form-input
            v-model="destructuredEnd.hour"
            type="text"
            placeholder="HH:mm:ss"
            :disabled='disableEdit'
            size="sm"
            :class="{ 'is-invalid': $v.destructuredEnd.hour.$anyError }"
          ></b-form-input>
          <b-input-group-append>
            <b-form-timepicker
              v-model="destructuredEnd.hour"
              locale="fr"
              show-seconds
              button-only
              right
              size="sm"
              :disabled='disableEdit'
              :class="{ 'is-invalid': $v.destructuredEnd.hour.$anyError }"
            />
          </b-input-group-append>
          <b-form-invalid-feedback v-if="!$v.destructuredEnd.hour.required">Valeur requise (HH:mm:ss)</b-form-invalid-feedback>
          <b-form-invalid-feedback v-else>La date est invalide</b-form-invalid-feedback>
        </b-input-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import moment from 'moment';
import { validationMixin } from 'vuelidate';
import { required, maxLength, helpers } from 'vuelidate/lib/validators';
import { hourCheck } from '../../regex';

const hourRegex = helpers.regex('hourRegex', hourCheck);

export default {
  props: {
    editableEvent: {
      type: Object,
      required: true,
    },
    disableEdit: {
      type: Boolean,
      required: true,
    },
  },
  mixins: [validationMixin],
  data() {
    return {
      destructuredStart: {
        day: null,
        hour: null,
      },
      destructuredEnd: {
        day: null,
        hour: null,
      },
    };
  },
  created() {
    moment.locale('fr-FR');
  },
  validations: {
    editableEvent: {
      title: {
        required,
        maxLength: maxLength(50),
      },
    },
    destructuredStart: {
      day: {
        required,
        isSameOrBeforeEnd(val) {
          const dayStart = moment(val, 'YYYY-MM-DD');
          const dayEnd = moment(this.destructuredEnd.day, 'YYYY-MM-DD');
          return dayStart.isSameOrBefore(dayEnd);
        },
      },
      hour: {
        required,
        hourRegex,
        // isBeforeEnd(val) {
        //   const hourStart = moment(val, 'HH:mm:ss');
        //   const hourEnd = moment(this.destructuredEnd.hour, 'HH:mm:ss');
        //   return hourStart.isBefore(hourEnd);
        // },
      },
    },
    destructuredEnd: {
      day: {
        required,
        isSameOrAfterEnd(val) {
          const dayEnd = moment(val, 'YYYY-MM-DD');
          const dayStart = moment(this.destructuredStart.day, 'YYYY-MM-DD');
          return dayEnd.isSameOrAfter(dayStart);
        },
      },
      hour: {
        required,
        hourRegex,
        // isAfterEnd(val) {
        //   const hourEnd = moment(val, 'HH:mm:ss');
        //   const hourStart = moment(this.destructuredStart.hour, 'HH:mm:ss');
        //   return hourEnd.isAfter(hourStart);
        // },
      },
    },
  },
  watch: {
    editableEvent: {
      handler() {
        this.destructuredStart = this.destructureDate(this.editableEvent.start);
        this.destructuredEnd = this.destructureDate(this.editableEvent.end);
      },
      immediate: true,
    },
    destructuredStart: {
      handler() {
        this.editableEvent.start = this.constructDate(this.destructuredStart);
      },
      deep: true,
    },
    destructuredEnd: {
      handler() {
        this.editableEvent.end = this.constructDate(this.destructuredEnd);
      },
      deep: true,
    },
  },
  methods: {
    destructureDate(date) {
      return {
        day: moment(date).format('YYYY-MM-DD'),
        hour: moment(date).format('HH:mm:ss'),
      };
    },
    constructDate(destructureDate) {
      return moment(`${destructureDate.day} ${destructureDate.hour}`, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
