import gql from 'graphql-tag';
import { resourceFragment } from '../resources/fragments';

export const FETCH_EVENTS_WITH_DATES = gql`
  query FETCH_EVENTS_WITH_DATES(
    $start: String!,
    $end: String!,
    $id: ID,
    $elementId: ID,
    $elementType: String,
    $resourcesIds: [String!],
    $titleContains: String,
    $includeEventResident: Boolean!
    $includeSequenceEventResidents: Boolean!
  ) {
    events(
    filter: {
      start: $start,
      end: $end,
      id: $id,
      elementId: $elementId,
      elementType: $elementType,
      resourcesIds: $resourcesIds,
      titleContains: $titleContains,
      includeEventResident: $includeEventResident,
      includeSequenceEventResidents: $includeSequenceEventResidents
    })  {
      id
      sequence
      title
      start
      end
      color
      borderColor
      textColor
      backgroundColor
      rrule
      exdate
      duration
      sequenceEventResidents @include(if: $includeSequenceEventResidents) {
        residentId
        startHour
        endHour
        duration
        resident {
          name
          firstName
        }
      }
      eventResident(residentId: $elementId) @include(if: $includeEventResident) {
        residentId
        id
        startHour
        endHour
        duration
        resident {
          name
          firstName
        }
      }
    }
  }
`;

// eventResidents for resources... need all residents for displaying in list

export const FIND_EVENT = gql`
  query FIND_EVENT(
    $id: ID,
    $elementId: ID,
    $elementType: String,
    $includeEventResident: Boolean!
  ) {
    events(
    filter: {
      id: $id,
      elementId: $elementId,
      elementType: $elementType,
      includeEventResident: $includeEventResident,
    })  {
      id
      title
      capacity
      start
      end
      color
      borderColor
      textColor
      backgroundColor
      rrule
      exdate
      duration
      sequence
      sequenceDefaultDuration
      eventDefaultTasks {
        id
        taskId
        task {
          id
          title
        }
        eventDefaultTaskOrder
      }
      eventResident(residentId: $elementId) @include(if: $includeEventResident) {
        residentId
        startHour
        endHour
        duration
        resident {
          name
          firstName
        }
      }
      residents {
        id
        name
        firstName
        sequenceEvent(eventId: $id) {
          id
          startHour
          endHour
          duration
        }
        lastMobilizationDiagnostic {
          id
          mobilizationCategory {
            id
            name
            color
          }
        }
        forbiddenTasks {
          id
        }
        room {
          id
          number
        }
        sector {
          id
          name
        }
      }
      ehpad {
        id
      }
      resources {
        ...ResourceFragment
      }
      mobilizationCategories {
        id
        name
        description
        color
      }
    }
  }
  ${resourceFragment}
`;
