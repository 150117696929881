<template>
  <div>
    <b-row v-if="residentMobilizationDiagnosticsForCharts !== undefined">
      <b-col class="col-2">Intervalle </b-col>
      <b-col class="col-2">
        <div>
          <b-form-select
            v-model="rangeForMobilizationDiagnosticsCharts"
            :options="chartRangeOptions"
            @change="residentMobilizationDiagnosticsForCharts = undefined"
          />
        </div>
      </b-col>
    </b-row>
    <div v-if="residentMobilizationDiagnosticsForCharts !== undefined" class="mt-3">
      <line-chart :data="dataResidentMobilizationDiagnosticsForCharts" :library="options"></line-chart>
    </div>
    <div class="text-center" style="color: #469AE0;" v-if="residentMobilizationDiagnosticsForCharts === undefined">
      <h2>
        <i class="fa fa-spin fa-circle-notch" />
        Chargement des graphiques...
      </h2>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import { FETCH_RESIDENT_MOBILIZATION_DIAGNOSTICS_FOR_CHARTS } from '../../graphql/charts/queries';

export default {
  props: ['resident'],
  data() {
    return {
      dataResidentMobilizationDiagnosticsForCharts: [],
      rangeForMobilizationDiagnosticsCharts: { months: null, year: null },
      options: {
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              displayFormats: {
                day: 'DD MMM YYYY',
              },
            },
          }],
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Diagnostics de mobilité',
            },
            display: true,
            ticks: {
              min: 1, // minimum value
              max: 5, // maximum value
              stepSize: 1,
              display: true,
              stacked: true,
            },
          }],
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
      },
    };
  },
  apollo: {
    residentMobilizationDiagnosticsForCharts: {
      query: FETCH_RESIDENT_MOBILIZATION_DIAGNOSTICS_FOR_CHARTS,
      variables() {
        return {
          residentId: this.resident.id,
          year: parseInt(this.rangeForMobilizationDiagnosticsCharts.year, 10),
          months: parseInt(this.rangeForMobilizationDiagnosticsCharts.months, 10),
        };
      },
      fetchPolicy: 'network-only',
    },
  },
  watch: {
    residentMobilizationDiagnosticsForCharts: {
      handler() {
        if (this.residentMobilizationDiagnosticsForCharts !== undefined) {
          this.dataResidentMobilizationDiagnosticsForCharts = this.buildDataResidentMobilizationDiagnosticsForCharts;
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
    }),
    chartRangeOptions() {
      return [
        { value: { months: null, year: null }, text: '12 mois glissants' },
        { value: { months: 6, year: null }, text: '6 mois glissants' },
        { value: { months: 3, year: null }, text: '3 mois glissants' },
        { value: { months: null, year: moment().year() - 2 }, text: moment().year() - 2 },
        { value: { months: null, year: moment().year() - 1 }, text: moment().year() - 1 },
        { value: { months: null, year: moment().year() }, text: moment().year() },
      ];
    },
    buildDataResidentMobilizationDiagnosticsForCharts() {
      return [
        {
          name: 'Diagnostics de mobilité',
          data: this.residentMobilizationDiagnosticsForCharts,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
