import gql from 'graphql-tag';

export const resourceFragment = gql`
  fragment ResourceFragment on Resource {
    id
    name
    firstName
    job
    capacity
    resourceCategory {
      id
      title
      icon
    }
  }
`

