<template>
  <div>
    <div class="d-flex justify-content-center align-items-center mb-3">
      <span class="text-center">
        <h3 class="m-0" style="display: inline-block;margin: 0;">
          Nouveau Test
        </h3>
      </span>
    </div>
    <b-row align-h="center">
      <b-col cols="12" xl="10">
        <app-new-mobilization-test-form />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import NewMobilizationTestForm from '../../components/mobilization_tests/NewMobilizationTestForm.vue';

export default {
  components: {
    appNewMobilizationTestForm: NewMobilizationTestForm,
  },
};

</script>

<style>

</style>
