import gql from 'graphql-tag';

export const CREATE_MOBILIZATION_DIAGNOSTIC = gql`
  mutation CREATE_MOBILIZATION_DIAGNOSTIC(
    $residentId: ID!
    $mobilizationCategory: MobilizationCategoryAttributes!
    $value: Int!
    $date: String!
    $comment: String
  ) {
    createMobilizationDiagnostic(
      input: { 
        residentId: $residentId, 
        mobilizationCategory: $mobilizationCategory,
        value: $value,
        date: $date,
        comment: $comment,
      }
    ) {
      mobilizationDiagnostic {
        id
      }
    }
  }
`;