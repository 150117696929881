<template>
  <b-modal :title="modalTitle" v-bind="$attrs" size="xl" class="col-12 text-center" :no-close-on-backdrop="loading">
    <div class="text-center" style="color: #469AE0;" v-if="loadingSelectedEvent">
      <h4>
        <i class="fa fa-spin fa-circle-notch" />
        Chargement...
      </h4>
    </div>
    <b-container fluid v-else>
      <b-form>
        <p style="color: red;font-weight: bold;" v-if="disableEdit && this.elementType === 'Resident'">
          Vous ne pouvez pas modifier un événement du programme global pour un résident.
          Vous pouvez supprimer cet événement pour ce résident ou le modifier pour tous les résidents concernés dans le programme global.
        </p>
        <p style="color: red;font-weight: bold;" v-if="this.editableEvent.vuexModified === true">
          Pour modifier un événement 'séquence' vous devez modifier l'événement parent.
        </p>
        <p style="color: red;font-weight: bold;" v-if="(this.elementType === 'Ehpad' && this.editableEvent.ehpad === null && this.selectedEvent !== null)">
          ERREUR, NE dois pas se produire
        </p>

        <app-event-modal-form
          ref="eventModalForm"
          :editableEvent="editableEvent"
          :disableEdit="disableEdit"
        />

      <!-- https://github.com/jakubroztocil/rrule -->
        <app-event-modal-recur-form
          v-if="checkSameDayEvent"
          ref="eventModalRecurForm"
          :editableEvent="editableEvent"
          :disableEdit="disableEdit"
        />

        <app-event-modal-form-categories
          ref="eventModalFormCategories"
          :editableEvent="editableEvent"
          :disableEdit="disableEdit"
          :elementType="elementType"
          v-show="displayFormCategoriesConditions"
        />

        <app-event-modal-form-resources
          ref="eventModalFormResources"
          :editableEvent="editableEvent"
          :disableEdit="disableEdit"
          :elementType="elementType"
        />

        <div v-show="this.elementType !== 'Resident'">
          <app-event-modal-sequence-form
            v-if="checkSameDayEvent"
            ref="eventModalSequenceForm"
            :editableEvent="editableEvent"
            :disableEdit="disableEdit"
          />
        </div>

        <div v-show="this.elementType !== 'Resident'">
          <div class="validate-btn">
            <b-button variant="primary" @click.prevent="openTasksModal">
              <img src="/assets/images/tasks-blanc_borderless.png" alt="Tasks" width="28" height="28">
              Gérer les activités
              <img src="/assets/images/tasks-blanc_borderless.png" alt="Tasks" width="28" height="28">
            </b-button>
          </div>
          <app-event-modal-tasks-modal
            id="event-modal-tasks-modal"
            :editableEvent="editableEvent"
            :disableEdit="disableEdit"
            hide-footer
          />
        </div>

      <!-- https://github.com/SortableJS/Vue.Draggable -->
        <div v-if="elementType !== 'Resident'">
          <app-event-modal-residents-list
            :editableEvent="editableEvent"
            :disableEdit="disableEdit"
            @open-event-mobista-modal="(resident) => openMobistaModal(resident)"
          />
        <app-event-modal-mobista-modal
          :id='`event-modal-mobista-modal`'
          :editableEvent="editableEvent"
          :resident="residentForMobista"
          :computedEventResidentTasks="computedEventResidentTasks"
          @update-event-resident-task="findEventResidentTasks()"
          hide-footer
        />
        </div>

        <div v-else-if="elementType === 'Resident' && resident">
          <app-event-mobista-card
            :resident="resident"
            :computedEventResidentTasks="computedEventResidentTasks"
            @update-event-resident-task="findEventResidentTasks()"
          />
        </div>

        <div class="text-center" style="color: #469AE0;" v-if="loading">
          <h4>
            <i class="fa fa-spin fa-circle-notch" />
            Traitement...
          </h4>
        </div>

        <div class="footer" v-else>
          <div class="validate-btn" v-if="!disableEdit && selectedEvent === null">
            <b-button variant="primary" size="sm" @click.prevent="createEvent">Créer</b-button>
          </div>
          <div class="validate-btn" v-if="!disableEdit && selectedEvent !== null">
            <b-button variant="primary" size="sm" @click.prevent="updateEvent">Modifier</b-button>
          </div>
          <div class="destroy-btn" v-if="selectedEvent !== null && !(elementType === 'Resource' && editableEvent.vuexModified === true)">
            <b-button variant="danger" size="sm" @click.prevent="destroyEvent"
              ><i class="fa fa-trash-alt" v-b-tooltip.hover.v-danger title="Supprimer" />
            </b-button>
          </div>
        </div>
      </b-form>

    </b-container>

  </b-modal>
</template>

<script>
import Swal from 'sweetalert2';
import _ from 'lodash';
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import EventModalResidentsList from './EventModalResidentsList.vue';
import EventModalTasksModal from './EventModalTasksModal.vue';
import EventModalForm from './EventModalForm.vue';
import EventModalSequenceForm from './EventModalSequenceForm.vue';
import EventModalRecurForm from './EventModalRecurForm.vue';
import EventModalFormCategories from './EventModalFormCategories.vue';
import EventModalFormResources from './EventModalFormResources.vue';
import EventModalMobistaModal from './EventModalMobistaModal.vue';
import EventMobistaCard from '../mobista/EventMobistaCard.vue';

export default {
  props: ['selectedEvent', 'elementType', 'elementId', 'clickInfo', 'loadingSelectedEvent'],
  data() {
    return {
      loading: false,
      disableEdit: false,
      eventDate: null,
      computedEventResidentTasks: null,
      residentForMobista: null,
      editableEvent: {
        title: '',
        start: null,
        end: null,
        mobilizationCategories: [],
        eventDefaultTasks: [],
        resources: [],
        capacity: null,
        residents: [],
        ehpad: null,
        rrule: null,
        sequence: false,
        sequenceDefaultDuration: null,
      },
    };
  },
  components: {
    appEventModalForm: EventModalForm,
    appEventModalSequenceForm: EventModalSequenceForm,
    appEventModalRecurForm: EventModalRecurForm,
    appEventModalFormCategories: EventModalFormCategories,
    appEventModalFormResources: EventModalFormResources,
    appEventModalResidentsList: EventModalResidentsList,
    appEventModalMobistaModal: EventModalMobistaModal,
    appEventModalTasksModal: EventModalTasksModal,
    appEventMobistaCard: EventMobistaCard,
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if (modalId === `event-modal-${this.elementType}`) {
        Object.assign(this.$data, this.$options.data.apply(this));
      }
    });
  },
  watch: {
    selectedEvent: {
      handler() {
        if (this.selectedEvent !== null) {
          this.duplicateEvent(this.selectedEvent);
          if (this.elementType === 'Resident') {
            this.residentForMobista = this.resident;
            this.eventDate = moment(this.editableEvent.start).format('YYYY-MM-DD');
            this.findEventResidentTasks();
          }
          if (this.elementType === 'Resident' && this.editableEvent.ehpad !== null) {
            this.disableEdit = true;
          }
          if (this.elementType === 'Resident' && this.editableEvent.sequence === true) {
            this.disableEdit = true;
          }
          if (this.elementType === 'Resource' && this.editableEvent.vuexModified === true) {
            this.disableEdit = true;
          }
        }
      },
      immediate: true,
    },
    clickInfo: {
      handler() {
        if (this.clickInfo !== null && this.selectedEvent === null) {
          this.editableEvent.start = this.clickInfo.startStr;
          this.editableEvent.end = this.clickInfo.endStr;
          if (this.elementType === 'Resident' && this.selectedEvent === null) {
            this.editableEvent.capacity = 1;
            this.editableEvent.residents = [this.resident];
          }
          if (this.elementType === 'Resource' && this.selectedEvent === null) {
            this.editableEvent.resources = [this.resource];
            this.editableEvent.capacity = this.resource.capacity;
          }
        }
      },
      immediate: true,
    },
    'editableEvent.mobilizationCategories': {

      // Si un événement est selectionné
      // On prend residents la premiére fois
      // Sinon residents est null et changera en fonction des catégories
      handler() {
        // const residents = this.selectedEvent.residents;
        // this.editableEvent.mobilizationCategories.forEach((mobilizationCategory) => {
        //   residents.push(mobilizationCategory.residents.map((resident) => {
        //     return resident;
        //   }));
        // }, this);
        // this.editableEvent.residents = this.selectedEvent.residents.push(residents.flat());
        // if (this.editableEvent.mobilizationCategories.length === 0) {
        //   this.editableEvent.residents = [];
        // }
        // if (this.editableEvent.mobilizationCategories.length !== 0) {
        //   // Because problem whend its called in first place (before changing mobilizationCategories)
        //   // Will change in v1.5
        //   if (this.editableEvent.mobilizationCategories[0].numberOfResidents !== undefined) {
        //     this.availableResidents = this.formatedAvailableResidents;
        //   }
        // }
      },
    },
    // availableResidents: {
    //   // When available residents change (category added or removed) selected residents are removed if the category is remove from the form
    //   handler(newVal) {
    //     const filteredResidents = newVal.filter(({ id: id1 }) => this.editableEvent.residents.some(({ id: id2 }) => id2 === id1));
    //     this.editableEvent.residents = filteredResidents;
    //   },
    // },
  },
  methods: {
    ...mapActions(['createEvent', 'updateEvent', 'destroyEvent', 'findEventResidentTasks']),
    openTasksModal() {
      this.$bvModal.show('event-modal-tasks-modal');
    },
    openMobistaModal(resident) {
      this.residentForMobista = resident;
      this.eventDate = moment(this.editableEvent.start).format('YYYY-MM-DD');
      this.findEventResidentTasks();
      this.$bvModal.show('event-modal-mobista-modal');
    },
    async findEventResidentTasks() {
      await this.$store.dispatch('findEventResidentTasks', {
        eventId: this.editableEvent.id,
        residentId: this.residentForMobista.id,
        eventDate: this.eventDate,
      }).then((result) => {
        this.computedEventResidentTasks = result;
      }).catch((error) => {
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      }).finally(() => {
        this.computeEventDefaultTasks();
      });
    },
    computeEventDefaultTasks() {
      // Filter if element already in computedEventResidentTasks (record)
      const eventResidentTasksIds = this.computedEventResidentTasks.map((eventResidentTask) => eventResidentTask.task.id);
      const filteredTasks = this.editableEvent.eventDefaultTasks.filter((eventDefaultTask) => !eventResidentTasksIds.includes(eventDefaultTask.task.id));
      filteredTasks.forEach((element) => {
        this.computedEventResidentTasks.push({
          id: null,
          task: element.task,
          taskId: element.task.id,
          mobista: null,
          eventResidentTaskOrder: element.eventDefaultTaskOrder,
          comment: null,
          eventDate: this.eventDate,
          residentId: this.residentForMobista.id,
          eventId: this.editableEvent.id,
        });
      });
      this.computedEventResidentTasks.sort((a, b) => a.eventResidentTaskOrder - b.eventResidentTaskOrder);
    },
    duplicateEvent(event) {
      this.editableEvent = { ...event };
      if (this.editableEvent.rrule) {
        this.editableEvent.start = this.clickInfo.event.start;
        this.editableEvent.end = this.clickInfo.event.end;
      }
    },
    async createEvent() {
      this.$refs.eventModalForm.$v.$touch();
      this.$refs.eventModalFormResources.$v.$touch();
      if (this.$refs.eventModalForm.$v.$anyError) return;
      if (this.$refs.eventModalFormResources.$v.$anyError) return;
      if (this.checkSameDayEvent) {
        this.$refs.eventModalSequenceForm.$v.$touch();
        if (this.$refs.eventModalSequenceForm.$v.$anyError) return;
      }
      this.loading = true;
      // Global Event ? Set EhpadId
      const ehpadId = ((this.elementType === 'Ehpad') || (this.elementType === 'Resource')) ? this.currentEhpad.id : null;
      const residents = this.editableEvent.residents.map((resident) => _.pick(resident, 'id', 'sequenceEvent'));
      await this.$store.dispatch('createEvent', {
        title: this.editableEvent.title,
        capacity: this.editableEvent.capacity,
        start: this.editableEvent.start,
        end: this.editableEvent.end,
        rrule: this.editableEvent.rrule,
        mobilizationCategories: this.editableEvent.mobilizationCategories,
        eventDefaultTasks: this.editableEvent.eventDefaultTasks,
        resources: this.editableEvent.resources,
        sequence: this.editableEvent.sequence,
        sequenceDefaultDuration: this.editableEvent.sequenceDefaultDuration,
        residents,
        ehpadId,
      }).then((result) => {
        this.loading = false;
        this.$emit('events-refetch');
        if (this.elementType === 'Ehpad' && this.$parent.$parent.$refs.residentCalendar) {
          const { datesInfo } = this.$parent.$parent.$refs.residentCalendar;
          this.$parent.$parent.$refs.residentCalendar.asyncFetchEvents(datesInfo);
        }
        this.$bvModal.hide(`event-modal-${this.elementType}`);
      }).catch((error) => {
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
    },
    async updateEvent() {
      let swal = null;
      if (this.selectedEvent.rrule !== null) {
        swal = Swal.fire({
          title: 'Êtes vous sûr ?',
          text: "S'il s'agit d'un événement d'établissement il sera modifié pour tout les résidents",
          icon: 'warning',
          input: 'checkbox',
          inputValue: 0,
          inputPlaceholder:
            'Cocher pour modifier cet événement ET TOUS les suivants',
          showCancelButton: true,
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non',
          allowOutsideClick: false,
        });
      }
      else {
        swal = Swal.fire({
          title: 'Êtes vous sûr ?',
          text: "S'il s'agit d'un événement d'établissement il sera modifié pour tout les résidents",
          icon: 'warning',
          inputValue: 0,
          showCancelButton: true,
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non',
          allowOutsideClick: false,
        });
      }
      swal.then(async (result) => {
        if (result.isConfirmed) {
          this.$refs.eventModalForm.$v.$touch();
          this.$refs.eventModalFormResources.$v.$touch();
          if (this.$refs.eventModalForm.$v.$anyError) return;
          if (this.$refs.eventModalFormResources.$v.$anyError) return;
          if (this.checkSameDayEvent) {
            this.$refs.eventModalSequenceForm.$v.$touch();
            if (this.$refs.eventModalSequenceForm.$v.$anyError) return;
          }
          this.loading = true;
          const residents = this.editableEvent.residents.map((resident) => _.pick(resident, 'id', 'sequenceEvent'));
          let thisEventOnly = true;
          if (result.value === 1) { thisEventOnly = false; }
          await this.$store.dispatch('updateEvent', {
            id: this.editableEvent.id,
            title: this.editableEvent.title,
            capacity: this.editableEvent.capacity,
            start: this.editableEvent.start,
            end: this.editableEvent.end,
            rrule: this.editableEvent.rrule,
            sequence: this.editableEvent.sequence,
            sequenceDefaultDuration: this.editableEvent.sequenceDefaultDuration,
            mobilizationCategories: this.editableEvent.mobilizationCategories,
            eventDefaultTasks: this.editableEvent.eventDefaultTasks,
            resources: this.editableEvent.resources,
            thisEventOnly,
            residents,
          }).then((result) => {
            this.loading = false;
            this.$emit('events-refetch');
            if (this.elementType === 'Ehpad' && this.$parent.$parent.$refs.residentCalendar) {
              const { datesInfo } = this.$parent.$parent.$refs.residentCalendar;
              this.$parent.$parent.$refs.residentCalendar.asyncFetchEvents(datesInfo);
            }
            this.$bvModal.hide(`event-modal-${this.elementType}`);
          }).catch((error) => {
            this.flashMessage.show({
              status: 'error',
              title: error.graphQLErrors[0].extensions.code,
              message: error.graphQLErrors[0].message,
            });
          });
        }
      });
    },
    destroyEvent() {
      let swal = null;
      if (this.selectedEvent.rrule !== null) {
        swal = Swal.fire({
          title: 'Êtes vous sûr ?',
          text: "Êtes vous sûr de vouloir supprimer cet événement définitivement ? (S'il s'agit d'un événement d'établissement il sera supprimé pour tout les résidents)",
          icon: 'warning',
          input: 'checkbox',
          inputValue: 0,
          inputPlaceholder:
            'Cocher pour supprimer cet événement ET TOUS les suivants',
          showCancelButton: true,
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non',
          allowOutsideClick: false,
        });
      } else {
        swal = Swal.fire({
          title: 'Êtes vous sûr ?',
          text: "Êtes vous sûr de vouloir supprimer cet événement définitivement ? (S'il s'agit d'un événement d'établissement il sera supprimé pour tout les résidents)",
          icon: 'warning',
          inputValue: 0,
          showCancelButton: true,
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non',
          allowOutsideClick: false,
        });
      }
      swal.then(async (result) => {
        if (result.isConfirmed) {
          this.loading = true;
          let thisEventOnly = true;
          if (result.value === 1) { thisEventOnly = false; }
          await this.$store.dispatch('destroyEvent', {
            id: this.editableEvent.id,
            elementType: this.elementType,
            elementId: this.elementId,
            // For rrule events (to know which event instance we are talking about)
            date: this.date,
            thisEventOnly,
          }).then((result) => {
            this.loading = false;
            this.$emit('events-refetch');
            if (this.elementType === 'Ehpad' && this.$parent.$parent.$refs.residentCalendar) {
              const { datesInfo } = this.$parent.$parent.$refs.residentCalendar;
              this.$parent.$parent.$refs.residentCalendar.asyncFetchEvents(datesInfo);
            }
            this.$bvModal.hide(`event-modal-${this.elementType}`);
          }).catch((error) => {
            this.flashMessage.show({
              status: 'error',
              title: error.graphQLErrors[0].extensions.code,
              message: error.graphQLErrors[0].message,
            });
          });
        }
      });
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
      resident: 'resident',
      resource: 'resource',
    }),
    residentName() {
      let { firstName } = this.resident;
      if (firstName === null) {
        firstName = '';
      }
      return `${firstName} ${this.resident.name.toUpperCase()}`;
    },
    date() {
      return moment(this.editableEvent.start).format('YYYY-MM-DD');
    },
    checkSameDayEvent() {
      if (moment(this.editableEvent.start).format('DD-MM-YYYY') !== moment(this.editableEvent.end).format('DD-MM-YYYY')) {
        return false;
      }
      return true;
    },
    displayFormCategoriesConditions() {
      if (
        this.elementType === 'Ehpad'
        || this.elementType === 'Resource'
        || this.editableEvent.ehpad !== null
        || (this.resident && this.resident.lastMobilizationDiagnostic === null)
      ) {
        return true;
      }
      return false;
    },
    modalTitle() {
      if (this.selectedEvent !== null) {
        const eventType = this.editableEvent.ehpad === null ? 'Resident' : 'Ehpad';
        if (this.elementType === 'Resident' && this.resident) {
          return `Modifier un événement ${eventType} pour ${this.residentName}`;
        }
        return `Modifier un événement ${eventType}`;
      }
      return `Créer un événement ${this.elementType}`;
    },
  },
};
</script>

<style lang="scss" scoped>

  @media (max-width: 1200px) {
    ::v-deep .modal-xl {
      max-width: 90% !important;
    }
  }

  .footer {
    display: flex;
    justify-content: center;
  }

  .validate-btn {
    flex: 1;
    display: flex;
    justify-content: center;
    transform: translateX(10px); /*D element Width[if needed]*/
  }

</style>
