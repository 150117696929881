import gql from 'graphql-tag';

export const FETCH_RESIDENT_MOBILIZATION_DIAGNOSTICS_FOR_CHARTS = gql`
  query FETCH_RESIDENT_MOBILIZATION_DIAGNOSTICS_FOR_CHARTS($year: Int, $months: Int, $residentId: ID!) {
    residentMobilizationDiagnosticsForCharts(year: $year, months: $months, residentId: $residentId)
  }
`;

export const FETCH_RESIDENT_TESTS_FOR_CHARTS = gql`
  query FETCH_RESIDENT_TESTS_FOR_CHARTS($year: Int, $months: Int, $residentId: ID!) {
    residentTestsForCharts(year: $year, months: $months, residentId: $residentId)
  }
`;
