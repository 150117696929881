<template>
  <div>
    <div class="d-flex justify-content-center align-items-center mb-3">
      <span class="text-center">
        <h3 class="m-0 mb-3" style="display: inline-block;margin: 0;">
          Modifier une Ressource
        </h3>
      </span>
    </div>
    <b-row align-h="center">
      <b-col cols="12" xl="8">
        <app-edit-resource-form />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import EditResourceForm from '../../components/resources/EditResourceForm.vue';

export default {
  components: {
    appEditResourceForm: EditResourceForm,
  },
  props: ['resourceId'],
  methods: {
    ...mapActions(['findResource']),
  },
  computed: {
    ...mapGetters({
      resource: 'resource',
      currentEhpad: 'ehpad',
    }),
  },
  created() {
    this.findResource(this.resourceId);
  },
};

</script>

<style>

</style>
