import gql from 'graphql-tag';

export const CREATE_MOBILIZATION_TEST = gql`
  mutation CREATE_MOBILIZATION_TEST(
    $name: String!
    $url: String
    $description: String!
    $mobilizationTestCategories: [MobilizationTestCategoryAttributes!]!
  ) {
    createMobilizationTest(
      input: {
        name: $name
        url: $url
        description: $description
        mobilizationTestCategories: $mobilizationTestCategories
      }
    ) {
      mobilizationTest {
        id
        name
        url
        description
        mobilizationTestCategories {
          id
          alertTreshold
          mobilizationCategory {
            id
            name
            number
            color
          }
        }
        mobilizationCategories {
          id
          name
          number
          color
          ehpad {
            id
          }
        }
      }
    }
  }
`;

export const UPDATE_MOBILIZATION_TEST = gql`
  mutation UPDATE_MOBILIZATION_TEST(
    $id: ID!
    $name: String!
    $url: String
    $description: String!
    $mobilizationTestCategories: [MobilizationTestCategoryAttributes!]!
  ) {
    updateMobilizationTest(
      input: {
        id: $id
        name: $name
        url: $url
        description: $description
        mobilizationTestCategories: $mobilizationTestCategories
      }
    ) {
      mobilizationTest {
        id
        name
        url
        description
        mobilizationTestCategories {
          id
          alertTreshold
          mobilizationCategory {
            id
            name
            number
            color
          }
        }
        mobilizationCategories {
          id
          name
          number
          color
          ehpad {
            id
          }
        }
      }
    }
  }
`;

export const DESTROY_MOBILIZATION_TEST = gql`
  mutation DESTROY_MOBILIZATION_TEST($id: ID!) {
    destroyMobilizationTest(
      input: {
        id: $id
      }
    ) {
      mobilizationTest {
        id
      }
    }
  }
`;
