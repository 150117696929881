<template>
  <div>
    <b-row class="mb-2">
      <b-col cols="3">
        <label for="inline-form-input-resources" class="pr-4 text-nowrap">Ressources : </label>
      </b-col>
      <b-col cols="6">
        <multiselect
          v-model="editableEvent.resources"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="false"
          :options="resourcesOptions"
          placeholder="Selectionner une/plusieurs ressources"
          label="name"
          track-by="name"
          selectLabel=""
          deselectLabel=""
          selectedLabel="Sélectionné"
          @input="onChangeResource"
          :disabled='disableEdit'
        >
          <span slot="noResult">Pas de résultat correspondant</span>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <i :class="props.option.icon" />
              <span class="option__title">
                <span v-if="props.option.firstName"> {{ props.option.firstName }} </span>
                <span> {{ props.option.name }}</span>
                <span v-if="props.option.job">({{ props.option.job }})</span>
                |
                <span v-if="props.option.capacity">Cap : {{ props.option.capacity }})</span>
              </span>
            </div>
          </template>
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <i :class="props.option.icon" />
              <span class="option__title">
                <span v-if="props.option.firstName"> {{ props.option.firstName }} </span>
                <span> {{ props.option.name }}</span>
                <span v-if="props.option.job">({{ props.option.job }})</span>
                |
                <span v-if="props.option.capacity">Cap : {{ props.option.capacity }})</span>
              </span>
            </span>
          </template>
        </multiselect>
      </b-col>
      <b-col cols="1" class="my-auto">
        <label for="capacity-form" class="text-nowrap">Capacité : </label>
      </b-col>
      <b-col cols="2">
        <b-form-input
          id="capacity-form"
          type="number"
          :number="true"
          v-model="editableEvent.capacity"
          :disabled='disableEdit'
          :class="{ 'is-invalid': $v.editableEvent.capacity.$anyError }"
        />
        <b-form-invalid-feedback>Valeur requise (nombre)</b-form-invalid-feedback>
      </b-col>
    </b-row>

    <b-row class="mb-2">
    </b-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import Multiselect from 'vue-multiselect';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  props: ['editableEvent', 'disableEdit', 'elementType'],
  mixins: [validationMixin],
  data() {
    return {
    };
  },
  components: {
    Multiselect,
  },
  validations: {
    editableEvent: {
      capacity: {
        required,
      },
    },
  },
  methods: {
    onChangeResource() {
      if (this.elementType === 'Resident' && this.editableEvent.ehpad === null) {
        this.editableEvent.capacity = 1;
      } else {
        const minResource = _.minBy(this.editableEvent.resources, 'capacity');
        this.editableEvent.capacity = (minResource && minResource.capacity) || null;
      }
    },
  },
  computed: {
    ...mapGetters({
      resources: 'resources',
    }),
    resourcesOptions() {
      return this.resources.map((el) => ({
        id: el.id,
        name: el.name,
        firstName: el.firstName,
        job: el.job,
        capacity: el.capacity,
        icon: el.resourceCategory.icon,
      }));
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
