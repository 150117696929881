<template>
  <b-modal title="Residents disponibles" v-bind="$attrs" size="xl" class="col-12 text-center">
    <b-table
      sticky-header="800px"
      bordered
      striped
      small
      hover
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :items="availableResidents"
      :fields="computedFields"
      select-mode="multi"
      selectable
      @row-selected="onRowSelected"
      class="m-0"
      ref="residentTable"
      id="residentTable"
    >

      <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <span aria-hidden="true">&check;</span>
          <span class="sr-only">Sélectionné</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&nbsp;</span>
          <span class="sr-only">Non sélectionné</span>
        </template>
      </template>

      <template v-slot:cell(lastMobilizationDiagnostic)="data">
        <span v-html="$options.filters.coloredMobDiagHtml(data.item.lastMobilizationDiagnostic)">
        </span>
      </template>

      <template v-slot:cell(room)="data">
        <span>
          {{ (data.item.room && `N° ${data.item.room.number}`) || 'Aucun lit assigné' }}
        </span>
      </template>

      <template v-slot:cell(sector)="data">
        <span style="font-size: medium;">{{ (data.item.sector && `${data.item.sector.name}`) || 'Pas de secteur' }}</span>
      </template>

    </b-table>
    <b-button class="d-flex mx-auto mt-2" variant="primary" size="sm" @click.prevent="addResidents">
      Valider
    </b-button>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: ['availableResidents', 'editableEvent'],
  data() {
    return {
      sortBy: 'name',
      sortDesc: false,
      selectedResidents: [],
      fields: [
        {
          key: 'selected',
          label: 'Sélectionné',
        },
        {
          key: 'firstName',
          label: 'Prénom',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'name',
          label: 'Nom',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'lastMobilizationDiagnostic',
          label: 'Catégorie',
          formatter: (value, key, item) => {
            if (value === null) { return 'Pas de catégorie'; }
            return value.mobilizationCategory.name;
          },
          sortable: true,
          sortByFormatted: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'room',
          label: 'Lit',
          formatter: (value, key, item) => {
            return value.number;
          },
          sortable: true,
          sortByFormatted: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'sector',
          label: 'Secteur',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
      ],
    };
  },
  methods: {
    onRowSelected(items) {
      this.selectedResidents = items;
    },
    addResidents() {
      this.editableEvent.residents.push(...this.selectedResidents);
      this.$bvModal.hide('event-modal-residents-table');
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
    }),
    computedFields() {
      if (!this.currentEhpad.allSettings.configuration.sectors.activate_sectors) {
        return this.fields.filter((field) => field.key !== 'sector');
      }
      return this.fields;
    },
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep .small-size {
    width: 75px;
  }

  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: hidden;
  }
</style>
