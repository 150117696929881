<template>
  <b-container fluid fixed="sticky" :class="navClass" :style="styleObject">
    <div class="header__top-part d-flex align-items-center justify-content-center">
      <img src="/assets/images/Logo_mobi_rect_nb.png" class="app-logo mr-3 d-flex">
      <h2 class="m-0 title" v-if="currentEhpad">| {{ currentEhpad.name }}</h2>
    </div>
    <div v-if="currentUser" class="header__menu d-flex align-items-center justify-content-center">

      <router-link
        v-if="!currentUser.ehpad"
        to="/ehpads"
        tag="a"
        class="nav-item far fa-building fa-icon"
        style="cursor: pointer;line-height: inherit;"
        v-b-tooltip.hover title="Menu de selection des Ehpad"
      ></router-link>

      <router-link
        v-if="currentEhpad && currentEhpad.id"
        :to="{path: `/ehpads/${currentEhpad.id}/main`}"
        tag="a"
        class="nav-item fas fa-home fa-icon"
        style="cursor: pointer;line-height: inherit;"
        v-b-tooltip.hover title="Accueil"
      ></router-link>

      <router-link
        v-if="currentEhpad && currentEhpad.id"
        :to="{path: `/ehpads/${currentEhpad.id}/setup`}"
        tag="a"
        class="nav-item fas fa-cog fa-icon"
        style="cursor: pointer;line-height: inherit;"
        v-b-tooltip.hover title="Paramétrage"
      ></router-link>

      <router-link
        v-if="currentEhpad && currentEhpad.id"
        :to="{path: `/ehpads/${currentEhpad.id}/mobilization_tests`}"
        tag="a"
        class="nav-item custom-icon"
        style="cursor: pointer;line-height: inherit;"
        v-b-tooltip.hover title="Tests"
      ><img src="/assets/images/tests-on_borderless.png"></router-link>

      <router-link
        v-if="currentEhpad && currentEhpad.id"
        tag="a"
        :to="{path: `/ehpads/${currentEhpad.id}/residents`}"
        class="nav-item custom-icon"
        style="cursor: pointer"
        v-b-tooltip.hover title="Résidents"
      ><img src="/assets/images/residents-on_borderless.png"></router-link>

      <router-link
        v-if="currentEhpad && currentEhpad.id"
        :to="{path: `/ehpads/${currentEhpad.id}/resources`}"
        tag="a"
        class="nav-item custom-icon"
        style="cursor: pointer;line-height: inherit;"
        v-b-tooltip.hover title="Ressources"
      ><img src="/assets/images/ressources-on_borderless.png"></router-link>

      <router-link
        v-if="currentEhpad && currentEhpad.id"
        :to="{path: `/ehpads/${currentEhpad.id}/tasks`}"
        tag="a"
        class="nav-item custom-icon"
        style="cursor: pointer;line-height: inherit;"
        v-b-tooltip.hover title="Activités"
      ><img src="/assets/images/tasks-on_borderless.png"></router-link>

      <router-link
        v-if="currentEhpad && currentEhpad.id"
        tag="a"
        :to="{path: `/ehpads/${currentEhpad.id}/calendar`}"
        class="nav-item custom-icon"
        style="cursor: pointer"
        v-b-tooltip.hover title="Programmes"
      ><img src="/assets/images/programmes-on_borderless.png"></router-link>

      <router-link
        v-if="currentEhpad && currentEhpad.id"
        tag="a"
        :to="{path: `/ehpads/${currentEhpad.id}/dashboard/`}"
        class="nav-item custom-icon"
        style="cursor: pointer"
        v-b-tooltip.hover title="Tableau de bord"
      ><img src="/assets/images/tableaudebord-on_borderless.png"></router-link>

      <router-link
        v-if="currentEhpad && currentEhpad.id"
        tag="a"
        :to="{path: `/ehpads/${currentEhpad.id}/infos/`}"
        class="nav-item custom-icon"
        style="cursor: pointer"
        v-b-tooltip.hover title="Informations"
      ><img src="/assets/images/infos-on_borderless.png"></router-link>

      <router-link
        v-if="currentUser.role === 'administrateur'"
        :to="{path: `/users`}"
        tag="a"
        class="nav-item fas fa-users fa-icon"
        style="cursor: pointer;line-height: inherit;"
        v-b-tooltip.hover title="Gestion des utilisateurs du logiciel"
      ></router-link>

      <router-link
        v-if="currentUser"
        :to="{path: `/profile`}"
        tag="a"
        class="nav-item fas fa-user fa-icon"
        style="cursor: pointer;line-height: inherit;"
        v-b-tooltip.hover title="Gérer mon profil"
      ></router-link>

      <a
        v-if="currentUser"
        @click="signOut"
        class="nav-item custom-icon"
        style="cursor: pointer; hover:color: white !important"
        v-b-tooltip.hover title="Déconnexion"
      ><img src="/assets/images/connex-on_borderless.png"></a>

    </div>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'Navbar',
  methods: {
    signOut() {
      this.$store.dispatch('signoutUser');
      this.$router.push('/signin');
    },
  },
  data() {
    return {
      style: {
        // Couleurs par défaut
        headerLight: '#980740BB',
        headerDark: '#980740',
      },
    };
  },
  created() {
    moment.locale('fr-FR');
  },
  watch: {
    // Si changement d'ehpad, changement de style
    currentEhpad(newEhpad) {
      if (!newEhpad) return;
      this.style.headerLight = `${newEhpad.color}BB`;
      this.style.headerDark = newEhpad.color;
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
      currentUser: 'user',
    }),
    // currentYear() {
    //   return (moment().year());
    // },
    // currentWeek() {
    //   return (moment().isoWeek());
    // },
    // currentDate() {
    //   return moment().format('DD-MM-YYYY');
    // },
    styleObject() {
      return {
        '--header': this.style.headerLight,
        '--header-menu': this.style.headerLight,
        '--header-top-part': this.style.headerDark,
        '--header-menu--hover': this.style.headerDark,
        '--header-menu--active': this.style.headerDark,
      };
    },
    navClass() {
      if (this.$route.name === 'EhpadCalendar' || this.$route.name === 'ResidentCalendar') { return 'header p-0 d-print-none'; }
      return 'header p-0 d-print-none fixed-top';
    },
  },
};
</script>

<style lang="scss" scoped>

.custom-icon {
  padding: 3px;
}

.custom-icon > img {
  width: 100%;
  height: 100%;
}

.fa-icon {
  font-size: 2.8rem !important;
}

.header {
  background-color: var(--header);
  background-image: linear-gradient(to right, #FFFFFF, #FFFFFF);
}

.header__top-part {
  background-color: var(--header-top-part);
  height: 100px;
}

.header__menu a:hover {
  background-color: var(--header-menu--hover);
  color: white;
  text-decoration: none;
}

.router-link-exact-active {
  background-color: var(--header-menu--hover);
  color: white;
  text-decoration: none;
}

.header__menu img:hover {
  background-color: var(--header-menu--hover);
  color: white;
  text-decoration: none;
}

.active {
  background-color: var(--header-menu--active);
}

.header__navbar-fix {
  margin-top: 10rem;
}

.header__top-part {
  padding-left: 2rem;
  padding-right: 2rem;
  width: 100%;
}

.header__top-part .title {
  color: white;
}

.header__menu {
  overflow: hidden;
  background-color: var(--header-menu);
}

.header__menu > a, img {
  float: left;
  display: block;
  color: white;
  text-align: center;
  width: 65px;
  height: 65px;
  text-decoration: none;
  font-size: 1.0625rem;
}

.header__menu .icon {
  display: none;
}

.app-logo {
  width: 200px;
  height: auto;
  filter: brightness(0) invert(1);
}

</style>
