<template>
  <div>
    <div class="d-flex justify-content-center align-items-center mb-3">
      <span class="text-center">
        <h5 class="font-weight-bold m-0" style="display: inline-block;margin: 0;">
          Moyenne des tests (par date)
        </h5>
        <i class="fas fa-exclamation-triangle"
            style="color: #5bc0de;"
            v-b-tooltip.hover.v-primary
            title="Pour pouvoir faire une moyenne il faut au moins 5 résultats de résidents par test"
          />
      </span>
    </div>
    <div v-if="Object.keys(averageTestsResultsByDate).length !== 0">
      <div class="text-center">
        <a :href="`/ehpads/${this.currentEhpad.id}/tests_history/download_by_test.xlsx`" target="_blank">
          <span>Exporter les données (Par test) <i class="far fa-file-excel" /></span>
        </a>
      </div>
      <div class="text-center">
        <a :href="`/ehpads/${this.currentEhpad.id}/tests_history/download_by_resident.xlsx`" target="_blank">
          <span>Exporter les données (Par résident) <i class="far fa-file-excel" /></span>
        </a>
      </div>
    </div>
    <div v-if="averageTestsResultsByDate !== undefined">
      <custom-date-picker :id="`datepicker-${$options.name}`" @dates-selected="onDatesSelected"></custom-date-picker>

      <div v-if="Object.keys(averageTestsResultsByDate).length !== 0" class="mt-3">
        <template v-for="(dataEhpadTest, testName, index) in averageTestsResultsByDate">
          <test-chart
            :test-name="testName"
            :data-ehpad-test="dataEhpadTest"
            :test-type="'date'"
            :key="index"
          />
        </template>
      </div>
      <div class="text-center mt-3" style="color: #469AE0;" v-if="Object.keys(averageTestsResultsByDate).length === 0">
        <h2>
          Pas de donnée : Choisissez un intervalle de date plus large
        </h2>

      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import CustomDatePicker from '../custom/CustomDatePicker.vue';
import { FETCH_AVERAGE_TESTS_RESULTS_BY_DATE } from '../../graphql/customs/queries';
import TestChart from "../../components/dashboard/TestChart.vue";

export default {
  name: 'TestResultsbyDateComponent',
  data() {
    return {
      startDate: null,
      endDate: null,
    };
  },
  components: {
    CustomDatePicker,
    TestChart,
  },
  created() {
    this.startDate = this.sixMonthsAgo;
    this.endDate = this.today;
  },
  methods: {
    onDatesSelected(dates) {
      this.startDate = dates.start;
      this.endDate = dates.end;
    },
  },
  apollo: {
    averageTestsResultsByDate: {
      query: FETCH_AVERAGE_TESTS_RESULTS_BY_DATE,
      variables() {
        return {
          ehpadId: this.currentEhpad.id,
          startDate: this.startDate,
          endDate: this.endDate,
        };
      },
      fetchPolicy: 'network-only',
      watchQuery: {
        // Fonction qui est appelée lorsque les variables changent
        // Cela redéclenche la requête avec les nouvelles variables
        variables(newVariables) {
          return {
            ehpadId: newVariables.currentEhpad.id,
            startDate: newVariables.startDate,
            endDate: newVariables.endDate,
          };
        },
      },
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
    }),
    today() {
      const now = new Date();
      const date = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      return moment(date).format('YYYY-MM-DD');
    },
    sixMonthsAgo() {
      const now = new Date();
      const date = new Date(now.getFullYear(), now.getMonth() - 6, now.getDate());
      return moment(date).format('YYYY-MM-DD');
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
