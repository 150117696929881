<template>
    <div v-if="resource !== null">
        <app-full-calendar
          elementType='Resource'
          :elementId='resource.id'
          ref="resourceCalendar"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FullCalendar from '../../components/calendars/FullCalendar.vue';

export default {
  components: {
    appFullCalendar: FullCalendar,
  },
  props: ['resourceId'],
  methods: {
    ...mapActions([
      'findResource',
    ]),
  },
  computed: {
    ...mapGetters({
      resource: 'resource',
    }),
  },
  watch: {
    resourceId: {
      handler() {
        this.findResource(this.resourceId);
      },
    },
  },
  created() {
    this.findResource(this.resourceId);
  },
};
</script>

<style lang="scss" scoped>
</style>
