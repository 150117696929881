import gql from 'graphql-tag';

export const CREATE_RESIDENT = gql`
  mutation CREATE_RESIDENT(
    $name: String!
    $firstName: String!
    $height: Int!
    $ehpadId: ID!
    $weight: Float!
    $roomId: Int
  ) {
    createResident(
      input: {
        name: $name
        firstName: $firstName
        height: $height
        ehpadId: $ehpadId
        weight: $weight
        roomId: $roomId
      }
    ) {
      resident {
        id
        name
        firstName
        height
        lastWeight {
          id
          date
          value
        }
        lastStatus {
          id
          description
          date
          statusCategory {
            name
          }
        }
        room {
          id
          number
        }
        sector {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_RESIDENT = gql`
  mutation UPDATE_RESIDENT(
    $id: ID!
    $name: String!
    $firstName: String!
    $height: Int!
    $roomId: ID
    $sectorId: ID
    $entryDate: String
  ) {
    updateResident(
      input: { 
        id: $id, 
        name: $name, 
        firstName: $firstName, 
        height: $height, 
        roomId: $roomId, 
        sectorId: $sectorId,
        entryDate: $entryDate,
      }
    ) {
      resident {
        id
        name
        firstName
        height
        lastWeight {
          id
          date
          value
        }
        lastStatus {
          id
          description
          date
          statusCategory {
            name
          }
        }
        residentTests {
          id
          date
          result
          comment
          mobilizationTest {
            id
            name
            mobilizationTestCategories {
              id
              alertTreshold
            }
          }
        }
        room {
          id
          number
        }
        sector {
          id
          name
        }
      }
    }
  }
`;
