<template>
<div class="mb-4">
  <b-row class="mb-2">
    <b-col cols="8">
      <span>
        <b>Liste des participants</b>
        <span :style="selectedResidentStyle">
          (Nb sélectionnés : {{ editableEvent.residents.length }})
        </span>
      </span>
    </b-col>
    <b-col cols="4">
      <div v-if="!disableEdit">
        <b-button style="float: right;" variant="primary" size="sm" @click="add">
          Ajouter des participants
        </b-button>
      </div>
    </b-col>
  </b-row>

  <b-row>
    <b-col cols="12">
      <draggable
        tag="ul"
        v-model="editableEvent.residents"
        class="list-group"
        handle=".handle"
        v-bind="dragOptions"
        @start="isDragging = true"
        @end="isDragging = false"
        @change="computeSequenceTimeForResidents"
      >
        <transition-group type="transition" name="flip-list">
          <div
            v-for="(resident, residentIndex) in editableEvent.residents"
            :key="resident.id"
          >
            <app-event-modal-residents-list-element
              :resident="resident"
              :residentIndex="residentIndex"
              :disableEdit="disableEdit"
              :editableEvent="editableEvent"
              :initialEventDefaultTasks="initialEventDefaultTasks"
              v-on:update-resident-sequence="computeSequenceTimeForResidents"
              @open-event-mobista-modal="(resident) => $emit('open-event-mobista-modal', resident)"
            />
          </div>
        </transition-group>
        </draggable>
    </b-col>
  </b-row>

    <app-event-modal-residents-table
      v-if="!disableEdit"
      id="event-modal-residents-table"
      :availableResidents="availableResidents"
      :editableEvent="editableEvent"
      hide-footer
    />
</div>

</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import draggable from 'vuedraggable';
import { mapGetters } from 'vuex';
import EventModalResidentsListElement from './EventModalResidentsListElement.vue';
import EventModalResidentsTable from './EventModalResidentsTable.vue';

export default {
  props: ['editableEvent', 'disableEdit'],
  components: {
    appEventModalResidentsTable: EventModalResidentsTable,
    appEventModalResidentsListElement: EventModalResidentsListElement,
    draggable,
  },
  data() {
    return {
      availableResidents: [],
      dragging: false,
      initialEventDefaultTasks: 0,
    };
  },
  created() {
    this.initialEventDefaultTasks = this.editableEvent.eventDefaultTasks.length;
    // Update availableResidents for the table, filtering with already selected residents
    const selectedResidentsIds = _.map(this.editableEvent.residents, 'id');
    this.availableResidents = _.filter(this.currentEhpad.residentsWithRoom, (obj) => !selectedResidentsIds.includes(obj.id));
  },
  watch: {
    'editableEvent.residents': {
      handler() {
        this.editableEvent.residents.forEach((element) => {
          // Add reactive properties to an object :
          // Object.assign({}, element, { sequenceDefaultDuration: null });
          // this.$set(element, 'sequenceDefaultDuration', null);
          if ((element.sequenceEvent === null) || (element.sequenceEvent === undefined)) {
            this.$set(element, 'sequenceEvent', {});
            this.$set(element.sequenceEvent, 'startHour', null);
            this.$set(element.sequenceEvent, 'endHour', null);
            this.$set(element.sequenceEvent, 'duration', null);
          }
          // Update availableResidents for the table, filtering with already selected residents
          const selectedResidentsIds = _.map(this.editableEvent.residents, 'id');
          this.availableResidents = _.filter(this.currentEhpad.residentsWithRoom, (obj) => !selectedResidentsIds.includes(obj.id));
        });
      },
    },
    editableEventResidentsClone: {
      handler(newVal, oldVal) {
        if (newVal.length !== oldVal.length) {
          this.computeSequenceTimeForResidents();
        }
      },
    },
    'editableEvent.sequenceDefaultDuration': {
      handler() {
        this.resetIntervalTimeForResidents();
      },
    },
    'editableEvent.start': {
      handler() {
        this.computeSequenceTimeForResidents();
      },
    },
    'editableEvent.end': {
      handler() {
        this.computeSequenceTimeForResidents();
      },
    },
    'editableEvent.sequence': {
      handler() {
        this.editableEvent.residents.forEach((element) => {
          if ((element.sequenceEvent === null) || (element.sequenceEvent === undefined)) {
            this.$set(element, 'sequenceEvent', {});
            this.$set(element.sequenceEvent, 'startHour', null);
            this.$set(element.sequenceEvent, 'endHour', null);
            this.$set(element.sequenceEvent, 'duration', null);
          }
        });
        this.computeSequenceTimeForResidents();
      },
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
    }),
    editableEventResidentsClone() {
      return JSON.parse(JSON.stringify(this.editableEvent.residents));
    },
    dragOptions() {
      return {
        animation: 0,
        group: 'description',
        disabled: this.disableEdit ? true : false,
        ghostClass: 'ghost',
      };
    },
    selectedResidentStyle() {
      return this.editableEvent.capacity < this.editableEvent.residents.length
        ? 'color: red;font-weight: bold;'
        : 'color: black;';
    },
  },
  methods: {
    add() {
      this.$bvModal.show('event-modal-residents-table');
    },
    resetIntervalTimeForResidents() {
      this.editableEvent.residents.forEach((element) => {
        element.sequenceEvent.duration = this.editableEvent.sequenceDefaultDuration;
      }, this);
      this.setAndCalculateTimesResidents();
    },
    setAndCalculateTimesResidents() {
      // Set and update residents start hour, end hour and duration for time
      if (this.editableEvent.residents.length !== 0) {
        // set default duration for all residents
        this.editableEvent.residents.forEach((element, index, array) => {
          if ((element.sequenceEvent.duration === '') || (element.sequenceEvent.duration === null)) {
            element.sequenceEvent.duration = this.editableEvent.sequenceDefaultDuration;
          }
        }, this);
        // Set first element base on editableEvent start
        const startHour = moment(this.editableEvent.start).format('HH:mm:ss');
        this.editableEvent.residents[0].sequenceEvent.startHour = startHour;
        const durationInSeconds = moment.duration(this.editableEvent.residents[0].sequenceEvent.duration).asSeconds();
        this.editableEvent.residents[0].sequenceEvent.endHour = moment(this.editableEvent.residents[0].sequenceEvent.startHour, 'HH:mm:ss').add(durationInSeconds, 'seconds').format('HH:mm:ss');

        this.editableEvent.residents.forEach((element, index, array) => {
          // skip first
          if (index === 0) return;
          let prevResident = array[index - 1];
          let prevEndHour = prevResident.sequenceEvent.endHour;
          element.sequenceEvent.startHour = prevEndHour;
          let currentResidentIntervalInSeconds = moment.duration(element.sequenceEvent.duration).asSeconds();
          element.sequenceEvent.endHour = moment(prevEndHour, 'HH:mm:ss').add(currentResidentIntervalInSeconds, 'seconds').format('HH:mm:ss');
        }, this);
      }
    },
    setDefaultTimesResidents() {
      // Set default residents start hour, end hour and duration for time
      if (this.editableEvent.residents.length !== 0) {
        // set default for all residents
        this.editableEvent.residents.forEach((element, index, array) => {
          // skip set default if element already have an id (and values) no immediate, not needed
          // if ((element.sequenceEvent.id !== undefined) && (element.sequenceEvent.id !== null)) { return; }
          element.sequenceEvent.startHour = moment(this.editableEvent.start).format('HH:mm:ss');
          element.sequenceEvent.endHour = moment(this.editableEvent.end).format('HH:mm:ss');
          const end = moment(element.sequenceEvent.endHour, 'HH:mm:ss');
          const start = moment(element.sequenceEvent.startHour, 'HH:mm:ss');
          const durationDuration = moment.duration(end.diff(start));
          element.sequenceEvent.duration = moment.utc(durationDuration.as('milliseconds')).format('HH:mm:ss');
        }, this);
      }
    },
    computeSequenceTimeForResidents() {
      if (this.editableEvent.sequence) {
        if ((this.editableEvent.sequenceDefaultDuration === '') || (this.editableEvent.sequenceDefaultDuration === null)) {
          this.setDefaultTimesResidents();
        } else {
          this.setAndCalculateTimesResidents();
        }
      }
    },
  },
};
</script>
<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}
</style>
