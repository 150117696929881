import gql from 'graphql-tag';

export const CREATE_TASK = gql`
  mutation CREATE_TASK(
    $title: String!
    $description: String
    $ehpadId: ID!
    $taskType: String!
  ) {
    createTask(
      input: {
        title: $title
        description: $description
        ehpadId: $ehpadId
        taskType: $taskType
      }
    ) {
      task {
        id
        title
        description
        taskType
        humanTaskType
      }
    }
  }
`;

export const UPDATE_TASK = gql`
  mutation UPDATE_TASK(
    $id: ID!
    $title: String!
    $taskType: String!
    $description: String
  ) {
    updateTask(
      input: {
        id: $id
        title: $title
        description: $description
        taskType: $taskType
      }
    ) {
      task {
        id
        title
        description
        taskType
        humanTaskType
      }
    }
  }
`;


export const DESTROY_TASK = gql`
  mutation DESTROY_TASK($id: ID!) {
    destroyTask(
      input: {
        id: $id
      }
    ) {
      task {
        id
      }
    }
  }
`;
