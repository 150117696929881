<template>
  <div>
    <b-row align-h="center">
        <b-col sm="12" md="6" lg="4" xl="2" class="text-center"
            v-for="mobilizationDiagnostic in mobilizationDiagnostics"
            :key="mobilizationDiagnostic.id"
        >
          <app-resident-mob-log-card
            :mobilizationDiagnostic="mobilizationDiagnostic"
          />

        </b-col>
    </b-row>
  </div>
</template>

<script>
import ResidentMobLogCard from './ResidentMobLogCard.vue';

export default {
  props: ['mobilizationDiagnostics'],
  components: {
    appResidentMobLogCard: ResidentMobLogCard,
  },
};

</script>

<style lang="scss" scoped>

</style>
