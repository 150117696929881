<template>
  <b-modal
    id='terms-of-service-modal'
    title="Conditions générales d'utilisation"
    v-bind="$attrs" class="col-12 text-center"
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    hide-footer
  >
    <b-container fluid>
      <p>Pour utiliser le logiciel vous devez accepter les CGU.</p>
      <a href="/assets/documentation/cgu.pdf" target="_blank">Conditions Générales d'Utilisation</a>
      <b-form  class="mt-3">
        <b-form-checkbox
          id="checkbox-1"
          v-model='termsOfService'
          name="checkbox-1"
          :value='checkedValue'
          :unchecked-value='uncheckedValue'
        >
          J'accepte les CGU
        </b-form-checkbox>

        <b-button
          :disabled='isDisabled'
          class="mt-3 d-flex mx-auto"
          variant="primary"
          size="sm"
          @click.prevent="acceptTermsOfService"
          >Valider</b-button
        >
        <b-button
          class="mt-3 d-flex mx-auto"
          variant="primary"
          size="sm"
          @click.prevent="signOut"
          >Annuler</b-button
        >
      </b-form>
      <hr />
    </b-container>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      termsOfService: null,
      checkedValue: true,
      uncheckedValue: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'user',
    }),
    isDisabled() {
      return !this.termsOfService;
    },
  },
  watch: {
    currentUser: {
      handler() {
        if (this.currentUser) {
          this.termsOfService = this.currentUser.termsOfService;
        }
      },
      immediate: true,
    },
  },
  methods: {
    async acceptTermsOfService() {
      await this.$store.dispatch('updateCurrentUserTermsOfService', {
        termsOfService: this.termsOfService,
      }).then((result) => {
        this.$bvModal.hide('terms-of-service-modal');
      }).catch((error) => {
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
    },
    signOut() {
      this.$store.dispatch('signoutUser');
      this.$bvModal.hide('terms-of-service-modal');
      this.$router.push('/signin');
    },
  },
};
</script>

<style lang="scss" scoped></style>
