<template>
  <b-container fluid>
    <div class="mx-5 d-print-none">
      <b-row class="justify-content-around">
        <b-col cols="12" lg="8" class="mt-2">
          <h6 class="text-center mb-2">Passer un Test</h6>
          <b-form>
              <b-row class="my-2">
                <b-col cols="3">
                  <label for="mobilization-test" class="text-nowrap">Choisir le Test : </label>
                </b-col>
                <b-col cols="9">

                  <!-- <b-form-select
                    id="mobilization-test"
                    v-model="newResidentTest.mobilizationTest"
                    :options="availableMobilizationTestsOptions"
                    :class="{'is-invalid': $v.newResidentTest.mobilizationTest.$error}"
                  >
                    <template #first>
                      <b-form-select-option :value="null" disabled>Selectionner un Test</b-form-select-option>
                    </template>
                  </b-form-select> -->

                  <multiselect
                    v-model="newResidentTest.mobilizationTest"
                    :options="availableMobilizationTestsOptions"
                    placeholder="Selectionner un Test"
                    label="name"
                    track-by="name"
                    selectLabel=""
                    deselectLabel=""
                    group-values="tests"
                    group-label="groups"
                    :group-select="false"
                    :class="{ 'is-invalid': $v.newResidentTest.mobilizationTest.$error }"
                  >

                  </multiselect>

                  <b-form-invalid-feedback>Test Requis</b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row class="my-3" v-if="newResidentTest.mobilizationTest && newResidentTest.mobilizationTest.length !== 0">
                <b-col cols="3">
                  <span class="text-nowrap">Description du Test : </span>
                </b-col>
                <b-col cols="9">
                  <span> {{ newResidentTest.mobilizationTest.description }}</span>
                </b-col>
              </b-row>

              <b-row class="my-3" v-if="showMobilizationTest">
                <b-col cols="3">
                  <span class="text-nowrap">Lien du Test : </span>
                </b-col>
                <b-col cols="9">
                  <a :href="newResidentTest.mobilizationTest.url" target="_blank"> Cliquez ICI pour passer le test</a>
                </b-col>
              </b-row>


              <b-row class="my-2">
                <b-col cols="3">
                  <label for="date" class="text-nowrap">Date du Test : </label>
                </b-col>
                <b-col cols="9">
                  <b-form-datepicker
                    id="date-datepicker-status"
                    :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                    label-help
                    label-no-date-selected="Aucune date sélectionnée"
                    size="sm"
                    :max="today"
                    v-model="newResidentTest.date"
                    locale="fr"
                  />
                </b-col>
              </b-row>

              <b-row class="my-2">
                <b-col cols="3">
                  <label for="resident-test-result" class="text-nowrap">Résultat (numerique): </label>
                </b-col>
                <b-col cols="9">
                    <b-form-input
                      id="resident-test-result"
                      size="sm"
                      type="number"
                      :number="true"
                      v-model="newResidentTest.result"
                      :class="{ 'is-invalid': $v.newResidentTest.result.$error }"
                    />
                    <b-form-invalid-feedback>Résultat Requis</b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row class="my-2">
                <b-col cols="3">
                  <label for="resident-test-comment" class="text-nowrap">Commentaire : </label>
                </b-col>
                <b-col cols="9">
                    <b-form-textarea
                      id="resident-test-comment"
                      size="sm"
                      type="text"
                      rows="4"
                      v-model="newResidentTest.comment"
                    />
                </b-col>
              </b-row>

            <div v-if="error" style="color: red;" class="text-center mt-2">
              <p v-for="(error, i) of error.graphQLErrors" :key="i">
                {{ error.message }}
              </p>
            </div>

            <b-button class="d-flex mx-auto" variant="primary" size="sm" @click.prevent="createResidentTest"
              >Valider</b-button
            >
          </b-form>
        </b-col>
        <b-col cols="12" lg="4" class="mt-2" v-if="this.resident.expiredMobilizationTests.length !== 0">
          <h6 class="text-center mb-2">Liste des Tests à passer ou ayant expiré</h6>
          <li
            v-for="(expiredMobilizationTest) in this.resident.expiredMobilizationTests"
            :key="expiredMobilizationTest.id"
            class="text-center"
          >
            <b style="color: red;">{{expiredMobilizationTest.name}}</b>
          </li>
        </b-col>
      </b-row>

    </div>
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';
import Multiselect from 'vue-multiselect';
import _ from 'lodash';

export default {
  mixins: [validationMixin],
  props: ['resident'],
  components: {
    Multiselect,
  },
  data() {
    return {
      error: null,
      newResidentTest: {
        date: null,
        result: null,
        mobilizationTest: null,
      },
    };
  },
  validations: {
    newResidentTest: {
      mobilizationTest: {
        required,
      },
      date: {
        required,
      },
      result: {
        required,
      },
    },
  },
  created() {
    this.newResidentTest.date = this.today;
    this.setMobilizationTests(this.currentEhpad.id);
  },
  methods: {
    ...mapActions(['setMobilizationTests']),
    async createResidentTest() {
      this.$v.newResidentTest.$touch();
      if (this.$v.$anyError) return;
      await this.$store.dispatch('createResidentTest', {
        residentId: this.resident.id,
        mobilizationTestId: this.newResidentTest.mobilizationTest.id,
        date: this.newResidentTest.date,
        result: this.newResidentTest.result,
        comment: this.newResidentTest.comment,
      }).then((result) => {
        this.resetData();
        this.$v.$reset();
      }).catch((error) => {
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
    },
    resetData() {
      // For resetting data object
      Object.assign(this.$data, this.$options.data.apply(this));
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
      mobilizationTests: 'mobilizationTests',
    }),
    showMobilizationTest() {
      const { mobilizationTest } = this.newResidentTest;
      return (
        mobilizationTest
        && mobilizationTest.length !== 0
        && mobilizationTest.url
        && mobilizationTest.url !== ""
      );
    },
    today() {
      const now = new Date();
      const date = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      return moment(date).format('YYYY-MM-DD');
    },
    availableMobilizationTestsOptions() {
      const categoryMobTests = _.find(this.currentEhpad.mobilizationCategories, { name: this.resident.lastMobilizationDiagnostic.mobilizationCategory.name }).mobilizationTests;
      const categoryMobTestsIds = _.map(categoryMobTests, 'id');
      const otherMobTests = _.filter(this.mobilizationTests, obj => !categoryMobTestsIds.includes(obj.id));
      return [
        {
          groups: 'Tests de la catégorie',
          tests:
            categoryMobTests,
        },
        {
          groups: 'Autres tests',
          tests:
            otherMobTests,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped></style>
