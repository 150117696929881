<template>
  <div>
    <b-row align-h="center">
      <b-col cols="12" md="5">
        <app-room-table />
      </b-col>

      <b-col cols="12" md="5" v-if="currentEhpad.allSettings && currentEhpad.allSettings.configuration.sectors.activate_sectors">
        <app-sector-table />
      </b-col>
    </b-row>

    <hr />
    <b-row align-h="center">
      <b-col cols="12" md="5">
        <div class="d-flex justify-content-center align-items-center mb-3">
          <span class="text-center">
            <h5 class="m-0" style="display: inline-block;margin: 0;">
              Parametrage de l'Ehpad
            </h5>
            <i
              class="fas fa-exclamation-triangle"
              style="color: red;"
              v-b-tooltip.hover.v-danger
              title="Modifier ces paramétres renverra à l'accueil du logiciel"
            />
          </span>
        </div>
        <b-form>
          <div>
            <label for="inline-form-input-name" class="pr-4">Nom </label>
            <b-input
              id="inline-form-input-name"
              size="sm"
              type="text"
              v-model="editableEhpad.name"
              :class="{ 'is-invalid': $v.editableEhpad.name.$error }"
            />
            <b-form-invalid-feedback>Valeur requise (50 caractères max)</b-form-invalid-feedback>
          </div>

          <div class="pt-2">
            <label for="inline-form-input-shortname" class="pr-4">Nom court </label>
            <b-input
              id="inline-form-input-shortname"
              size="sm"
              type="text"
              v-model="editableEhpad.shortname"
              :class="{ 'is-invalid': $v.editableEhpad.shortname.$error }"
            />
            <b-form-invalid-feedback>Valeur requise (3 caractères)</b-form-invalid-feedback>
          </div>

          <div class="pt-2">
            <label for="inline-form-input-email" class="pr-4">Email</label>
            <b-input
              id="inline-form-input-email"
              size="sm"
              type="text"
              v-model="editableEhpad.email"
              :class="{ 'is-invalid': $v.editableEhpad.email.$error }"
            />
            <b-form-invalid-feedback>Entrez un email valide</b-form-invalid-feedback>
          </div>

          <div class="pt-2">
            <label class="pr-4">Couleur</label>
            <app-verte
              v-model="editableEhpad.color"
              :showHistory="null"
              model="hex"
              display="widget"
            ></app-verte>
          </div>
          <b-button
            class="mt-3 d-flex mx-auto"
            variant="primary"
            size="sm"
            @click.prevent="updateEhpad"
            >Valider</b-button
          >
        </b-form>
      </b-col>
    </b-row>
    <hr />
    <div class="d-flex justify-content-center align-items-center mb-3">
      <span class="text-center">
        <h5 class="m-0" style="display: inline-block;margin: 0;">
          Parametrage des catégories de mobilité
        </h5>
      </span>
    </div>
  <b-row align-h="center">
    <template>
      <b-col
        v-for="mobilizationCategory, index in currentEhpad.mobilizationCategories"
        :key="index"
        cols="5"
      >
        <app-mobilization-category-form
          :mobilizationCategory="mobilizationCategory"
        />
      </b-col>
    </template>
  </b-row>

    <hr />

    <div class="d-flex justify-content-center align-items-center mb-3">
      <span class="text-center">
        <h5 class="m-0" style="display: inline-block;margin: 0;">
          Parametrage
        </h5>
      </span>
    </div>

    <b-row align-h="center">

      <app-root-settings
        v-for="(rootValues, rootState) in currentEhpad.allSettings"
        :rootValues="rootValues"
        :rootState="rootState"
        :key="rootState"
      />

    </b-row>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import verte from 'verte';
import 'verte/dist/verte.css';
import { required, maxLength, alphaNum, email, minLength } from 'vuelidate/lib/validators';
import RoomTable from '../../components/rooms/Table.vue';
import SectorTable from '../../components/sectors/Table.vue';
import RootSettings from '../../components/ehpad/RootSettings.vue';
import MobilizationCategoryForm from '../../components/ehpad/MobilizationCategoryForm.vue';

export default {
  name: 'EhpadSetup',
  components: {
    appVerte: verte,
    appRootSettings: RootSettings,
    appRoomTable: RoomTable,
    appSectorTable: SectorTable,
    appMobilizationCategoryForm: MobilizationCategoryForm,
  },
  mixins: [validationMixin],
  watch: {
    currentEhpad: {
      handler() {
        if (this.currentEhpad !== null) {
          this.duplicateEhpad(this.currentEhpad);
        }
      },
      immediate: true,
    },
  },
  validations: {
    editableEhpad: {
      name: {
        required,
        maxLength: maxLength(50),
      },
      shortname: {
        required,
        maxLength: maxLength(3),
        minLength: minLength(3),
        alphaNum,
      },
      color: {
        required,
      },
      email: {
        email,
      },
    },
  },
  data() {
    return {
      editableEhpad: null,
    };
  },
  methods: {
    duplicateEhpad(ehpad) {
      this.editableEhpad = { ...ehpad };
    },
    async updateEhpad() {
      this.$v.editableEhpad.$touch();
      if (this.$v.$anyError) return;
      await this.$store.dispatch('updateEhpad', {
        id: this.editableEhpad.id,
        name: this.editableEhpad.name,
        shortname: this.editableEhpad.shortname,
        color: this.editableEhpad.color,
        email: this.editableEhpad.email,
      }).then((result) => {
        this.$v.$reset();
        this.$router.push({ path: '/' });
      }).catch((error) => {
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
    }),
  },
};
</script>

<style lang="scss" scoped></style>
