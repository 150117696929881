import gql from 'graphql-tag';

export const CREATE_EVENT_RESIDENT_TASKS = gql`
  mutation CREATE_EVENT_RESIDENT_TASKS(
    $eventId: ID!
    $residentId: ID!
    $mobista: Float!
    $eventResidentTaskOrder: Int!
    $eventDate: String!
    $comment: String
    $task: TaskAttributes!
  ) {
    createEventResidentTask(
      input: {
        eventId: $eventId
        residentId: $residentId
        mobista: $mobista
        eventResidentTaskOrder: $eventResidentTaskOrder
        eventDate: $eventDate
        comment: $comment
        task: $task
      }
    ) {
      eventResidentTask {
        id
        task {
          id
          title
        }
        taskId
        residentId
        eventId
        mobista
        eventDate
        eventResidentTaskOrder
        comment
      }
    }
  }
`;

export const UPDATE_EVENT_RESIDENT_TASKS = gql`
  mutation UPDATE_EVENT_RESIDENT_TASKS(
    $id: ID!
    $mobista: Float!
    $comment: String
  ) {
    updateEventResidentTask(
      input: {
        id: $id
        mobista: $mobista
        comment: $comment
      }
    ) {
      eventResidentTask {
        id
        task {
          id
          title
        }
        taskId
        residentId
        eventId
        mobista
        eventDate
        eventResidentTaskOrder
        comment
      }
    }
  }
`;
