import { ApolloClient, ApolloLink, InMemoryCache, HttpLink } from 'apollo-boost';


const apiLink = () => {
  if (process.env.NODE_ENV === 'production') return process.env.GRAPHQL_API_URL;
  return 'http://localhost:3000/graphql';
};

const httpLink = new HttpLink({ uri: apiLink });

const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the authorization token from local storage.
  const token = localStorage.getItem('auth_token');

  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

const apiClient = new ApolloClient({
  link: authLink.concat(httpLink), // Chain it with the HttpLink
  cache: new InMemoryCache(),
});

export default apiClient;
