<template>
  <b-container fluid>
    <app-col-content v-if="!$apollo.loading && ehpad !== null && ehpad.id === ehpadId">
      <router-view :ehpadId="ehpadId" />
    </app-col-content>

    <!-- Pour les admins des ehpads qui chercheraient à accéder à un ehpad via une modif de l'adresse -->
    <b-alert show variant="danger" v-else-if="ehpad === null">
      Cet Ehpad n'existe pas
    </b-alert>

    <!-- Si pas admin (c'est à dire avec un user.ehpad) et essai d'accéder à un autre ehpad (qui existe ou pas) -->
    <b-alert show variant="danger" v-else-if="user.ehpad && user.ehpad.id !== ehpadId">
      Vous ne pouvez pas accéder aux données de cet Ehpad
    </b-alert>

  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ColContent from '../../components/layout/ColContent.vue';

export default {
  name: 'EhpadShow',
  props: ['ehpadId'],
  components: {
    appColContent: ColContent,
  },
  methods: {
    ...mapActions([
      'findEhpad',
      'setRooms',
      'setSectors',
      'setGlobalSettings',
      'setResources',
    ]),
  },
  computed: {
    ...mapGetters({
      user: 'user',
      ehpad: 'ehpad',
    }),
  },
  created() {
    // Important en cas de refresh pour les users sans Ehpads
    this.findEhpad(this.ehpadId);
    this.setGlobalSettings();
    // Le resolver renvoi une erreur si le user essai de chercher un ehpad autre que le sien et ne modifiera pas l'ehpad du store
  },
  watch: {
    ehpad: {
      handler() {
        if (this.ehpad !== null) {
          this.setRooms(this.ehpad.id);
          this.setSectors(this.ehpad.id);
          this.setResources(this.ehpad.id);
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
