<template>
  <b-container fluid>
    <b-form>
      <b-row>
        <b-col cols="4">
          <label for="inline-form-input-name" class="pr-4 text-nowrap">Nom de la Ressource : </label>
        </b-col>
        <b-col cols="8">
            <b-form-input
              id="inline-form-input-name"
              size="sm"
              type="text"
              v-model="newResource.name"
              :class="{ 'is-invalid': $v.newResource.name.$anyError }"
            />
            <b-form-invalid-feedback>Valeur requise (100 caractères max)</b-form-invalid-feedback>
        </b-col>
      </b-row>

      <div v-if="newResource.resourceCategory && newResource.resourceCategory.title === 'Intervenant'">
        <b-row class="pt-4">
          <b-col cols="5">
            <label for="inline-form-input-first-name" class="pr-4 text-nowrap">Prénom de la Ressource (optionnel) :</label>
          </b-col>
          <b-col cols="7">
              <b-form-input
                id="inline-form-input-first-name"
                size="sm"
                type="text"
                v-model="newResource.firstName"
              />
          </b-col>
        </b-row>

        <b-row class="pt-4">
          <b-col cols="5">
            <label for="inline-form-input-job" class="pr-4 text-nowrap">Qualification de la Ressource (optionnel) :</label>
          </b-col>
          <b-col cols="7">
              <b-form-input
                id="inline-form-input-job"
                size="sm"
                type="text"
                v-model="newResource.job"
              />
          </b-col>
        </b-row>
      </div>

      <b-row class="pt-4">
        <b-col cols="4">
          <label for="inline-form-input-capacity" class="pr-4 text-nowrap">Capacité de la Ressource : </label>
        </b-col>
        <b-col cols="8">
            <b-form-input
              id="inline-form-input-capacity"
              size="sm"
              type="number"
              :number="true"
              v-model="newResource.capacity"
              :class="{ 'is-invalid': $v.newResource.capacity.$anyError }"
            />
            <b-form-invalid-feedback>Valeur requise (Nombre)</b-form-invalid-feedback>
        </b-col>
      </b-row>

      <b-row class="pt-4">
        <b-col cols="4">
          <label for="inline-form-input-resourceCategory" class="pr-4 text-nowrap">Catégorie de la ressource : </label>
        </b-col>
        <b-col cols="8">
          <multiselect
            v-model="newResource.resourceCategory"
            :options="resourceCategoriesOptions"
            placeholder="Selectionner une catégorie"
            label="title"
            track-by="title"
            selectLabel=""
            deselectLabel=""
            :class="{ 'is-invalid': $v.newResource.resourceCategory.$error }"
          >
          <span slot="noResult">Pas de résultat correspondant</span>
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <i :class="props.option.icon"/>
                  <span class="option__title">{{ props.option.title }}</span>
                </div>
              </template>
              <template slot="singleLabel" slot-scope="props">
                <div class="option__desc">
                  <i :class="props.option.icon"/>
                  <span class="option__title">{{ props.option.title }}</span>
                </div>
              </template>
          </multiselect>
          <b-form-invalid-feedback>Une Ressource doit avoir une catégorie</b-form-invalid-feedback>
        </b-col>
      </b-row>

      <div v-if="error" style="color: red;" class="text-center mt-2">
        <p v-for="(error, i) of error.graphQLErrors" :key="i">
          {{ error.message }}
        </p>
      </div>

      <b-button class="d-flex mx-auto mt-4" variant="primary" size="sm" @click.prevent="createResource">
        Valider
      </b-button>
    </b-form>

  </b-container>
</template>

<script>

import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import Multiselect from 'vue-multiselect';
import { required, maxLength } from 'vuelidate/lib/validators';

export default {
  mixins: [validationMixin],
  data() {
    return {
      error: null,
      newResource: {
        name: '',
        firstName: '',
        job: '',
        capacity: '',
        resourceCategory: null,
      },
    };
  },
  components: {
    Multiselect,
  },
  validations: {
    newResource: {
      resourceCategory: {
        required,
      },
      name: {
        required,
        maxLength: maxLength(100),
      },
      capacity: {
        required,
      },
    },
  },
  methods: {
    async createResource() {
      this.$v.newResource.$touch();
      if (this.$v.$anyError) return;
      await this.$store.dispatch('createResource', {
        name: this.newResource.name,
        firstName: this.newResource.firstName,
        job: this.newResource.job,
        capacity: this.newResource.capacity,
        resourceCategoryId: this.newResource.resourceCategory.id,
        ehpadId: this.currentEhpad.id,
      }).then((result) => {
        this.$router.push({
          path: `/ehpads/${this.currentEhpad.id}/resources`,
        });
      }).catch((error) => {
        this.error = error;
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
    }),
    resourceCategoriesOptions() {
      return this.currentEhpad.resourcesCategories;
    },
  },
};
</script>

<style lang="scss">

.multiselect__tags {
  border: 1px solid #ced4da !important;
}

</style>
