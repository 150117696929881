import _ from 'lodash';

import apiClient from '../../utilities/api_client';
import { FIND_RESOURCE, FETCH_RESOURCES } from '../../graphql/resources/queries';
import {
  CREATE_RESOURCE,
  UPDATE_RESOURCE,
  SWAP_RESOURCE,
} from '../../graphql/resources/mutations';

const resourceState = {
  resource: null,
  resources: [],
};

const mutations = {
  SET_RESOURCES(state, resources) {
    state.resources = resources;
  },
  SELECT_RESOURCE(state, resource) {
    state.resource = resource;
  },
  ADD_RESOURCE(state, resource) {
    state.resources.push(resource);
  },
  UPDATE_RESOURCE(state, resource) {
    const index = _.findIndex(state.resources, { id: resource.id });
    state.resources.splice(index, 1, resource);
  },
};

const actions = {
  setResources: async ({ commit, state }, ehpadId) => {
    state.resources = [];
    const { data } = await apiClient.query({
      query: FETCH_RESOURCES,
      variables: { ehpadId },
      fetchPolicy: 'network-only',
    });
    commit('SET_RESOURCES', data.resources);
  },
  findResource: async ({ commit, state }, id) => {
    state.resource = null;
    const { data } = await apiClient.query({
      query: FIND_RESOURCE,
      variables: { id },
      fetchPolicy: 'network-only',
    });
    commit('SELECT_RESOURCE', data.findResource);
  },
  createResource: async ({ commit }, formData) => {
    const { data } = await apiClient.mutate({
      mutation: CREATE_RESOURCE,
      variables: { ...formData },
    });
    const { resource } = data.createResource;
    commit('ADD_RESOURCE', resource);
  },
  updateResource: async ({ commit }, formData) => {
    const { data } = await apiClient.mutate({
      mutation: UPDATE_RESOURCE,
      variables: { ...formData },
    });
    const { resource } = data.updateResource;
    commit('UPDATE_RESOURCE', resource);
  },
  swapResource: async ({ commit }, formData) => {
    const { data } = await apiClient.mutate({
      mutation: SWAP_RESOURCE,
      variables: { ...formData },
    });
  },
};

const getters = {
  resources: (state) => state.resources,
  resource: (state) => state.resource,
};

export default {
  state: resourceState,
  mutations,
  actions,
  getters,
};
