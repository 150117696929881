<template>
  <b-col>
    <span class="d-flex">
    <li class="mb-2"> {{ name(state) }} </li>
      <i
        v-if="message(state) !== undefined"
        class="fas fa-exclamation-triangle mt-1 ml-2"
        style="color: #5bc0de;"
        v-b-tooltip.hover.v-primary
        :title="message(state)"
      />
    </span>
    <dl class="row">
      <template v-for="(value, settingName) in values">
        <dd class="col-8">
          <span style="font-size: smaller;"
            >{{ name(settingName) }}
            <i
              class="fas fa-exclamation-triangle mx-auto mt-2"
              style="color: #5bc0de;"
              v-b-tooltip.hover.v-primary
              v-if="message(settingName) !== undefined"
              :title="message(settingName)"
            />
            :</span>
        </dd>
        <dt class="col-4">
          <b-form
            class="d-flex align-items-center"
            @submit.stop.prevent="
              updateEhpadSetting(settingName, editableSetting[settingName])
            "
          >
            <b-form-group
              v-if="typeof value === 'boolean' || value === 'true' || value === 'false'"
              size="sm"
              style="margin-right: 125px;"
              :class="{ 'is-invalid': $v.editableSetting[settingName].$error }"
            >
              <b-form-radio v-model="editableSetting[settingName]" name="Actif" value="true"
                >Activé</b-form-radio
              >
              <b-form-radio
                v-model="editableSetting[settingName]"
                name="Désactivé"
                value="false"
                >Désactivé</b-form-radio
              >
            </b-form-group>

            <b-form-input
              v-else
              size="sm"
              v-model="editableSetting[settingName]"
              :class="{ 'is-invalid': $v.editableSetting[settingName].$error }"
            />

            <b-input-group-append>
              <b-button
                variant="primary"
                size="sm"
                @click="updateEhpadSetting(settingName, editableSetting[settingName])"
                :disabled="updating"
              >
                <i :class="updatingClasses" />
              </b-button>
            </b-input-group-append>
          </b-form>
        </dt>
      </template>
    </dl>
  </b-col>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'ChildSettingsForm',
  props: ['state', 'values', 'rootState'],
  mixins: [validationMixin],
  created() {
    this.editableSetting = this.values;
  },
  validations() {
    if (this.state === 'sectors') {
      return {
        editableSetting: {
          activate_sectors: {
            required,
          },
        },
      };
    }
  },
  data() {
    return {
      editableSetting: null,
      updating: false,
      sectors: {
        name: 'Configuration des secteurs',
      },
      activate_sectors: {
        name: "Activer l'option secteur pour les résidents",
        message:
          'Activer cette option vous permettra de créer des secteurs et affecter les résidents de facon à pouvoir les trier plus facilement',
      },
    };
  },
  methods: {
    async updateEhpadSetting(setting, value) {
      this.$v.editableSetting.$touch();
      if (this.$v.$anyError) return;
      this.updating = true;
      await this.$store.dispatch('updateEhpadSetting', {
        id: this.currentEhpad.id,
        rootState: this.rootState,
        state: this.state,
        setting,
        value: String(value),
      }).then((result) => {
        this.$v.$reset();
      }).catch((error) => {
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
      this.updating = false;
    },
    name(setting) {
      return eval(`this.${setting}.name`);
    },
    message(setting) {
      return eval(`this.${setting}.message`);
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
    }),
    updatingClasses() {
      return this.updating ? 'fa fa-circle-notch fa-spin' : 'fa fa-check';
    },
  },
};
</script>

<style lang="scss" scoped></style>
