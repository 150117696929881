<template>
  <div>
    <div class="d-flex justify-content-center align-items-center mb-3">
      <span class="text-center">
        <h5 class="font-weight-bold m-0" style="display: inline-block;margin: 0;">
          Moyenne des tests (par index)
        </h5>
        <i class="fas fa-exclamation-triangle"
            style="color: #5bc0de;"
            v-b-tooltip.hover.v-primary
            title="Il s'agit d'une moyenne sur la valeur de chaque test des résidents.
              Ex: La première valeur du graphique est la moyenne de tout les premiers tests des résidents, idem pour la seconde, etc...
              Pour pouvoir faire une moyenne il faut au moins 5 résultats de résidents par test"
          />
      </span>
    </div>
    <div class="text-center" v-if="Object.keys(averageTestsResultsByIndex).length !== 0">
      <a :href="`/ehpads/${this.currentEhpad.id}/tests_history/download_by_test_index.xlsx`" target="_blank">
        <span>Exporter les données <i class="far fa-file-excel" /></span>
      </a>
    </div>
    <div v-if="averageTestsResultsByIndex !== undefined">
      <div v-if="Object.keys(averageTestsResultsByIndex).length !== 0" class="mt-3">
        <template v-for="(dataEhpadTest, testName, index) in averageTestsResultsByIndex">
          <test-chart
            :test-name="testName"
            :data-ehpad-test="dataEhpadTest"
            :test-type="'index'"
            :key="index"
          />
        </template>
      </div>
      <div class="text-center mt-3" style="color: #469AE0;" v-if="Object.keys(averageTestsResultsByIndex).length === 0">
        <h2>
          Pas de donnée : Choisissez un intervalle de date plus large
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FETCH_AVERAGE_TESTS_RESULTS_BY_INDEX } from '../../graphql/customs/queries';
import TestChart from "../../components/dashboard/TestChart.vue";

export default {
  name: 'TestResultsbyIndexComponent',
  components: {
    TestChart,
  },
  data() {
    return {
      startDate: null,
      endDate: null,
    };
  },
  apollo: {
    averageTestsResultsByIndex: {
      query: FETCH_AVERAGE_TESTS_RESULTS_BY_INDEX,
      variables() {
        return {
          ehpadId: this.currentEhpad.id,
        };
      },
      fetchPolicy: 'network-only',
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
    }),
  },
};
</script>

<style lang="scss" scoped>

</style>
