import gql from 'graphql-tag';
import { resourceFragment } from './fragments';

export const CREATE_RESOURCE = gql`
  mutation CREATE_RESOURCE(
    $name: String!
    $capacity: Int!
    $ehpadId: ID!
    $resourceCategoryId: ID!
    $firstName: String
    $job: String
  ) {
    createResource(
      input: {
        name: $name,
        capacity: $capacity,
        ehpadId: $ehpadId,
        resourceCategoryId: $resourceCategoryId,
        firstName: $firstName,
        job: $job,
      }
    ) {
      resource {
        ...ResourceFragment
      }
    }
  }
  ${resourceFragment}
`;

export const UPDATE_RESOURCE = gql`
  mutation UPDATE_RESOURCE(
    $id: ID!
    $name: String!
    $capacity: Int!
    $resourceCategoryId: ID!
    $firstName: String
    $job: String
  ) {
    updateResource(
      input: {
        id: $id,
        name: $name,
        capacity: $capacity,
        resourceCategoryId: $resourceCategoryId,
        firstName: $firstName,
        job: $job,
      }
    ) {
      resource {
        ...ResourceFragment
      }
    }
  }
  ${resourceFragment}
`;

export const SWAP_RESOURCE = gql`
  mutation SWAP_RESOURCE(
    $startDate: String!
    $endDate: String
    $resourceToChangeId: ID!
    $newResourceForChangeId: ID
  ) {
    swapResource(
      input: {
        startDate: $startDate
        endDate: $endDate
        resourceToChangeId: $resourceToChangeId 
        newResourceForChangeId: $newResourceForChangeId 
      }
    ) {
      resource {
        id
      }
    }
  }
`;
