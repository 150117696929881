<template>
  <b-container fluid v-if="resident">
    <app-event-mobista
      v-for="eventResidentTask in computedEventResidentTasks"
      :key="`${resident.id}-${eventResidentTask.task.id}-${eventResidentTask.eventId}-${eventResidentTask.eventDate}`"
      :eventResidentTask="eventResidentTask"
      :resident="resident"
      @update-event-resident-task="$emit('update-event-resident-task')"
    />
  </b-container>
</template>

<script>

import EventMobista from './EventMobista.vue';

export default {
  props: ['resident', 'computedEventResidentTasks'],
  components: {
    AppEventMobista: EventMobista,
  },
};
</script>

<style lang="scss" scoped>
</style>
