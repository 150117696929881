<template>
  <b-col class="col-wrapper" v-bind="$attrs">
    <slot></slot>
  </b-col>
</template>

<script>
export default {
  name: 'ColContent',
};
</script>

<style lang="scss" scoped>
.col-wrapper {
  background-color: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  box-shadow: 1px 1px 2px #F4F4F4;
  padding: 20px;
}
</style>