<template>
  <div>
        <div class="card bg-light mb-3" style="max-width: 18rem;">
          <div class="card-header">
            <span
              v-html="`${$options.filters.coloredMobDiagHtml(mobilizationDiagnostic)}`"
            >
            </span>
            <b-form-rating
              style="zoom: 0.7;"
              inline
              v-model="mobilizationDiagnostic.value"
              disabled
              size="sm"
              stars="3"
              icon-empty="plus-square"
              icon-full="plus-square-fill"
              icon-clear="slash-square"
              show-clear
            >
            </b-form-rating>
          </div>
          <div class="card-body">
            <div class="card-text">
            <div style="font-style: italic;font-size: smaller;">
              Le {{`${$options.filters.dateFormatterShort(mobilizationDiagnostic.date)}`}}
            </div>
            <div style="font-style: italic;font-size: smaller;">Par : <b>{{mobilizationDiagnostic.user.email | capitalizeString }}</b></div>
            <p
              v-if="mobilizationDiagnostic.comment !== '' && mobilizationDiagnostic.comment !== null"
              style="word-wrap: break-word;font-size: small;margin-bottom: 0;"
            >
              <span v-if="!readMoreActivated">
                {{mobilizationDiagnostic.comment.slice(0, 75)}}
                <span class="fake-link" v-if="!readMoreActivated && mobilizationDiagnostic.comment.length > 75" @click="activateReadMore">
                  ...Lire plus...
                </span>
              </span>
              <span v-if="readMoreActivated" v-html="mobilizationDiagnostic.comment"></span>
            </p>
            <p v-else style="word-wrap: break-word;white-space: pre-line;font-size: smaller;">Pas de commentaire</p>
            </div>
          </div>
        </div>

  </div>
</template>

<script>

export default {
  props: ['mobilizationDiagnostic'],
  data() {
    return {
      readMoreActivated: false,
    };
  },
  methods: {
    activateReadMore() {
      this.readMoreActivated = true;
    },
  },
};

</script>

<style lang="scss" scoped>
  .fake-link {
      color: blue;
      cursor: pointer;
  }
</style>
