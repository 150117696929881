<template>
  <!-- https://bootstrap-vue.js.org/docs/components/table -->
  <div>
    <b-row align-h="center">
      <b-col cols="10">
        <h6 class="text-center mb-3">
          Historique des Tests de mobilité
        </h6>

        <b-table
          bordered
          striped
          small
          hover
          :items="residentTests"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          class="m-0"
          ref="testTable"
          id="testTable"
        >
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// Webpack :
import { mapGetters } from 'vuex';

export default {
  props: ['residentTests'],
  methods: {

  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
    }),
  },
  data() {
    return {
      sortBy: 'date',
      sortDesc: true,
      fields: [
        {
          key: 'mobilizationTest.name',
          label: 'Nom du Test',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'date',
          label: 'Passé le',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'result',
          label: 'Résultat',
          sortable: true,
          sortByFormatted: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'comment',
          label: 'Commentaire',
          sortable: true,
          sortByFormatted: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>

</style>
