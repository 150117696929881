import gql from 'graphql-tag';

export const FIND_EHPAD = gql`
  query FIND_EHPAD($id: ID!) {
    findEhpad(id: $id) {
      id
      shortname
      color
      name
      email
      allSettings
      startingDate
      residentsWithRoom {
        id
        name
        firstName
        lastMobilizationDiagnostic {
          mobilizationCategory {
            name
            id
            color
          }
          id
        }
        forbiddenTasks {
          id
        }
        room {
          id
          number
        }
        sector {
          id
          name
        }
      }
      statusCategories {
        id
        name
      }
      resourcesCategories {
        id
        title
        icon
      }
      mobilizationCategories {
        id
        name
        number
        color
        description
        residents {
          id
          name
          firstName
          lastMobilizationDiagnostic {
            id
            mobilizationCategory {
              id
              name
              color
            }
          }
          forbiddenTasks {
            id
          }
          room {
            id
            number
          }
          sector {
            id
            name
          }
        }
        numberOfResidents
        mobilizationTests {
          id
          name
          url
          description
        }
      }
    }
  }
`;

export const FETCH_EHPADS = gql`
  query FETCH_EHPADS {
    ehpads {
      id
      shortname
      color
      name
      email
      allSettings
      startingDate
    }
  }
`;
