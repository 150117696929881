import gql from 'graphql-tag';

export const CREATE_RESIDENT_TEST = gql`
  mutation CREATE_RESIDENT_TEST(
    $residentId: ID!
    $mobilizationTestId: ID!
    $date: String!
    $result: Int!
    $comment: String
  ) {
    createResidentTest(
      input: { 
        residentId: $residentId, 
        mobilizationTestId: $mobilizationTestId,
        date: $date,
        result: $result,
        comment: $comment,
      }
    ) {
      residentTest {
        id
        date
        result
        comment
        mobilizationTest {
          id
          name
        }
      }
    }
  }
`;