<template>
  <b-modal :title="modalTitle" v-bind="$attrs" class="col-12 text-center">
    <b-container fluid>
      <b-form>
        <div class="form-inline">
          <label for="inline-form-input-cal" class="pr-4">Numéro de lit </label>
          <b-input
            id="inline-form-input-cal"
            size="sm"
            type="text"
            v-model="editableRoom.number"
            :class="{ 'is-invalid': $v.editableRoom.number.$error }"
          />
          <b-form-invalid-feedback>Valeur requise (20 caractères max)</b-form-invalid-feedback>
        </div>

        <div v-if="error" style="color: red;" class="text-center mt-2">
          <p v-for="(error, i) of error.graphQLErrors" :key="i">
            {{ error.message }}
          </p>
        </div>

        <b-button
          class="mt-3 d-flex mx-auto"
          variant="primary"
          size="sm"
          @click.prevent="updateRoom"
          >Valider</b-button
        >
      </b-form>
      <hr />
    </b-container>
  </b-modal>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, maxLength } from 'vuelidate/lib/validators';

export default {
  props: ['room'],
  mixins: [validationMixin],
  data() {
    return {
      error: null,
      editableRoom: null,
    };
  },
  created() {
    this.duplicateRoom(this.room);
  },
  validations: {
    editableRoom: {
      number: {
        required,
        maxLength: maxLength(20),
      },
    },
  },
  computed: {
    modalTitle() {
      return 'Modifier un lit';
    },
  },
  methods: {
    duplicateRoom(room) {
      this.editableRoom = { ...room };
    },
    async updateRoom() {
      this.$v.editableRoom.$touch();
      if (this.$v.$anyError) return;
      await this.$store.dispatch('updateRoom', {
        id: this.editableRoom.id,
        number: this.editableRoom.number,
      }).then((result) => {
        this.error = null;
        this.$v.$reset();
        this.$bvModal.hide('info-room-modal');
      }).catch((error) => {
        // Erreur dans la modale :
        this.error = error;
        // Erreur en Flash
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
