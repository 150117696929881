import gql from 'graphql-tag';

// EHPAD

export const FETCH_AVERAGE_TESTS_RESULTS_BY_DATE = gql`
  query FETCH_AVERAGE_TESTS_RESULTS_BY_DATE($startDate: String!, $endDate: String!, $ehpadId: ID!) {
    averageTestsResultsByDate(startDate: $startDate, endDate: $endDate, ehpadId: $ehpadId)
  }
`;

export const FETCH_AVERAGE_TESTS_RESULTS_BY_INDEX = gql`
  query FETCH_AVERAGE_TESTS_RESULTS_BY_INDEX($ehpadId: ID!) {
    averageTestsResultsByIndex(ehpadId: $ehpadId)
  }
`;
