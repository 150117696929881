<template>
  <div>
      <div class="d-flex justify-content-center align-items-center mb-3">
        <span class="text-center">
          <h5 class="m-0" style="display: inline-block;margin: 0;">
            Liste des lits
          </h5>
        </span>
      </div>

      <b-table
        sticky-header="600px"
        bordered
        striped
        small
        hover
        :items="rooms"
        :fields="fields"
        :sort-by.sync="sortBy"
        class="m-0"
        ref="roomTable"
        id="roomTable"
      >
        <template v-slot:cell(active)="data">{{ humanActive(data.item) }}</template>

        <template v-slot:head(actions)>{{ '' }}</template>

        <template v-slot:cell(actions)="data">
          <b-button
            variant="link"
            size="sm"
            v-b-modal.info-room-modal
            @click="selectRoom(data.item)"
          >
            <i class="fas fa-edit" />
            Modifier
          </b-button>
        </template>
      </b-table>

      <app-info-room-modal
        v-if="selectedRoom"
        id="info-room-modal"
        hide-footer
        :room="selectedRoom"
      />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import InfoRoomModal from './InfoRoomModal.vue';

export default {
  name: 'RoomTable',
  components: {
    appInfoRoomModal: InfoRoomModal,
  },
  data() {
    return {
      updating: false,
      selectedRoom: null,
      sortBy: 'number',
      fields: [
        {
          key: 'number',
          label: 'Numéro de lit',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'actions',
          label: 'actions',
          class: 'text-center',
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
      ],
    };
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', () => {
      this.selectedRoom = null;
    });
  },
  methods: {
    selectRoom(room) {
      this.selectedRoom = room;
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
      rooms: 'rooms',
    }),
  },
};
</script>

<style lang="scss" scoped></style>
