<template>
  <div class="mb-5">
    <h5 class="text-center">{{ testName | capitalizeString }}</h5>
    <div v-if="testType == 'index'">
      <line-chart :data="dataEhpadTest" :library="chartOptionsForIndex"></line-chart>
    </div>
    <div v-else-if="testType == 'date'">
      <line-chart :data="dataEhpadTest" :points=false :library="chartOptionsForDate"></line-chart>
    </div>
  </div>
</template>

<script>
export default {
  name: "TestChartComponent",
  props: ["testName", "dataEhpadTest", "testType"],
  computed: {
    chartOptionsForIndex() {
      return {
        scales: {
          xAxes: [{
            minimum: 1,
            type: "integer",
            scaleLabel: {
              display: true,
              labelString: "Index du test",
            },
            ticks: {
              min: 1,
              stepSize: 1,
              precision: 0,
            },
          }],
          yAxes: [{
            ticks: {
              min: this.minWithMargin,
              max: this.maxWithMargin
            }
          }]
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
      };
    },
    chartOptionsForDate() {
      return {
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              displayFormats: {
                day: 'DD MMM YYYY',
              },
            },
          }],
          yAxes: [{
            ticks: {
              min: this.minWithMargin,
              max: this.maxWithMargin
            }
          }]
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
      };
    },
    minWithMargin() {
      const minValue = this.dataEhpadTest.reduce((acc, val) => Math.min(acc, val[1]), this.dataEhpadTest[0][1]);
      return Math.floor(minValue - (minValue * 0.05));
    },
    maxWithMargin() {
      const maxValue = this.dataEhpadTest.reduce((acc, val) => Math.max(acc, val[1]), this.dataEhpadTest[0][1]);
      return Math.ceil(maxValue + (maxValue * 0.05));
    },
  },
};

</script>
