<template>
  <div class="d-flex justify-content-between align-items-center mb-3" v-if="resident !== null">
    <b-button
      v-if="checkFirstItem"
      variant="primary"
      :to="{ path: formatButtonLink('prev') }"
      size="sm"
    >
      Résident précédent
    </b-button>
    <b-col>
      <b-row align-h="center">
          <div class="d-flex justify-content-center align-items-center top-title">
            <h3 v-html="title"></h3>
            <i
              v-if="resident.lastMobilizationDiagnostic !== null"
              class="fas fa-exclamation-triangle mb-1 ml-1"
              style="color: #5bc0de;"
              v-b-tooltip.hover.v-primary
              :title=resident.lastMobilizationDiagnostic.mobilizationCategory.description
            />
            <b-button
              v-if="targetRoute != 'diagnostics'"
              class="mb-2 ml-4 d-print-none"
              variant="primary"
              size="sm"
              :to="{ path: `/ehpads/${currentEhpad.id}/resident/${resident.id}/diagnostics` }"
            >
              <i class="fas fa-chart-line" />
            </b-button>
            <b-button
              v-if="targetRoute != 'mobilization'"
              class="mb-2 ml-4 d-print-none custom-icon"
              variant="primary"
              size="sm"
              :to="{ path: `/ehpads/${currentEhpad.id}/resident/${resident.id}/mobilization` }"
            >
              <img src="/assets/images/tasks-on_borderless.png">
            </b-button>
            <b-button
              v-if="targetRoute != 'calendar'"
              class="mb-2 ml-4 d-print-none"
              variant="primary"
              size="sm"
              :to="{ path: `/ehpads/${currentEhpad.id}/resident/${resident.id}/calendar` }"
            >
              <i class="far fa-calendar-alt" />
            </b-button>
          </div>
      </b-row>
    </b-col>

    <b-button
      v-if="checkLastItem"
      variant="primary"
      :to="{ path: formatButtonLink('next') }"
      size="sm"
    >
      Résident suivant
    </b-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  props: ['targetRoute'],
  computed: {
    ...mapGetters({
      resident: 'resident',
      currentEhpad: 'ehpad',
    }),
    title() {
      let { firstName } = this.resident;
      if (firstName === null) { firstName = ''; }
      return `${firstName} ${this.resident.name.toUpperCase()} (${this.$options.filters.coloredMobDiagHtml(this.resident.lastMobilizationDiagnostic)})`;
    },
    checkFirstItem() {
      if ((this.prevResidentId() === null) || (this.resident.room === null)) { return false; }
      return true;
    },
    checkLastItem() {
      if ((this.nextResidentId() === null) || (this.resident.room === null)) { return false; }
      return true;
    },
  },
  methods: {
    nextResidentId() {
      if (localStorage.mobiviewResidentsWithRoomSorted === undefined) { return null; }
      const residentsWithRoomSorted = JSON.parse(localStorage.mobiviewResidentsWithRoomSorted);
      const index = _.findIndex(residentsWithRoomSorted, { id: this.resident.id });
      if (residentsWithRoomSorted[index + 1] === undefined) { return null; }
      return residentsWithRoomSorted[index + 1].id;
    },
    prevResidentId() {
      if (localStorage.mobiviewResidentsWithRoomSorted === undefined) { return null; }
      const residentsWithRoomSorted = JSON.parse(localStorage.mobiviewResidentsWithRoomSorted);
      const index = _.findIndex(residentsWithRoomSorted, { id: this.resident.id });
      if (residentsWithRoomSorted[index - 1] === undefined) { return null; }
      return residentsWithRoomSorted[index - 1].id;
    },
    formatButtonLink(type) {
      let id;
      if (type === 'next') {
        id = this.nextResidentId();
      } else {
        id = this.prevResidentId();
      }
      return `/ehpads/${this.currentEhpad.id}/resident/${id}/${this.targetRoute}`;
    },
  },
};
</script>

<style lang="scss" scoped>
  .custom-icon {
    width: 33px;
    padding: 2px;
  }
  .custom-icon img {
    width: 100%;
  }
</style>
