<template>
  <b-container fluid>
    <div class="mx-5 d-print-none">
      <b-row class="justify-content-around">
        <b-col cols="12" lg="5" class="mt-2">
        <h6 class="text-center mb-2">Diagnostic de mobilité retenu</h6>
          <b-form>
            <b-row class="mt-3">
              <b-col cols="12" md="6" class="mb-3">
                <b-form-select
                  v-model="mobilizationCategory"
                  :options="mobilizationCategoriesOptions"
                  :class="{'is-invalid': $v.mobilizationCategory.$anyError}"
                >
                <template #first>
                  <b-form-select-option :value="null" disabled>Selectionner une catégorie</b-form-select-option>
                </template>
              </b-form-select>
              <b-form-invalid-feedback>Catégorie requise</b-form-invalid-feedback>

              </b-col>

              <b-col cols="12" md="6" class="mb-3">
                <b-form-rating
                  inline
                  stars="3"
                  icon-empty="plus-square"
                  icon-full="plus-square-fill"
                  icon-clear="slash-square"
                  show-clear
                  v-model="mobilizationDiagnostic.value"
                >
                </b-form-rating>
              </b-col>


              <b-col cols="12" md="6" class="mb-3">
                <b-form-datepicker
                  id="date-datepicker-diag"
                  :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                  label-help
                  label-no-date-selected="Aucune date sélectionnée"
                  :max="today"
                  v-model="mobilizationDiagnostic.date"
                  locale="fr"
                  :class="{'is-invalid': $v.mobilizationDiagnostic.date.$error}"
                />
              </b-col>
              <b-col cols="12">
                <b-form-textarea
                  id="comment-diag"
                  v-model="mobilizationDiagnostic.comment"
                  type="text"
                  rows="6"
                  size="sm"
                  placeholder="Saisissez un commentaire attaché à ce diagnostic de mobilité"
                />
              </b-col>
              <b-button class="mt-3 mx-auto d-flex align-items-center" variant="primary" size="sm" type="submit" @click.prevent="createMobilizationDiagnostic()" :disabled="loading">
                <i class="mr-2" :class="loading ? 'fa fa-spin fa-circle-notch' : 'fas fa-plus'" />
                  Ajouter ce diagnostic de mobilité
              </b-button>
            </b-row>
          </b-form>
        </b-col>
        <b-col cols="12" lg="5" class="mt-2">
          <h6 class="text-center mb-2">Aide et informations</h6>
            <div class="accordion" role="tablist">
              <b-card
                v-for="mobilizationCategory, index in this.currentEhpad.mobilizationCategories"
                :key="index"
                no-body
                class="mb-1"
              >
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block v-b-toggle="'accordion-' + index" :style='{backgroundColor: mobilizationCategory.color}'>{{mobilizationCategory.name}}</b-button>
                </b-card-header>
                <b-collapse :id="'accordion-' + index" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-card-text>{{ mobilizationCategory.description }}</b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          <p class="mb-0">Les <b-icon-plus-square></b-icon-plus-square><span class="small"> : Ils servent à nuancer les capacités du résident au sein d'une même catégorie.</span></p>
          <p>Le <b-icon-slash-square></b-icon-slash-square><span class="small"> : Il sert à éffacer les <b-icon-plus-square></b-icon-plus-square> en cas d'erreur.</span></p>
        </b-col>
      </b-row>

    </div>
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';
import _ from 'lodash';

export default {
  props: ['resident'],
  mixins: [validationMixin],
  data() {
    return {
      loading: false,
      error: null,
      mobilizationCategory: null,
      mobilizationDiagnostic: {
        value: null,
        comment: '',
        date: null,
      },
    };
  },
  validations: {
    mobilizationDiagnostic: {
      date: {
        required,
      },
    },
    mobilizationCategory: {
      id: {
        required,
      },
      name: {
        required,
      },
      number: {
        required,
      },
    },
  },
  created() {
    this.mobilizationDiagnostic.date = this.today;
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
    }),
    today() {
      const now = new Date();
      return new Date(now.getFullYear(), now.getMonth(), now.getDate());
    },
    mobilizationCategoriesOptions() {
      // Todo in getter
      const sortedMobilizationCategory = _.sortBy(this.currentEhpad.mobilizationCategories, mobcat => mobcat.number);
      return sortedMobilizationCategory.map((el) => ({
        text: el.name,
        value: {
          id: el.id,
          name: el.name,
          number: el.number,
        },
      }));
    },
  },
  methods: {
    ...mapActions(['findEhpad']),
    async createMobilizationDiagnostic() {
      this.$v.mobilizationDiagnostic.$touch();
      this.$v.mobilizationCategory.$touch();
      if (this.$v.$anyError) return;
      this.loading = true;
      if (this.mobilizationDiagnostic.value === null) { this.mobilizationDiagnostic.value = 0; }
      await this.$store.dispatch('createMobilizationDiagnostic', {
        mobilizationCategory: this.mobilizationCategory,
        comment: this.mobilizationDiagnostic.comment,
        value: this.mobilizationDiagnostic.value,
        date: moment(this.mobilizationDiagnostic.date).format('YYYY-MM-DD'),
        residentId: this.resident.id,
      }).then((result) => {
        this.$v.$reset();
        this.mobilizationCategory = null;
        this.mobilizationDiagnostic.comment = '';
        this.mobilizationDiagnostic.value = 0;
        this.mobilizationDiagnostic.date = this.today;
        // For refreshing mobilization categories on global :
        this.findEhpad(this.currentEhpad.id);
      }).catch((error) => {
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
