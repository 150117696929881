<template>
  <b-col cols="12" lg="5" class="mx-2">
    <span class="d-flex">
      <h5> {{ name(rootState) }}</h5>
      <i
        v-if="message(rootState) !== undefined"
        class="fas fa-exclamation-triangle mt-1 ml-2"
        style="color: #5bc0de;"
        v-b-tooltip.hover.v-primary
        :title="message(rootState)"
      />
    </span>

    <app-child-settings-form
      v-for="(values, state, index) in rootValues"
      :state="state"
      :values="values"
      :rootState="rootState"
      :key="index"
    />

  </b-col>
</template>

<script>

import { mapGetters } from 'vuex';

import ChildSettingsForm from './ChildSettingsForm.vue'

export default {
  name: 'RootSettings',
  props: ['rootValues', 'rootState'],
  components: {
    appChildSettingsForm: ChildSettingsForm,
  },
  data() {
    return {
      editableSetting: null,
      updating: false,
      configuration: {
        name: 'Autres paramétres',
      },
    };
  },
  methods: {
    name(setting) {
      return eval(`this.${setting}.name`);
    },
    message(setting) {
      return eval(`this.${setting}.message`);
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
    }),
  },
};
</script>

<style lang="scss" scoped></style>
