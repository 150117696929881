import _ from 'lodash';

import apiClient from '../../utilities/api_client';
import { FIND_MOBILIZATION_TEST, FETCH_MOBILIZATION_TESTS } from '../../graphql/mobilization_tests/queries';
import {
  CREATE_MOBILIZATION_TEST,
  UPDATE_MOBILIZATION_TEST,
  DESTROY_MOBILIZATION_TEST,
} from '../../graphql/mobilization_tests/mutations';

const mobilizationTestState = {
  mobilizationTest: null,
  mobilizationTests: [],
};

const mutations = {
  SET_MOBILIZATION_TESTS(state, mobilizationTests) {
    state.mobilizationTests = mobilizationTests;
  },
  SELECT_MOBILIZATION_TEST(state, mobilizationTest) {
    state.mobilizationTest = mobilizationTest;
  },
  ADD_MOBILIZATION_TEST(state, mobilizationTest) {
    state.mobilizationTests.push(mobilizationTest);
  },
  UPDATE_MOBILIZATION_TEST(state, mobilizationTest) {
    const index = _.findIndex(state.mobilizationTests, { id: mobilizationTest.id });
    state.mobilizationTests.splice(index, 1, mobilizationTest);
  },
  DELETE_MOBILIZATION_TEST(state, id) {
    const index = _.findIndex(state.mobilizationTests, { id });
    state.mobilizationTests.splice(index, 1);
  }
};

const actions = {
  setMobilizationTests: async ({ commit, state }, ehpadId) => {
    state.mobilizationTests = [];
    const { data } = await apiClient.query({
      query: FETCH_MOBILIZATION_TESTS,
      variables: { ehpadId },
      fetchPolicy: 'network-only',
    });
    commit('SET_MOBILIZATION_TESTS', data.mobilizationTests);
  },
  findMobilizationTest: async ({ commit, state }, id) => {
    state.mobilizationTest = null;
    const { data } = await apiClient.query({
      query: FIND_MOBILIZATION_TEST,
      variables: { id },
      fetchPolicy: 'network-only',
    });
    commit('SELECT_MOBILIZATION_TEST', data.findMobilizationTest);
  },
  createMobilizationTest: async ({ commit, dispatch }, formData) => {
    const { data } = await apiClient.mutate({
      mutation: CREATE_MOBILIZATION_TEST,
      variables: { ...formData },
    });
    const { mobilizationTest } = data.createMobilizationTest;
    commit('ADD_MOBILIZATION_TEST', mobilizationTest);
    // How to Dispatch in another module (need namespaced true)
    dispatch('findEhpad', mobilizationTest.mobilizationCategories[0].ehpad.id);
  },
  updateMobilizationTest: async ({ commit, dispatch }, formData) => {
    const { data } = await apiClient.mutate({
      mutation: UPDATE_MOBILIZATION_TEST,
      variables: { ...formData },
    });
    const { mobilizationTest } = data.updateMobilizationTest;
    commit('UPDATE_MOBILIZATION_TEST', mobilizationTest);
    dispatch('findEhpad', mobilizationTest.mobilizationCategories[0].ehpad.id);
  },
  destroyMobilizationTest: async ({ commit }, formData) => {
    const { data } = await apiClient.mutate({
      mutation: DESTROY_MOBILIZATION_TEST,
      variables: { ...formData },
    });
    const { mobilizationTest } = data.destroyMobilizationTest;
    commit('DELETE_MOBILIZATION_TEST', mobilizationTest.id);
  },
};

const getters = {
  mobilizationTests: (state) => state.mobilizationTests,
  mobilizationTest: (state) => state.mobilizationTest,
};

export default {
  state: mobilizationTestState,
  mutations,
  actions,
  getters,
};
