<template>
    <div>
        <app-mobilization-tests-table />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MobilizationTestsTable from '../../components/mobilization_tests/Table.vue';

export default {
  components: {
    appMobilizationTestsTable: MobilizationTestsTable,
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
    }),
  },
};
</script>

<style lang="scss" scoped>
</style>
