<template>
  <b-modal v-bind="$attrs" size="lg" class="col-12 text-center">
    <template #modal-title>
      Modifier tous les événements d'une ressource
    </template>
    <b-container fluid>
      <b-form>
        <b-row class="mb-2">
          <b-col cols="6">
            <label for="inline-form-input-title" class="pr-4 text-nowrap"
              >Date de début du changement :
            </label>
          </b-col>
          <b-col cols="6">
            <b-form-datepicker
              id="date-datepicker-start-swap"
              :date-format-options="{
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                weekday: 'short',
              }"
              label-help
              label-no-date-selected="Aucune date sélectionnée"
              size="sm"
              v-model="startDate"
              locale="fr"
              :class="{ 'is-invalid': $v.startDate.$anyError }"
            />
            <b-form-invalid-feedback v-if="!$v.startDate.required">Valeur requise</b-form-invalid-feedback>
            <b-form-invalid-feedback v-else>La date est invalide</b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="6">
            <label for="inline-form-input-title" class="pr-4 text-nowrap"
              >Date de fin du changement :
              <i
                class="fas fa-exclamation-triangle mx-auto mt-2"
                style="color: red;"
                v-b-tooltip.hover.v-danger.html
                title="Laissez ce champ vide pour modifier tout les événements à venir pour cette ressource"
              />
            </label>
          </b-col>
          <b-col cols="6">
            <b-form-datepicker
              id="date-datepicker-end-swap"
              :date-format-options="{
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                weekday: 'short',
              }"
              label-help
              label-no-date-selected="Aucune date sélectionnée"
              size="sm"
              v-model="endDate"
              locale="fr"
            />
          </b-col>
        </b-row>

        <b-row class="pt-2">
          <b-col cols="4">
            <label for="inline-form-input-option" class="pr-4 text-nowrap">Ressource sélectionnée : </label>
          </b-col>
          <b-col cols="8">
            <multiselect
              v-model="resourceToChange"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="true"
              :preserve-search="false"
              :options="resources"
              placeholder="Rechercher/Sélectionner une ressource"
              label="name"
              track-by="name"
              :class="{ 'is-invalid': $v.resourceToChange.$anyError }"
            >
              <span slot="noResult">Pas de résultat correspondant</span>
            </multiselect>
            <b-form-invalid-feedback>Valeur requise</b-form-invalid-feedback>
          </b-col>
        </b-row>

        <b-row class="pt-2">
          <b-col cols="4">
            <label for="inline-form-input-option" class="pr-4 text-nowrap">
              Remplacer par :
              <i
                class="fas fa-exclamation-triangle mx-auto mt-2"
                style="color: red;"
                v-b-tooltip.hover.v-danger.html
                title="Laissez ce champ vide et la ressource ne sera pas remplacée par une autre, elle sera supprimée des événements"
              />
            </label>
          </b-col>
          <b-col cols="8">
            <multiselect
              v-model="newResourceForChange"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="true"
              :preserve-search="false"
              :options="resources"
              placeholder="Rechercher/Sélectionner une ressource"
              label="name"
              track-by="name"
            >
              <span slot="noResult">Pas de résultat correspondant</span>
            </multiselect>
          </b-col>
        </b-row>

        <b-button v-if="!loading" class="d-flex mx-auto mt-3" variant="primary" size="sm" @click.prevent="swapResource">
          Valider
        </b-button>
        <b-button v-else class="d-flex mx-auto mt-3" variant="primary" size="sm" disabled>
          <b-spinner small type="grow"></b-spinner>
          Chargement...
        </b-button>
      </b-form>
    </b-container>
  </b-modal>
</template>

<script>

import Swal from 'sweetalert2';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import Multiselect from 'vue-multiselect';

export default {
  props: ['elementType'],
  mixins: [validationMixin],
  data() {
    return {
      startDate: moment().toDate(),
      endDate: null,
      resourceToChange: null,
      newResourceForChange: null,
      loading: false,
    };
  },
  components: {
    Multiselect,
  },
  validations: {
    startDate: {
      required,
      isSameOrBeforeEnd(val) {
        if (this.endDate === null) return true;
        const dayStart = moment(val, 'YYYY-MM-DD');
        const dayEnd = moment(this.endDate, 'YYYY-MM-DD');
        return dayStart.isSameOrBefore(dayEnd);
      },
    },
    resourceToChange: {
      required,
    },
  },
  created() {
    moment.locale('fr-FR');
  },
  methods: {
    ...mapActions(['swapResource']),
    async swapResource() {
      Swal.fire({
        title: 'Êtes vous sûr ?',
        text: 'Tous les événements de cette ressource seront modifiés',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$v.$touch();
          if (this.$v.$anyError) return;
          this.loading = true;
          const endDateParsed = this.endDate ? moment(this.endDate).format('YYYY-MM-DD') : null;
          await this.$store.dispatch('swapResource', {
            startDate: moment(this.startDate).format('YYYY-MM-DD'),
            endDate: endDateParsed,
            resourceToChangeId: this.resourceToChange.id,
            newResourceForChangeId: (this.newResourceForChange?.id || null),
          }).then(() => {
            this.resetData();
            this.$v.$reset();
            this.$emit('events-refetch');
            this.$bvModal.hide(`full-calendar-swap-resource-modal-${this.elementType}`);
          }).catch((error) => {
            this.flashMessage.show({
              status: 'error',
              title: error.graphQLErrors[0].extensions.code,
              message: error.graphQLErrors[0].message,
            });
          }).finally(() => {
            this.loading = false;
          });
        }
      });
    },
    resetData() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
      resources: 'resources',
    }),
  },
};
</script>

<style lang="scss" scoped>
</style>
