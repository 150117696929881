<template>
  <!-- https://bootstrap-vue.js.org/docs/components/table -->
  <div>
    <div class="d-flex justify-content-center align-items-center mb-3">
      <span class="text-center">
        <h3 class="m-0" style="display: inline-block;margin: 0;">
          Tests de Mobilité
        </h3>
        <b-button class="mb-2 ml-4" variant="primary" size="sm"
          :to="{path: `/ehpads/${currentEhpad.id}/mobilization_tests/new`}"
        >
          <i class="fas fa-plus" />
          Créer un nouveau test
        </b-button>
      </span>
    </div>

    <b-form-group
      label-size="sm"
      label-for="filterInput"
      class="mb-3"
      style="width: 40%;"
    >
      <b-input-group size="sm">
        <b-form-input
          v-model="filter"
          type="search"
          id="filterInput"
          placeholder="Filtre de recherche"
        ></b-form-input>
        <b-input-group-append>
          <b-button :disabled="!filter" @click="filter = ''">Effacer</b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <b-table
      :filter="filter"
      bordered
      striped
      small
      hover
      :items="mobilizationTests"
      :fields="fields"
      :sort-by.sync="sortBy"
      class="m-0"
      ref="mobilizationTestsTable"
      id="mobilizationTestsTable"
    >
        <template v-slot:cell(mobilizationCategories)="data"
        >
          <div class="d-flex justify-content-right">
            <template
              v-for="mobilizationCategory in data.item.mobilizationCategories"
            >
              <span
                :key='`${data.item.id}_${mobilizationCategory.id}`'
              >
                <b-badge
                  class="mr-1"
                  style="font-size: small;"
                  :style='{backgroundColor: mobilizationCategory.color}'
                >
                <span>
                  {{ mobilizationCategoryTitle(mobilizationCategory.name) }}
                </span>
                </b-badge>
              </span>
            </template>
          </div>
        </template>

      <template v-slot:head(actions)>{{ '' }}</template>

      <template v-slot:cell(actions)="data">
        <b-button variant="link" size="sm" :to="{path: `/ehpads/${currentEhpad.id}/mobilization_tests/${data.item.id}`}">
          <i class="fas fa-edit"
            v-b-tooltip.hover.v-primary
            title="Modifier"
          />
        </b-button>
        <b-button variant="link" size="sm" @click="destroyMobilizationTest(data.item)">
          <i class="text-danger fa fa-trash-alt"
            v-b-tooltip.hover.v-danger
            title="Supprimer"
          />
        </b-button>
      </template>
    </b-table>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      selectedMobilizationTest: null,
      filter: null,
      sortBy: 'name',
      fields: [
        {
          key: 'name',
          label: 'Nom',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'residentTests.length',
          label: 'Nombre de tests réalisés',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'mobilizationCategories',
          label: 'Catégories de mobilité du test',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'actions',
          label: 'actions',
          class: 'text-center',
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
      ],
    };
  },
  created() {
    this.setMobilizationTests(this.currentEhpad.id);
  },
  methods: {
    ...mapActions(['setMobilizationTests']),
    mobilizationCategoryTitle(name) {
      return `${name}`;
    },
    destroyMobilizationTest(mobilizationTest) {
      Swal.fire({
        title: "Êtes vous sûr ? Cette action est irréversible.",
        text:
          "Êtes vous sûr de vouloir supprimer ce test de mobilité de facon permanente ? Les " + mobilizationTest.residentTests.length + " tests résidents associés seront également définitivement supprimés.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
        allowOutsideClick: true,
      }).then(async (result) => {
        if (result.value) {
          await this.$store.dispatch("destroyMobilizationTest", {
            id: mobilizationTest.id,
          }).catch((error) => {
            this.flashMessage.show({
              status: "error",
              title: error.graphQLErrors[0].extensions.code,
              message: error.graphQLErrors[0].message,
            });
          });
        }
      });
    },
  },
  computed: {
    ...mapGetters({
      mobilizationTests: 'mobilizationTests',
      currentUser: 'user',
      currentEhpad: 'ehpad',
    }),
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep .small-size {
    max-width: 75px;
  }

</style>
