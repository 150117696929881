import Vue from 'vue';
import moment from 'moment';
import _ from 'lodash';

Vue.filter('addNumPadding', (int) => (int < 10 ? `0${int}` : int));

Vue.filter('dateFormatter', (str) => {
  const date = moment(str);
  return date.locale('fr').format('ddd DD MMM YYYY');
});

Vue.filter('dateFormatterFull', (str) => {
  const date = moment(str);
  return date.locale('fr').format('dddd DD MMM YYYY');
});

Vue.filter('dateFormatterShort', (str) => {
  const date = moment(str);
  return date.locale('fr').format('DD/MM/YYYY');
});

Vue.filter('capitalizeString', (str) => `${str[0].toUpperCase()}${str.slice(1)}`);

Vue.filter('coloredMobDiagHtml', (lastMobilizationDiagnostic) => {
  if (lastMobilizationDiagnostic == null) {
    return '<b style="color:red;">!! Pas de catégorie !!</b>';
  }
  const para = document.createElement('b');
  const node = document.createTextNode(`${_.get(lastMobilizationDiagnostic, 'mobilizationCategory.name', null)}`);
  para.appendChild(node);
  para.style.color = lastMobilizationDiagnostic.mobilizationCategory.color;
  return para.outerHTML;
});

Vue.filter('dataWithDate', (value, date) => {
  const localizedDate = moment(date).format('DD/MM/YYYY');
  return `<b>${value}</b> <i style="color: darkgray;font-size: smaller;">(Le : ${localizedDate})</i>`;
});
