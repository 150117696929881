<template>
  <div>
    <div class="d-flex justify-content-center align-items-center mb-3">
      <span class="text-center">
        <h3 class="m-0" style="display: inline-block;margin: 0;">
          Nouvelle Ressource
        </h3>
      </span>
    </div>
    <b-row align-h="center">
      <b-col cols="12" xl="8">
        <app-new-resource-form />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import NewResourceForm from '../../components/resources/NewResourceForm.vue';

export default {
  components: {
    appNewResourceForm: NewResourceForm,
  },
};

</script>

<style>

</style>
