<template>
  <div>
    <div class="card m-3" :style="{border: '1px solid'+ editableMobilizationCategory.color}">
      <div class="card-body">
        <b-form>
          <div>
            <label :for="`inline-form-input-cat-name${editableMobilizationCategory.number}`" class="pr-4">Nom de la catégorie :</label>
            <b-input
              :id="`inline-form-input-cat-name${editableMobilizationCategory.number}`"
              size="sm"
              type="text"
              v-model="editableMobilizationCategory.name"
              :class="{ 'is-invalid': $v.editableMobilizationCategory.name.$error }"
            />
            <b-form-invalid-feedback>Valeur requise (50 caractères max)</b-form-invalid-feedback>
          </div>

          <div class="pt-2">
            <label :for="`inline-form-input-cat-desc${editableMobilizationCategory.number}`" class="pr-4">Description :</label>
            <b-textarea
              :id="`inline-form-input-cat-desc${editableMobilizationCategory.number}`"
              size="sm"
              type="text"
              rows="4"
              v-model="editableMobilizationCategory.description"
              :class="{ 'is-invalid': $v.editableMobilizationCategory.description.$error }"
            />
            <b-form-invalid-feedback>Valeur requise</b-form-invalid-feedback>
          </div>

          <div class="pt-2">
            <label class="pr-4">Couleur (cliquer sur la bulle pour changer) :</label>
            <app-verte
              v-model="editableMobilizationCategory.color"
              :showHistory="null"
              model="hex"
              display="picker"
            ></app-verte>
          </div>
          <b-button
            class="mt-3 d-flex mx-auto"
            variant="primary"
            size="sm"
            @click.prevent="updateMobilizationCategory"
            >Valider</b-button
          >
        </b-form>
        <h6 v-if="saved" style="color: red;" class="mt-4">
          Les modifications ont été enregistrées.
        </h6>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import verte from 'verte';
import 'verte/dist/verte.css';
import { required, maxLength } from 'vuelidate/lib/validators';

export default {
  name: 'MobilizationCategoryForm',
  props: ['mobilizationCategory'],
  components: {
    appVerte: verte,
  },
  mixins: [validationMixin],
  validations: {
    editableMobilizationCategory: {
      name: {
        required,
        maxLength: maxLength(30),
      },
      description: {
        required,
      },
      color: {
        required,
      },
    },
  },
  data() {
    return {
      saved: false,
      error: null,
      editableMobilizationCategory: null,
    };
  },
  watch: {
    mobilizationCategory: {
      handler() {
        if (this.mobilizationCategory !== null) {
          this.duplicateMobilizationCategory(this.mobilizationCategory);
        }
      },
      immediate: true,
    },
  },
  methods: {
    duplicateMobilizationCategory(mobilizationCategory) {
      this.editableMobilizationCategory = { ...mobilizationCategory };
    },
    async updateMobilizationCategory() {
      this.error = null;
      this.saved = false;
      this.$v.editableMobilizationCategory.$touch();
      if (this.$v.$anyError) return;
      await this.$store.dispatch('updateMobilizationCategory', {
        id: this.editableMobilizationCategory.id,
        name: this.editableMobilizationCategory.name,
        description: this.editableMobilizationCategory.description,
        color: this.editableMobilizationCategory.color,
        email: this.editableMobilizationCategory.email,
      }).then((result) => {
        this.$v.$reset();
      }).catch((error) => {
        this.error = error;
        this.flashMessage.show({
          status: 'error',
          title: error.graphQLErrors[0].extensions.code,
          message: error.graphQLErrors[0].message,
        });
      });
      if (this.error == null) { this.saved = true; }
    },
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
    }),
  },
};
</script>

<style lang="scss" scoped></style>
