<template>
  <div>
    <app-resident-nav-bar :targetRoute="'calendar'" />
    <hr />

    <b-row v-if="resident !== null">
      <b-col cols="12" lg="6">
        <app-full-calendar
          elementType='Resident'
          :elementId='resident.id'
          ref="residentCalendar"
        />
      </b-col>
      <b-col cols="12" lg="6">
        <app-full-calendar
          elementType='Ehpad'
          :elementId='currentEhpad.id'
        />
      </b-col>
    </b-row>

    <div class="text-center" style="color: #469AE0;" v-else>
      <h4>
        <i class="fa fa-spin fa-circle-notch" />
        Chargement...
      </h4>
    </div>

    <hr />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FullCalendar from '../../components/calendars/FullCalendar.vue';
import ResidentNavBar from '../../components/residents/ResidentNavBar.vue';

export default {
  props: ['residentId'],
  components: {
    appFullCalendar: FullCalendar,
    appResidentNavBar: ResidentNavBar,
  },
  methods: {
    ...mapActions([
      'findResident',
    ]),
  },
  computed: {
    ...mapGetters({
      resident: 'resident',
      currentEhpad: 'ehpad',
    }),
  },
  watch: {
    residentId: {
      handler() {
        this.findResident({
          id: this.residentId,
        });
      },
    },
  },
  created() {
    this.findResident({
      id: this.residentId,
    });
  },
};
</script>

<style lang="scss" scoped>
.loading-centered {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
</style>
