<template>
  <!-- https://bootstrap-vue.js.org/docs/components/table -->
  <div>
    <div class="d-flex justify-content-center align-items-center mb-3">
      <span class="text-center">
        <h3 class="m-0" style="display: inline-block;margin: 0;">
          Activités
        </h3>
        <b-button class="mb-2 ml-4" variant="primary" size="sm"
          :to="{path: `/ehpads/${currentEhpad.id}/tasks/new`}"
        >
          <i class="fas fa-plus" />
          Créer une nouvelle Activité
        </b-button>
      </span>
    </div>

    <b-form-group
      label-size="sm"
      label-for="filterInput"
      class="mb-3"
      style="width: 40%;"
    >
      <b-input-group size="sm">
        <b-form-input
          v-model="filter"
          type="search"
          id="filterInput"
          placeholder="Filtre de recherche"
        ></b-form-input>
        <b-input-group-append>
          <b-button :disabled="!filter" @click="filter = ''">Effacer</b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <b-table
      :filter="filter"
      bordered
      striped
      small
      hover
      :items="tasks"
      :fields="fields"
      :sort-by.sync="sortBy"
      class="m-0"
      ref="tasksTable"
      id="tasksTable"
    >

      <template v-slot:head(actions)>{{ '' }}</template>


      <template v-slot:cell(actions)="data">
        <b-button variant="link" size="sm" :to="{path: `/ehpads/${currentEhpad.id}/tasks/${data.item.id}`}">
          <i class="fas fa-edit"
            v-b-tooltip.hover.v-primary
            title="Modifier"
          />
        </b-button>
        <b-button variant="link" size="sm" @click="destroyTask(data.item)">
          <i class="text-danger fa fa-trash-alt"
            v-b-tooltip.hover.v-danger
            title="Supprimer"
          />
        </b-button>
      </template>
    </b-table>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      filter: null,
      sortBy: 'title',
      fields: [
        {
          key: 'title',
          label: 'Titre',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'eventResidentTasks.length',
          label: 'Activité effectuée (nb résidents)',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'eventDefaultTasks.length',
          label: 'Activité présente (nb événements)',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'description',
          label: 'Description',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          key: 'humanTaskType',
          label: "Type d'activité",
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
          class: 'min-size',
        },
        {
          key: 'actions',
          label: 'actions',
          class: 'text-center',
          tdClass: 'align-middle',
          thClass: 'align-middle',
          class: 'min-size',
        },
      ],
    };
  },
  created() {
    this.setTasks(this.currentEhpad.id);
  },
  methods: {
    ...mapActions(['setTasks']),
    destroyTask(task) {
      Swal.fire({
        title: "Êtes vous sûr ? Cette action est irréversible.",
        text:
          "Êtes vous sûr de vouloir supprimer cette activité de facon permanente ? Les " + task.eventResidentTasks.length + " activités associées aux résidents seront également définitivement supprimés.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
        allowOutsideClick: true,
      }).then(async (result) => {
        if (result.value) {
          await this.$store.dispatch("destroyTask", {
            id: task.id,
          }).catch((error) => {
            this.flashMessage.show({
              status: "error",
              title: error.graphQLErrors[0].extensions.code,
              message: error.graphQLErrors[0].message,
            });
          });
        }
      });
    },
  },
  computed: {
    ...mapGetters({
      tasks: 'tasks',
      currentEhpad: 'ehpad',
    }),
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep .small-size {
    max-width: 75px;
  }

  ::v-deep .min-size {
    width: 120px;
  }

</style>
