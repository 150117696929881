import gql from 'graphql-tag';

export const UPSERT_RESIDENT_TASK_GOAL = gql`
  mutation UPSERT_RESIDENT_TASK_GOAL(
    $value: Int!
    $taskId: ID!
    $residentId: ID!
  ) {
    upsertResidentTaskGoal(
      input: {
        value: $value
        taskId: $taskId
        residentId: $residentId
      }
    ) {
      residentTaskGoal {
        id
        value
        task {
          id
          title
          description
          taskType
          humanTaskType
        }
      }
    }
  }
`;
