<template>
  <b-container fluid>
    <div v-if="resident !== null">
      <app-resident-nav-bar
        :targetRoute="'mobilization'"
      />

      <div>
        <hr />
        <div class="d-print-none">
          <li class="text-center" style="list-style-type:none;"><a href="#task-goals">Objectifs des activités</a></li>
          <hr />
        </div>

        <a id="task-goals"></a>
        <TasksGoalsTable
          :resident="resident"
        />
      </div>
    </div>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import ResidentNavBar from '../../components/residents/ResidentNavBar.vue';
import TasksGoalsTable from '../../components/residents/TasksGoalsTable.vue';

export default {
  props: ['residentId'],
  components: {
    appResidentNavBar: ResidentNavBar,
    TasksGoalsTable,
  },
  data() {
    return {
      rangeForChart: { months: null, year: null },
      chartLoading: false,
    };
  },
  created() {
    // Pour ChartKick :
    moment.locale('fr-FR');
    this.findResident({
      id: this.residentId,
    });
  },
  watch: {
    residentId: {
      handler() {
        this.findResident({
          id: this.residentId,
        });
      },
    },
  },
  computed: {
    ...mapGetters({
      resident: 'resident',
      currentEhpad: 'ehpad',
    }),
    chartRangeOptions() {
      return [
        { value: { months: null, year: null }, text: '3 mois glissants' },
        { value: { months: 6, year: null }, text: '6 mois glissants' },
        { value: { months: 12, year: null }, text: '12 mois glissants' },
        { value: { months: null, year: moment().year() - 2 }, text: moment().year() - 2 },
        { value: { months: null, year: moment().year() - 1 }, text: moment().year() - 1 },
        { value: { months: null, year: moment().year() }, text: moment().year() },
      ];
    },
  },
  methods: {
    ...mapActions(['findResident']),
  },
};
</script>

<style lang="scss" scoped>

:target {
  display: block;
  position: relative;
  top: -11.5rem;
  visibility: hidden;
}

</style>
