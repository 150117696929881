
// import VueHtmlToPaper from 'vue-html-to-paper';
import Vue from 'vue';
import FlashMessage from '@smartweb/vue-flash-message';
import VueApollo from 'vue-apollo';
import Router from 'vue-router';
import VueCookies from 'vue-cookies';
// import Rails from '@rails/ujs';
import * as ActiveStorage from '@rails/activestorage';
import lodashGet from 'lodash/get';

import routes from '../routes';
import store from '../store';
import apiClient from '../utilities/api_client';
import App from '../App.vue';
import '../channels';
import '../plugins';
import '../utilities/filters';

// const options = {
//   name: '_blank',
//   specs: [
//     'fullscreen=yes',
//     'titlebar=yes',
//     'scrollbars=yes',
//   ],
//   styles: [
//     // TODO : Import all styles, how ?
//   ],
// };

Vue.prototype.$get = lodashGet;

// Rails.start();
ActiveStorage.start();

Vue.use(Router);
Vue.use(VueApollo);
// Vue.use(VueHtmlToPaper, options);
Vue.use(FlashMessage);
Vue.use(VueCookies);

const router = new Router({
  mode: 'history',
  base: '',
  routes,
  scrollBehavior(to, from, savedPosition) {
    // For Next, Back Button, else : Scroll to top
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('auth_token');
  const expirationDate = new Date(localStorage.getItem('expirationDate'));
  const now = new Date();
  const expired = now >= expirationDate;
  // Avant chaque route avec le meta requiresAuth on check dans le localStorage la validité du token
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Si le token n'existe pas ou est expiré on signout le User (suppression du token via l'action)
    // On renvoi vers /signin puis vers l'emplacement ou le user voulait aller
    if (!token || expired) {
      store.dispatch('signoutUser');
      return next({
        path: '/signin',
        query: { redirect: to.fullPath },
      });
    }
    // Si le token et la date est ok on continue
    return next();
  }
  // if (token || !expired) return next('/');
  // Si pas de meta requiresAuth on continue
  return next();
});

const apolloProvider = new VueApollo({
  defaultClient: apiClient,
});

Vue.config.devtools = process.env.NODE_ENV === 'development';

new Vue({
  router,
  store,
  apolloProvider,
  render: (h) => h(App),
}).$mount('#app');
