<template>
  <b-container fluid>
    <div v-if="resident !== null">
      <app-resident-nav-bar
        :targetRoute="'diagnostics'"
      />

      <div>
        <hr />
        <div class="d-print-none">
          <li class="text-center" style="list-style-type:none;"><a href="#resident-infos">Informations sur le résident</a></li>
          <li class="text-center" style="list-style-type:none;"><a href="#diag-form">Diagnostic de mobillité</a></li>
          <li class="text-center" style="list-style-type:none;"><a href="#diag-history">Historique et graphiques des diagnostics de mobillité</a></li>
          <li class="text-center" style="list-style-type:none;"><a href="#resident-tests">Les Tests de mobilité</a></li>
          <li class="text-center" style="list-style-type:none;"><a href="#resident-tests-history">Historique des tests et courbes</a></li>
          <hr />
        </div>

        <a id="resident-infos"></a>
        <b-row align-h="center">
          <app-resident-infos-form
            :resident="resident"
          />

        </b-row>
        <hr class="mb-4"/>

        <a id="diag-form"></a>
        <span class="d-flex justify-content-center">
          <h5 class="font-weight-bold">Diagnostic de mobilité</h5>
          <i
            class="fas fa-exclamation-triangle mt-1 ml-2"
            style="color: #5bc0de;"
            v-b-tooltip.hover.v-primary
            title="Attention : seul un professionnel de santé est habilité à établir un diagnostic de mobilité"
          />
        </span>
        <app-resident-mob-diag-form
          :resident="resident"
        />

        <app-expired-tests-alert-modal
          :expiredTests="resident.expiredMobilizationTests"
        />

        <hr class="mb-4"/>

        <a id="diag-history"></a>
        <span class="d-flex justify-content-center">
          <h5 class="font-weight-bold">Historique et graphiques des diagnostics de mobillité</h5>
        </span>
        <app-resident-mob-chart
          :resident="resident"
        />
        <hr class="mb-4"/>
        <app-resident-mob-log
          :mobilizationDiagnostics="resident.mobilizationDiagnostics"
        />
        <hr class="mb-4"/>

        <a id="resident-tests"></a>
        <span class="d-flex justify-content-center">
          <h5 class="font-weight-bold">Les Tests de mobilité</h5>
        </span>
        <app-resident-test-form
          v-if="resident.lastMobilizationDiagnostic !== null"
          :resident="resident"
        />
        <p v-else class="text-center">Ajouter un premier diagnostic de mobilité à ce résident pour commencer</p>
        <hr class="mb-4"/>
        <app-resident-tests-table
          :residentTests="resident.residentTests"
        />
        <a id="resident-tests-history"></a>
        <hr class="mb-4"/>
        <app-resident-tests-charts
          :resident="resident"
        />
        <hr class="mb-4"/>
      </div>

    </div>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

import ResidentInfosForm from '../../components/residents/ResidentInfosForm.vue';
import ResidentMobDiagForm from '../../components/residents/ResidentMobDiagForm.vue';
// import ResidentConstantsForm from '../../components/residents/ResidentConstantsForm.vue';
import ResidentMobLog from '../../components/residents/ResidentMobLog.vue';
import ResidentMobChart from '../../components/residents/ResidentMobChart.vue';
import ResidentTestsCharts from '../../components/residents/ResidentTestsCharts.vue';
import ResidentTestForm from '../../components/residents/ResidentTestForm.vue';
import ResidentTestsTable from '../../components/residents/ResidentTestsTable.vue';
import ExpiredTestsAlertModal from '../../components/residents/ExpiredTestsAlertModal.vue';
import ResidentNavBar from '../../components/residents/ResidentNavBar.vue';

export default {
  props: ['residentId'],
  components: {
    appResidentMobDiagForm: ResidentMobDiagForm,
    appResidentMobLog: ResidentMobLog,
    appResidentInfosForm: ResidentInfosForm,
    appResidentMobChart: ResidentMobChart,
    appResidentTestsCharts: ResidentTestsCharts,
    appResidentTestForm: ResidentTestForm,
    appResidentTestsTable: ResidentTestsTable,
    appResidentNavBar: ResidentNavBar,
    appExpiredTestsAlertModal: ExpiredTestsAlertModal,
  },
  data() {
    return {
      rangeForChart: { months: null, year: null },
      chartLoading: false,
    };
  },
  created() {
    // Pour ChartKick :
    moment.locale('fr-FR');
    this.findResident({
      id: this.residentId,
    });
  },
  watch: {
    resident: {
      handler() {
        if (this.resident && this.resident.expiredMobilizationTests.length !== 0) {
          setTimeout(() => { this.$bvModal.show('expired-tests-alert-modal'); }, 1000);
        }
      },
    },
    residentId: {
      handler() {
        this.findResident({
          id: this.residentId,
        });
      },
    },
  },
  computed: {
    ...mapGetters({
      resident: 'resident',
      currentEhpad: 'ehpad',
    }),
    chartRangeOptions() {
      return [
        { value: { months: null, year: null }, text: '3 mois glissants' },
        { value: { months: 6, year: null }, text: '6 mois glissants' },
        { value: { months: 12, year: null }, text: '12 mois glissants' },
        { value: { months: null, year: moment().year() - 2 }, text: moment().year() - 2 },
        { value: { months: null, year: moment().year() - 1 }, text: moment().year() - 1 },
        { value: { months: null, year: moment().year() }, text: moment().year() },
      ];
    },
  },
  methods: {
    ...mapActions(['findResident']),
  },
};
</script>

<style lang="scss" scoped>

:target {
  display: block;
  position: relative;
  top: -11.5rem;
  visibility: hidden;
}

</style>
