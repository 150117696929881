<template>
    <div>
      <app-full-calendar
        elementType='Ehpad'
        :elementId='currentEhpad.id'
      />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FullCalendar from '../../components/calendars/FullCalendar.vue';

export default {
  components: {
    appFullCalendar: FullCalendar,
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
    }),
  },
};
</script>

<style lang="scss" scoped>
</style>
