<template>
  <div>
    <hr class="my-2">
    <b-row>

      <b-col cols="3">
        <label for="inline-form-input-title" class="pr-4 text-nowrap"
          >Evenements <b>successifs</b> :
        </label>
      </b-col>

      <b-col cols="3">
        <b-form-group
          size="sm"
          style="margin-right: 125px;"
          class="my-auto"
        >
          <b-form-radio
          v-model="editableEvent.sequence"
          name="Actif"
          :disabled='disableEdit'
          :value="true"
            >Activé</b-form-radio
          >
          <b-form-radio
            v-model="editableEvent.sequence"
            name="Désactivé"
            :disabled='disableEdit'
            :value="false"
            >Désactivé</b-form-radio
          >
        </b-form-group>
      </b-col>

      <b-col cols="3" v-if="editableEvent.sequence">
        <label for="inline-form-input-title" class="pr-4 text-nowrap"
          >Durée individuelle :
        </label>
      </b-col>

      <b-col cols="3" v-if="editableEvent.sequence">
        <b-input-group>
          <b-form-input
            v-model="editableEvent.sequenceDefaultDuration"
            type="text"
            placeholder="HH:mm:ss"
            :disabled='disableEdit'
            size="sm"
            :class="{ 'is-invalid': $v.editableEvent.sequenceDefaultDuration.$anyError }"
          ></b-form-input>
          <b-input-group-append>
            <b-form-timepicker
              v-model="editableEvent.sequenceDefaultDuration"
              show-seconds
              locale="fr"
              button-only
              right
              :disabled='disableEdit'
              size="sm"
              :class="{ 'is-invalid': $v.editableEvent.sequenceDefaultDuration.$anyError }"
            />
          </b-input-group-append>
          <b-form-invalid-feedback>Valeur requise (HH:mm:ss)</b-form-invalid-feedback>
        </b-input-group>
      </b-col>

    </b-row>
    <hr class="my-2">
  </div>
</template>
<script>
import { validationMixin } from 'vuelidate';
import { required, helpers } from 'vuelidate/lib/validators';
import { hourCheck } from '../../regex';

const hourRegex = helpers.regex('hourRegex', hourCheck);

export default {
  props: {
    editableEvent: {
      type: Object,
      required: true,
    },
    disableEdit: {
      type: Boolean,
      required: true,
    },
  },
  mixins: [validationMixin],
  validations: {
    editableEvent: {
      sequence: {
        required,
      },
      sequenceDefaultDuration: {
        hourRegex,
      },
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
