<template>
  <b-modal v-bind="$attrs" size="lg" class="col-12 text-center">
    <template #modal-title>
      Filtrer les événements
    </template>
    <b-container fluid>
      <b-form>
        <b-row class="pt-2">
          <b-col cols="4">
            <label for="inline-form-input-option" class="pr-4 text-nowrap">Ressources sélectionnés : </label>
            <b-button class="d-flex mx-auto mt-1" variant="primary" size="sm" @click.prevent="activeResources = []">
              Vider le filtre
            </b-button>
          </b-col>
          <b-col cols="8">
            <multiselect
              v-model="activeResources"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="false"
              :options="resources"
              placeholder="Rechercher/Sélectionner une ressource"
              label="name"
              track-by="name"
              selectLabel=""
              deselectLabel=""
              selectedLabel="Sélectionné"
            >
              <span slot="noResult">Pas de résultat correspondant</span>
            </multiselect>
          </b-col>
        </b-row>

        <b-button class="d-flex mx-auto mt-3" variant="primary" size="sm" @click.prevent="filterEvents">
          Valider
        </b-button>
      </b-form>
    </b-container>
  </b-modal>
</template>

<script>

import { mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';

export default {
  props: ['filteredResources', 'elementType'],
  data() {
    return {
      activeResources: null,
    };
  },
  components: {
    Multiselect,
  },
  watch: {
    filteredResources: {
      handler() {
        this.activeResources = [...this.filteredResources];
      },
      immediate: true,
    },
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', () => {
      this.activeResources = [...this.filteredResources];
    });
  },
  computed: {
    ...mapGetters({
      currentEhpad: 'ehpad',
      resources: 'resources',
    }),
  },
  methods: {
    filterEvents() {
      this.$emit('filtered-resources-changed', this.activeResources);
      this.$bvModal.hide(`full-calendar-filter-modal-${this.elementType}`);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
