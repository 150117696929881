import gql from 'graphql-tag';

export const UPDATE_MOBILIZATION_CATEGORY = gql`
  mutation UPDATE_MOBILIZATION_CATEGORY($id: ID!, $name: String!, $description: String!, $color: String!) {
    updateMobilizationCategory(
      input: {
        id: $id
        name: $name
        description: $description
        color: $color
      }
    ) {
      mobilizationCategory {
        id
        name
        description
        color
      }
    }
  }
`;

