<template>
  <li
    class="list-group-item"
  >
    <div class="handle">

      <b-row>
        <b-col cols="6" class="d-flex justify-content-between m-auto" style="font-size:medium;">
          <div>
            <i class="fa fa-align-justify"></i>
            <span class="ml-1">{{ resident.name }} {{ resident.firstName }}</span>
            <span v-if="resident.room !== null">(Lit N° {{ resident.room.number }})</span>
            <span
              v-html="`${$options.filters.coloredMobDiagHtml(resident.lastMobilizationDiagnostic)}`"
            ></span>
          </div>
          <div v-if="forbiddenTaskTitles.length > 0">
            <span>
              <i class="fa fa-exclamation-triangle text-danger" v-b-tooltip.hover :title="`Activités interdites : ${forbiddenTaskTitles.join(', ')}`"></i>
            </span>
          </div>
          <div v-if="initialEventDefaultTasks !== 0">
            <b-button v-b-tooltip.hover.v-primary title="Mobista (Participation aux activités)"  style="float: right;" variant="primary" size="sm" @click="openEventMobistaModal">
              <i class="fas fa-thumbs-up"></i>
            </b-button>
          </div>
        </b-col>

        <template v-if="editableEvent.sequence">
          <b-col cols="2" style="max-width: 14.5% !important;">
            <b-input-group>
              <b-form-input
                v-model="resident.sequenceEvent.startHour"
                type="text"
                placeholder="Début"
                size="sm"
                :disabled='disableEdit'
                @input="sequenceHourInputChange()"
                :style="inputHourStyleError(resident.sequenceEvent.startHour)"
                :class="{ 'is-invalid': $v.resident.sequenceEvent.startHour.$anyError }"
              ></b-form-input>
              <b-input-group-append>
                <b-form-timepicker
                  v-model="resident.sequenceEvent.startHour"
                  show-seconds
                  locale="fr"
                  button-only
                  right
                  size="sm"
                  :disabled='disableEdit'
                  @input="sequenceHourInputChange()"
                  :class="{ 'is-invalid': $v.resident.sequenceEvent.startHour.$anyError }"
                />
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col cols="2" style="max-width: 14.5% !important;">
            <b-input-group>
              <b-form-input
                v-model="resident.sequenceEvent.duration"
                type="text"
                placeholder="Interval"
                size="sm"
                :disabled='disableEdit'
                @input="residentSequenceEventDurationInputChange()"
                :class="{ 'is-invalid': $v.resident.sequenceEvent.duration.$anyError }"
              ></b-form-input>
              <b-input-group-append>
                <b-form-timepicker
                  v-model="resident.sequenceEvent.duration"
                  show-seconds
                  locale="fr"
                  button-only
                  right
                  size="sm"
                  :disabled='disableEdit'
                  @input="residentSequenceEventDurationInputChange()"
                  :class="{ 'is-invalid': $v.resident.sequenceEvent.duration.$anyError }"
                />
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col cols="2" style="max-width: 14.5% !important;">
            <b-input-group>
              <b-form-input
                v-model="resident.sequenceEvent.endHour"
                type="text"
                placeholder="Fin"
                size="sm"
                :disabled='disableEdit'
                @input="sequenceHourInputChange()"
                :style="inputHourStyleError(resident.sequenceEvent.endHour)"
                :class="{ 'is-invalid': $v.resident.sequenceEvent.endHour.$anyError }"
              ></b-form-input>
              <b-input-group-append>
                <b-form-timepicker
                  v-model="resident.sequenceEvent.endHour"
                  show-seconds
                  locale="fr"
                  button-only
                  right
                  size="sm"
                  :disabled='disableEdit'
                  @input="sequenceHourInputChange()"
                  :class="{ 'is-invalid': $v.resident.sequenceEvent.endHour.$anyError }"
                />
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </template>

        <b-col class="align-self-end m-auto">
          <i class="fa fa-times close" v-if="!disableEdit" @click="removeThisResident()"></i>
        </b-col>
      </b-row>
    </div>
  </li>
</template>
<script>
import moment from 'moment';
import { validationMixin } from 'vuelidate';
import { required, helpers } from 'vuelidate/lib/validators';
import { hourCheck } from '../../regex';

const hourRegex = helpers.regex('hourRegex', hourCheck);

export default {
  props: ['resident', 'residentIndex', 'editableEvent', 'disableEdit', 'initialEventDefaultTasks'],
  mixins: [validationMixin],
  validations: {
    resident: {
      sequenceEvent: {
        startHour: {
          required,
          hourRegex,
          isBeforeEnd(val) {
            const sequenceStartHour = moment(val, 'HH:mm:ss');
            const sequenceEndHour = moment(this.resident.sequenceEvent.endHour, 'HH:mm:ss');
            return sequenceStartHour.isBefore(sequenceEndHour);
          },
        },
        endHour: {
          required,
          hourRegex,
          isAfterStart(val) {
            const sequenceEndHour = moment(val, 'HH:mm:ss');
            const sequenceStartHour = moment(this.resident.sequenceEvent.startHour, 'HH:mm:ss');
            return sequenceEndHour.isAfter(sequenceStartHour);
          },
        },
        duration: {
          required,
          hourRegex,
        },
      },
    },
  },
  methods: {
    openEventMobistaModal() {
      this.$emit('open-event-mobista-modal', this.resident);
    },
    removeThisResident() {
      this.editableEvent.residents.splice(this.residentIndex, 1);
    },
    residentSequenceEventDurationInputChange() {
      if (this.resident.sequenceEvent.duration !== null) {
        const durationInSeconds = moment.duration(this.resident.sequenceEvent.duration).asSeconds();
        this.resident.sequenceEvent.endHour = moment(this.resident.sequenceEvent.startHour, 'HH:mm:ss').add(durationInSeconds, 'seconds').format('HH:mm:ss');
      }
      this.$emit('update-resident-sequence');
    },
    sequenceHourInputChange() {
      const end = moment(this.resident.sequenceEvent.endHour, 'HH:mm:ss');
      const start = moment(this.resident.sequenceEvent.startHour, 'HH:mm:ss');
      const duration = moment.duration(end.diff(start));
      this.resident.sequenceEvent.duration = moment.utc(duration.as('milliseconds')).format('HH:mm:ss');
      this.$emit('update-resident-sequence');
    },
    inputHourStyleError(hour) {
      this.$v.resident.$touch();

      const editableEventEndHour = moment(this.editableEvent.end).format('HH:mm:ss');
      const editableEventEndHourMoment = moment(editableEventEndHour, 'HH:mm:ss');
      const editableEventStartHour = moment(this.editableEvent.start).format('HH:mm:ss');
      const editableEventStartHourMoment = moment(editableEventStartHour, 'HH:mm:ss');

      const formHourMoment = moment(hour, 'HH:mm:ss');

      return (editableEventEndHourMoment.isBefore(formHourMoment) || editableEventStartHourMoment.isAfter(formHourMoment))
        ? 'color: red;'
        : 'color: black;';
    },
  },
  computed: {
    forbiddenTaskTitles() {
      return this.editableEvent.eventDefaultTasks
        .filter((task) => this.resident.forbiddenTasks.some((forbiddenTask) => forbiddenTask.id === task.taskId))
        .map((task) => task.task.title);
    },
  },
};
</script>
<style scoped>
.list-group-item {
  padding: 6px 12px 6px 12px !important;
}
</style>
