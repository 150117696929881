import gql from 'graphql-tag';

export const CREATE_EVENT = gql`
  mutation CREATE_EVENT(
    $title: String!
    $capacity: Int!
    $start: String!
    $end: String!
    $rrule: RRuleAttributes
    $sequence: Boolean!
    $sequenceDefaultDuration: String
    $mobilizationCategories: [MobilizationCategoryAttributes!]
    $eventDefaultTasks: [EventDefaultTaskAttributes!]
    $resources: [ResourceAttributes!]
    $residents: [ResidentAttributes!]
    $ehpadId: ID
  ) {
    createEvent(
      input: {
        title: $title
        capacity: $capacity
        start: $start
        end: $end
        rrule: $rrule
        sequence: $sequence
        sequenceDefaultDuration: $sequenceDefaultDuration
        mobilizationCategories: $mobilizationCategories
        eventDefaultTasks: $eventDefaultTasks
        resources: $resources
        residents: $residents
        ehpadId: $ehpadId
      }
    ) {
      event {
        id
        sequence
        title
        start
        end
        color
        borderColor
        textColor
        backgroundColor
        residents {
          id
        }
        ehpad {
          id
        }
      }
    }
  }
`;

export const UPDATE_EVENT = gql`
  mutation UPDATE_EVENT(
    $id: ID!
    $title: String!
    $capacity: Int!
    $start: String!
    $end: String!
    $rrule: RRuleAttributes
    $sequence: Boolean!
    $sequenceDefaultDuration: String
    $mobilizationCategories: [MobilizationCategoryAttributes!]!
    $eventDefaultTasks: [EventDefaultTaskAttributes!]
    $resources: [ResourceAttributes!]
    $residents: [ResidentAttributes!]
    $thisEventOnly: Boolean!
  ) {
    updateEvent(
      input: {
        id: $id
        title: $title
        capacity: $capacity
        start: $start
        end: $end
        rrule: $rrule
        sequence: $sequence
        sequenceDefaultDuration: $sequenceDefaultDuration
        mobilizationCategories: $mobilizationCategories
        eventDefaultTasks: $eventDefaultTasks
        resources: $resources
        residents: $residents
        thisEventOnly: $thisEventOnly
      }
    ) {
      event {
        id
        sequence
        title
        start
        end
        color
        borderColor
        textColor
        backgroundColor
        residents {
          id
        }
        ehpad {
          id
        }
      }
    }
  }
`;

export const DESTROY_EVENT = gql`
  mutation DESTROY_EVENT(
    $id: ID!
    $elementId: ID!
    $elementType: String!
    $date: String!
    $thisEventOnly: Boolean!
  ) {
    destroyEvent(
      input: {
        id: $id 
        elementId: $elementId
        elementType: $elementType
        date: $date
        thisEventOnly: $thisEventOnly 
      }
    ) {
      event {
        id
      }
    }
  }
`;
